import { Svg, Path } from '@react-pdf/renderer';
import { usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

function IconInfo() {
    const pdfType = usePdfType();

    const color = pdfType === 'ys' ? '#d9492f' : '#e6492d';

    return (
        <Svg viewBox="0 0 546.41 546.41">
            <Path
                fill={color}
                d="M273.2,0C122.32,0,0,122.32,0,273.2S122.32,546.41,273.2,546.41,546.41,424.09,546.41,273.2,424.09,0,273.2,0m0,424.75a30.28,30.28,0,1,1,21.44-8.87,30.3,30.3,0,0,1-21.44,8.87m15.16-122.87c0,16.79-7,30.31-15.16,30.31s-15.15-13.52-15.15-30.31L242.9,167.12a30.31,30.31,0,1,1,60.61,0Z"
            />
        </Svg>
    );
}

export default IconInfo;
