import TextWithBlank from '@components/PdfReporter/components/TextWithBlank';
import { View, Image, Text, Svg, Path, G } from '@react-pdf/renderer';
import { usePdfTheme, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';
import scoreCardShadowLeft from '@components/MbtiPdfReporterMobile/assets/ys/scoreCardShadow_left.png';
import scoreCardShadowRight from '@components/MbtiPdfReporterMobile/assets/ys/scoreCardShadow_right.png';
import scoreCardShadowFull from '@components/MbtiPdfReporterMobile/assets/ms/scoreCardShadow_full.png';

export type TScoreCardData = {
    upperLetter: string;
    cnName: string;
    enName: string;
    description: string;
    upperLetterStyle?: any;
    cnNameStyle?: any;
    enNameStyle?: any;
    descriptionStyle?: any;
};
function ScoreCard(props: { type: 'left' | 'right'; data: [TScoreCardData, TScoreCardData] }) {
    const pdfType = usePdfType();
    const theme = usePdfTheme();
    const { type, data } = props;

    const offset: number = pdfType === 'ys' ? 0 : -3;

    return (
        <View style={{ width: 325, height: 60, position: 'relative', color: 'white' }}>
            {pdfType === 'ms' ? (
                <BgFull type={type} />
            ) : (
                <>
                    {type === 'right' ? <BgRight /> : null}
                    {type === 'left' ? <BgLeft /> : null}
                </>
            )}
            <View style={{ width: 162, height: 60, position: 'absolute', left: 0, top: 0 }}>
                {pdfType === 'ms' && type === 'left' ? (
                    <View style={{ width: 34, height: 34, position: 'absolute', left: 9, top: 12 }}>
                        <Svg viewBox="0 0 68 68">
                            <Path
                                fillRule="evenodd"
                                fill="rgb(255, 255, 255)"
                                d="M34.220,0.431 C52.726,0.431 67.728,15.466 67.728,34.012 C67.728,52.558 52.726,67.592 34.220,67.592 C15.713,67.592 0.711,52.558 0.711,34.012 C0.711,15.466 15.713,0.431 34.220,0.431 Z"
                            />
                        </Svg>
                    </View>
                ) : null}
                <Text
                    style={{
                        fontSize: 30,
                        position: 'absolute',
                        top: 10,
                        left: 22 + offset,
                        width: 18,
                        height: 36,
                        textAlign: 'center',
                        color: pdfType === 'ms' && type === 'left' ? theme?.scoreCardMain : void 0,
                        ...data[0]?.upperLetterStyle,
                    }}
                >
                    {data[0].upperLetter}
                </Text>
                <Text
                    style={{ fontSize: 12, position: 'absolute', top: 7, left: 50 + offset, ...data[0]?.cnNameStyle }}
                >
                    {data[0].cnName}
                </Text>
                <Text
                    style={{ fontSize: 10, position: 'absolute', top: 8, left: 80 + offset, ...data[0]?.enNameStyle }}
                >
                    {data[0].enName}
                </Text>
                <TextWithBlank
                    style={{
                        fontSize: 9,
                        position: 'absolute',
                        top: 26,
                        left: 50 + offset,
                        width: 105,
                        letterSpacing: -1,
                        ...data[0]?.descriptionStyle,
                    }}
                >
                    {data[0].description}
                </TextWithBlank>
            </View>
            <View style={{ width: 162, height: 60, position: 'absolute', right: 0, top: 0 }}>
                {pdfType === 'ms' && type === 'right' ? (
                    <View style={{ width: 34, height: 34, position: 'absolute', left: 6, top: 12 }}>
                        <Svg viewBox="0 0 68 68">
                            <Path
                                fillRule="evenodd"
                                fill="rgb(255, 255, 255)"
                                d="M34.220,0.431 C52.726,0.431 67.728,15.466 67.728,34.012 C67.728,52.558 52.726,67.592 34.220,67.592 C15.713,67.592 0.711,52.558 0.711,34.012 C0.711,15.466 15.713,0.431 34.220,0.431 Z"
                            />
                        </Svg>
                    </View>
                ) : null}
                <Text
                    style={{
                        fontSize: 30,
                        position: 'absolute',
                        top: 10,
                        left: 17 + offset,
                        width: 18,
                        height: 36,
                        textAlign: 'center',
                        color: pdfType === 'ms' && type === 'right' ? theme?.scoreCardMain : void 0,
                        ...data[1]?.upperLetterStyle,
                    }}
                >
                    {data[1].upperLetter}
                </Text>
                <Text
                    style={{ fontSize: 12, position: 'absolute', top: 7, left: 45 + offset, ...data[1]?.cnNameStyle }}
                >
                    {data[1].cnName}
                </Text>
                <Text
                    style={{ fontSize: 10, position: 'absolute', top: 8, left: 72 + offset, ...data[1]?.enNameStyle }}
                >
                    {data[1].enName}
                </Text>
                <TextWithBlank
                    style={{
                        fontSize: 9,
                        position: 'absolute',
                        top: 26,
                        left: 45 + offset,
                        width: 105,
                        letterSpacing: -1,
                        ...data[1]?.descriptionStyle,
                    }}
                >
                    {data[1].description}
                </TextWithBlank>
            </View>
        </View>
    );
}

function BgLeft() {
    const theme = usePdfTheme();

    return (
        <>
            <View
                style={{
                    width: 682 / 2,
                    height: 182 / 2,
                    position: 'absolute',
                    left: -14,
                    top: -10,
                }}
            >
                <Svg viewBox="0 0 682 182">
                    <Path
                        fillRule="evenodd"
                        fill={theme.scoreCardSecondary}
                        d="M202.000,22.000 L680.000,22.000 L680.000,22.000 L680.000,82.000 C680.000,115.137 653.137,142.000 620.000,142.000 L142.000,142.000 L142.000,142.000 L142.000,82.000 C142.000,48.863 168.863,22.000 202.000,22.000 Z"
                    />
                </Svg>
            </View>
            <View
                style={{
                    width: 682 / 2,
                    height: 182 / 2,
                    position: 'absolute',
                    left: -14,
                    top: -10,
                }}
            >
                <Image src={scoreCardShadowLeft} />
            </View>
            <View
                style={{
                    width: 682 / 2,
                    height: 182 / 2,
                    position: 'absolute',
                    left: -14,
                    top: -10,
                }}
            >
                <Svg viewBox="0 0 682 182">
                    <Path
                        fillRule="evenodd"
                        fill={theme.scoreCardMain}
                        d="M90.000,22.000 L356.000,22.000 L356.000,22.000 L356.000,142.000 L356.000,142.000 L30.000,142.000 L30.000,142.000 L30.000,82.000 C30.000,48.863 56.863,22.000 90.000,22.000 Z"
                    />
                </Svg>
            </View>
        </>
    );
}
function BgRight() {
    const theme = usePdfTheme();

    return (
        <>
            <View
                style={{
                    width: 682 / 2,
                    height: 182 / 2,
                    position: 'absolute',
                    left: 0,
                    top: -10,
                }}
            >
                <Svg viewBox="0 0 682 182">
                    <Path
                        fillRule="evenodd"
                        fill={theme.scoreCardSecondary}
                        d="M60.000,22.000 L540.000,22.000 L540.000,22.000 L540.000,82.000 C540.000,115.137 513.137,142.000 480.000,142.000 L0.000,142.000 L0.000,142.000 L0.000,82.000 C0.000,48.863 26.863,22.000 60.000,22.000 Z"
                    />
                </Svg>
            </View>
            <View
                style={{
                    width: 682 / 2,
                    height: 182 / 2,
                    position: 'absolute',
                    left: 0,
                    top: -10,
                }}
            >
                <Image src={scoreCardShadowRight} />
            </View>
            <View
                style={{
                    width: 682 / 2,
                    height: 182 / 2,
                    position: 'absolute',
                    left: 0,
                    top: -10,
                }}
            >
                <Svg viewBox="0 0 682 182">
                    <Path
                        fillRule="evenodd"
                        fill={theme.scoreCardMain}
                        d="M326.000,22.000 L650.000,22.000 L650.000,22.000 L650.000,82.000 C650.000,115.137 623.137,142.000 590.000,142.000 L326.000,142.000 L326.000,142.000 L326.000,22.000 L326.000,22.000 Z"
                    />
                </Svg>
            </View>
        </>
    );
}

/**
 * 针对 Milesight
 */
function BgFull(props: { type: 'left' | 'right' }) {
    const theme = usePdfTheme();
    const { type } = props;

    return (
        <>
            <View
                style={{
                    width: 682 * (346 / 682),
                    height: 182 * (346 / 682),
                    position: 'absolute',
                    left: -11,
                    top: -16,
                }}
            >
                <Image src={scoreCardShadowFull} />
            </View>
            <View
                style={{
                    width: 682 * (346 / 682),
                    height: 182 * (346 / 682),
                    position: 'absolute',
                    left: -11,
                    top: -16,
                }}
            >
                {type === 'left' ? (
                    <Svg viewBox="0 0 682 182">
                        <G transform="translate(341.000000, 91.000000) scale(-1, 1) translate(-341.000000, -91.000000) ">
                            <Path
                                d="M79.133,32 L602.867,32 C635.526,32 662,58.3590648 662,90.8740539 L662,91.1259461 C662,123.640935 635.526,150 602.867,150 L79.133,150 C46.475,150 20,123.640935 20,91.1259461 L20,90.8740539 C20,58.3590648 46.475,32 79.133,32 Z"
                                fill={theme?.scoreCardSecondary}
                            />
                            <Path
                                d="M340,150 L602.736141,150 C635.466412,150 662,123.641708 662,91.1254493 L662,90.8745507 C662,58.3592872 635.466412,32 602.736141,32 L340,32 L340,32 L340,150 L340,150 Z"
                                fill={theme?.scoreCardMain}
                            />
                        </G>
                    </Svg>
                ) : (
                    <Svg viewBox="0 0 682 182">
                        <Path
                            d="M79.133,32 L602.867,32 C635.526,32 662,58.3590648 662,90.8740539 L662,91.1259461 C662,123.640935 635.526,150 602.867,150 L79.133,150 C46.475,150 20,123.640935 20,91.1259461 L20,90.8740539 C20,58.3590648 46.475,32 79.133,32 Z"
                            fill={theme?.scoreCardSecondary}
                        />
                        <Path
                            d="M340,150 L602.736141,150 C635.466412,150 662,123.641708 662,91.1254493 L662,90.8745507 C662,58.3592872 635.466412,32 602.736141,32 L340,32 L340,32 L340,150 L340,150 Z"
                            fill={theme?.scoreCardMain}
                        />
                    </Svg>
                )}
            </View>
        </>
    );
}

export default ScoreCard;
