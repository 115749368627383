import ReactPDF, { StyleSheet, View } from "@react-pdf/renderer";
import { useStyles } from "../utils/styles";

interface ColumnProps<T extends Record<string, any>> {
    dataIndex: keyof T;
    title: (
        column: ColumnProps<T>,
        columnIndex: number,
        columns: ColumnProps<T>[]
    ) => React.ReactNode;
    render: (val: any, record: T, index: number) => React.ReactNode;
    width: string | number;
    style?: (record: T, index: number) => ReactPDF.Styles;
    titleStyle?: (
        column: ColumnProps<T>,
        columnIndex: number
    ) => ReactPDF.Styles;
}
function TableNormal<T extends Record<string, any>>(props: {
    dataSource: T[];
    columns: ColumnProps<T>[];
    keyColumnName: keyof T;
}) {
    const { dataSource, columns, keyColumnName } = props;

    const { commonStyles, colors } = useStyles();

    const tbStyles = StyleSheet.create({
        table: {
            ...commonStyles.text,
            textAlign: "center",
        },
        blueCellBg: {
            backgroundColor: colors.themeColor,
            color: "white",
        },
        row: {
            justifyContent: "space-between",
            alignItems: "stretch",
            flexDirection: "row",
            textAlign: "center",
            backgroundColor: "#F9F9F9",
            color: "black",
        },
        cell: {
            width: "25%",
        },
        cellHeight: {
            height: 36,
            paddingBottom: 0,
        },
        cellHeightX: {
            padding: 6,
        },
    });

    return (
        <>
            <View
                style={{
                    ...tbStyles.table,
                    ...tbStyles.row,
                    ...tbStyles.blueCellBg,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                }}
            >
                {columns.map((column, columnIndex) => {
                    return (
                        <View
                            key={column.dataIndex as string}
                            style={{
                                ...tbStyles.cell,
                                ...tbStyles.cellHeight,
                                ...commonStyles.borderLeft,
                                ...commonStyles.borderTop,
                                ...commonStyles.borderBottom,
                                ...(columnIndex === 0
                                    ? {
                                          borderTopLeftRadius: 4,
                                      }
                                    : {}),
                                ...(columnIndex === columns.length - 1
                                    ? {
                                          ...commonStyles.borderRight,
                                          borderTopRightRadius: 4,
                                      }
                                    : {}),
                                width: column.width,
                                ...column?.titleStyle?.(column, columnIndex),
                            }}
                        >
                            {column.title(column, columnIndex, columns)}
                        </View>
                    );
                })}
            </View>
            {dataSource.map((item, itemIndex) => {
                const isLastItem = itemIndex === dataSource.length - 1;
                return (
                    <View
                        key={item[keyColumnName]}
                        style={{
                            ...tbStyles.table,
                            ...tbStyles.row,
                            ...(isLastItem
                                ? {
                                      borderBottomLeftRadius: 4,
                                      borderBottomRightRadius: 4,
                                  }
                                : {}),
                        }}
                        break={!!item.break}
                    >
                        {columns.map((column, columnIndex) => {
                            const isFirstColumn = columnIndex === 0;
                            const isLastColumn =
                                columnIndex === columns.length - 1;
                            return (
                                <View
                                    key={column.dataIndex as string}
                                    style={{
                                        ...tbStyles.cell,
                                        ...tbStyles.cellHeightX,
                                        ...commonStyles.borderLeft,
                                        ...commonStyles.borderBottom,
                                        width: column.width,
                                        ...(isLastColumn
                                            ? commonStyles.borderRight
                                            : {}),
                                        ...(isLastItem
                                            ? {
                                                  ...commonStyles.borderBottom,
                                                  ...(isLastColumn
                                                      ? {
                                                            borderBottomRightRadius: 4,
                                                        }
                                                      : {}),
                                                  ...(isFirstColumn
                                                      ? {
                                                            borderBottomLeftRadius: 4,
                                                        }
                                                      : {}),
                                              }
                                            : {}),
                                        ...column?.style?.(item, itemIndex),
                                    }}
                                >
                                    {column.render(
                                        item[column.dataIndex],
                                        item,
                                        itemIndex
                                    )}
                                </View>
                            );
                        })}
                    </View>
                );
            })}
        </>
    );
}
export default TableNormal;
