import { View } from "@react-pdf/renderer";
import { useStyles } from "../utils/styles";
import TablePreOccupationalFactorPoints from "./TablePreOccupationalFactorPoints";
import TextWithBlank from "./TextWithBlank";

/**
 * 8、职业预测人格因素
 */
function Reporter8() {
    const { commonStyles } = useStyles();

    return (
        <View style={{ height: "auto", marginTop: 24 }}>
            <TextWithBlank
                style={commonStyles.heading}
            >{`8、职业预测人格因素`}</TextWithBlank>
            <View style={{ marginTop: 8 }}>
                <TablePreOccupationalFactorPoints />
            </View>
        </View>
    );
}

export default Reporter8;
