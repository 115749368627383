import React, { useContext } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Context } from '../utils/common';
import ModuleHeader from './ModuleHeader';
import Tags from './Tags';
import HighTable from './HighTable';
import { useStyles } from '@/components/PdfReporter/utils/styles';
import HealthListItem from './HealthListItem';

/**
 * @description 心理健康风险各因子得分详情
 * @param props
 */
const MentalHealthRiskFactors: React.FC = () => {
    const { data, type } = useContext(Context);
    const { commonStyles } = useStyles(type);
    const { mental_health_risk } = data;

    const styles = StyleSheet.create({
        tableHeadColumn: {
            ...commonStyles.flex,
            boxSizing: 'border-box',
            height: 24.57,
            justifyContent: 'center',
            textAlign: 'center',
            borderRight: '0.19 solid #fff',
        },
    });

    // 心理健康风险等级
    const levels = [
        { label: '无症状', bgcolor: '#B0EACD', range: [1, 1.5] },
        { label: '轻度', bgcolor: '#FADBD5', range: [1.5, 2.5] },
        { label: '中度', bgcolor: '#F5B6AB', range: [2.5, 3.5] },
        { label: '重度', bgcolor: '#F09281', range: [3.5, 4.5] },
        { label: '极重', bgcolor: '#EB6D57', range: [4.5, 5] },
    ];

    // 获取心理健康风险等级状态
    const getStatus = (score: number) => {
        let status = '无症状';
        levels.forEach((item: Record<string, any>) => {
            if (score > item.range[0] && score <= item.range[1]) {
                status = item.label;
            }
        });
        return status;
    };

    const dataSource = [
        {
            dimension: '躯体化',
            define: '身体不适感以及焦虑等躯体不适表现',
            score: 'somatization',
            ranges: [
                {
                    status: ['无症状'],
                    behavior: ['身体无明显不适'],
                },
                {
                    status: ['轻度', '中度'],
                    behavior: ['自我感觉身体有些不适', '有时略感疼痛', '比较不善于化解自身压力'],
                },
                {
                    status: ['重度', '极重'],
                    behavior: ['身体有明显不适感', '日常焦虑紧张，致心理因素躯体化'],
                },
            ],
        },
        {
            dimension: '强迫状态',
            define: '明知没有必要，但又无法摆脱的无意义的思想、冲动和行为',
            score: 'obsessive_symptoms',
            ranges: [
                {
                    status: ['无症状'],
                    behavior: ['追求的目标切合实际，对自身的要求适当'],
                },
                {
                    status: ['轻度', '中度'],
                    behavior: ['有时苛求细节和完美，对某些事情过度严格，易给自己和他人带来困扰'],
                },
                {
                    status: ['重度', '极重'],
                    behavior: ['过于苛求细节和完美', '在行为和思想上出现了较多困扰'],
                },
            ],
        },
        {
            dimension: '人际关系敏感',
            define: '在人际交往中的自卑感，明显的不自在及不良自我暗示，消极期待等',
            score: 'interpersonal_relationship_sensitivity',
            ranges: [
                {
                    status: ['无症状'],
                    behavior: ['通常有良好的人际关系和亲密的朋友'],
                },
                {
                    status: ['轻度', '中度'],
                    behavior: ['与人交往敏感谨慎', '情感表达方式有限'],
                },
                {
                    status: ['重度', '极重'],
                    behavior: ['不合群，敏感多虑', '较难被激励', '不善交往，孤单冷漠'],
                },
            ],
        },
        {
            dimension: '抑郁',
            define: '长时间心情低落，缺乏动力，对工作和生活丧失兴趣',
            score: 'depression',
            ranges: [
                {
                    status: ['无症状'],
                    behavior: ['能用积极乐观的态度看待身边的事物'],
                },
                {
                    status: ['轻度', '中度'],
                    behavior: ['易感到消极和悲观', '活力和精力不佳', '对大多数事情兴趣不大'],
                },
                {
                    status: ['重度', '极重'],
                    behavior: ['悲观绝望，内疚自责', '精力涣散，言行迟缓', '觉得生活没有意义'],
                },
            ],
        },
        {
            dimension: '焦虑',
            define: '对尚未发生的事情忧虑，并目自己无法控制这种担心和忧虑',
            score: 'anxiety',
            ranges: [
                {
                    status: ['无症状'],
                    behavior: ['无明显焦虑症状', '对现状比较满意，自己能够较好地掌控'],
                },
                {
                    status: ['轻度', '中度'],
                    behavior: ['偶尔焦虑和紧张', '有时因小事焦虑，难以集中注意力'],
                },
                {
                    status: ['重度', '极重'],
                    behavior: ['无故焦虑', '精神紧绷', '难以集中注意力'],
                },
            ],
        },
        {
            dimension: '敌对',
            define: '从思维、情感及行为三个方面来反映的对抗、对立的敌对表现',
            score: 'hostility',
            ranges: [
                {
                    status: ['无症状'],
                    behavior: ['友善，脾气温和，不易与人发生冲突'],
                },
                {
                    status: ['轻度', '中度'],
                    behavior: ['偶尔胡思乱想', '压力情景下，情绪易波动', '有时可能会出现过激行为'],
                },
                {
                    status: ['重度', '极重'],
                    behavior: ['胡思乱想，容易烦恼', '易冲动行事，情绪易激动', '好争论，易出现攻击行为'],
                },
            ],
        },
        {
            dimension: '恐怖',
            define: '对某些特定的对象或处境产生强烈和不必要的恐惧情绪',
            score: 'phobia',
            ranges: [
                {
                    status: ['无症状'],
                    behavior: ['恐怖的症状表现不明显，能正常的社交与活动'],
                },
                {
                    status: ['轻度', '中度'],
                    behavior: ['有时感觉不安全', '在某种场景下可能或过度担心，焦虑不安'],
                },
                {
                    status: ['重度', '极重'],
                    behavior: ['强烈不安全感', '过度担心，烦躁不安'],
                },
            ],
        },
        {
            dimension: '偏执',
            define: '对他人存在普遍的不信任感，倾向于从阴暗面去理解他人的行为',
            score: 'paranoia',
            ranges: [
                {
                    status: ['无症状'],
                    behavior: ['对他人有基本的信任感，不会无缘由地怀疑他人和质疑别人的出发点'],
                },
                {
                    status: ['轻度', '中度'],
                    behavior: ['有时会感觉对他人缺乏信任感或产生怀疑', '有时会怀疑被人利用'],
                },
                {
                    status: ['重度', '极重'],
                    behavior: ['容易偏激，较为固执', '对他人缺乏信任感', '敏感多疑，过分警觉'],
                },
            ],
        },
        {
            dimension: '妄想幻觉',
            define: '思维紊乱，会产生不真实且幻想或幻听，对事物有歪曲的认知',
            score: 'delusion_hallucination',
            ranges: [
                {
                    status: ['无症状'],
                    behavior: ['对事物认知正常，能分清现实和虚幻'],
                },
                {
                    status: ['轻度', '中度'],
                    behavior: ['可能分不清现实和虚幻', '可能出现幻觉'],
                },
                {
                    status: ['重度', '极重'],
                    behavior: ['思维紊乱', '幻想或幻听', '分不清现实和虚幻'],
                },
            ],
        },
    ].map(item => {
        return {
            ...item,
            status: getStatus(mental_health_risk?.[item.score]),
        };
    });

    const headers = [
        {
            text: '维度',
            width: 61.3,
        },
        {
            text: '定义',
            width: 123.42,
        },
        {
            text: '得分',
            width: 108.67,
        },
        {
            text: '状态',
            width: 53.87,
        },
        {
            text: '具体表现',
            width: 129.21,
        },
    ];

    // 头部
    const headerChildren = () => {
        return (
            <>
                {headers.map((item, index) => {
                    return (
                        <View
                            key={index}
                            style={{
                                width: item.width,
                                ...styles.tableHeadColumn,
                                ...(index === headers.length - 1
                                    ? {
                                          borderRight: '0',
                                      }
                                    : {}),
                            }}
                        >
                            <Text style={{ paddingTop: 4 }}>{item.text}</Text>
                        </View>
                    );
                })}
            </>
        );
    };

    return (
        <View>
            <ModuleHeader
                titleText="心理健康风险各因子得分详情"
                extraCom={<Tags options={levels} style={{ marginLeft: 10 }} />}
            />
            <View
                style={{
                    fontSize: 12.85,
                }}
            >
                <HighTable
                    width={510.3}
                    height={517.89}
                    pdfType={type}
                    headerStyle={{ ...commonStyles.flex, padding: 16.51, color: '#fff' }}
                    header={headerChildren()}
                >
                    <View style={{ ...commonStyles.flexColumn, paddingTop: 37.8, alignItems: 'center' }}>
                        {dataSource.map(item => {
                            return (
                                <HealthListItem
                                    data={item}
                                    scores={mental_health_risk || {}}
                                    pdfType={type}
                                    levels={levels}
                                />
                            );
                        })}
                    </View>
                </HighTable>
            </View>
        </View>
    );
};

export default MentalHealthRiskFactors;
