import LocaleContainer from "@components/Locale";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ModelsProvider from "./components/useModel/Provider";
import "./index.less";
import { models } from "./models/index";
import routes from "./routes/index";
import { loadIcons } from "ysd-pp/es/icon";

loadIcons(() => {
  import("@/assets/Icon/iconfont.ts");
});
const router = createBrowserRouter(routes);

ReactDOM.render(
  <React.StrictMode>
    <LocaleContainer>
      <ModelsProvider models={models}>
        <RouterProvider router={router} />
      </ModelsProvider>
    </LocaleContainer>
  </React.StrictMode>,
  document.getElementById("root")
);
