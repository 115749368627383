import { View, Image, Svg, Path, Polygon } from '@react-pdf/renderer';
import { usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';
import logoYeastar from '@components/MbtiPdfReporterMobile/assets/ys/logo_yeastar.png';

function Logo() {
    const pdfType = usePdfType();

    return (
        <View
            style={{
                width: 118,
                height: 43,
                ...(pdfType === 'ms'
                    ? {
                          width: 133 * 0.9,
                          height: 37 * 0.9,
                          position: 'relative',
                          left: 2,
                      }
                    : {}),
            }}
        >
            {pdfType === 'ys' ? <Image src={logoYeastar} /> : null}
            {pdfType === 'ms' ? (
                <Svg viewBox="0 0 337.84 92.64">
                    <Polygon fill="#3c3c3c" points="101.36 37.2 108.17 37.2 100.03 75.83 93.22 75.83 101.36 37.2" />
                    <Polygon fill="#3c3c3c" points="104.99 20 111.79 20 109.93 28.82 103.13 28.82 104.99 20" />
                    <Polygon fill="#3c3c3c" points="122.32 20 129.17 20 117.4 75.83 110.55 75.83 122.32 20" />
                    <Path
                        fill="#3c3c3c"
                        d="M399.36,272.38c-.11.5-.29,1.14-.53,1.89s-.51,1.58-.8,2.45l-28.75.73a24.17,24.17,0,0,0-.7,6.14,6.39,6.39,0,0,0,1.47,3.69,7.54,7.54,0,0,0,3.67,2.17,20.41,20.41,0,0,0,6.06.87c2.35,0,4.59-.06,7.15-.19a21.78,21.78,0,0,0,6.55-1l-1.08,5.15c-.82.17-1.09.25-2.25.43s-2.35.37-3.71.47-2.59.19-4.11.22-3,.06-4.52.06a31.22,31.22,0,0,1-8.11-.94,11,11,0,0,1-5.45-3.18,10.7,10.7,0,0,1-2.5-5.93,28.25,28.25,0,0,1,.79-9.28,33.18,33.18,0,0,1,3.3-9.28,19.25,19.25,0,0,1,5-5.93,18.11,18.11,0,0,1,6.7-3.17,34.4,34.4,0,0,1,8.28-.93,20.28,20.28,0,0,1,8.41,1.41,9.42,9.42,0,0,1,4.32,3.66,9.92,9.92,0,0,1,1.34,5A25.14,25.14,0,0,1,399.36,272.38Zm-6.66.07a27.24,27.24,0,0,0,.58-4.11,6.84,6.84,0,0,0-.62-3.45,5.18,5.18,0,0,0-2.58-2.34,12.76,12.76,0,0,0-5.28-.86,19.8,19.8,0,0,0-6.16.82,11,11,0,0,0-4.15,2.42,12.14,12.14,0,0,0-2.71,3.86,19.4,19.4,0,0,0-1.55,4.25Z"
                        transform="translate(-232.15 -219.6)"
                    />
                    <Path
                        fill="#3c3c3c"
                        d="M432,276.85a9.94,9.94,0,0,0-3.64-2.15,45.33,45.33,0,0,0-5.78-1.64c-1.6-.32-2.88-.63-3.86-.93a5.6,5.6,0,0,1-2.18-1.12,2.67,2.67,0,0,1-.87-1.56,6.3,6.3,0,0,1,.16-2.36,5.67,5.67,0,0,1,3.15-4.31,17.36,17.36,0,0,1,6.92-1.11,34.36,34.36,0,0,1,5.54.42,23.42,23.42,0,0,1,4,1l1.1-5.22a23.93,23.93,0,0,0-3.67-.68,51.22,51.22,0,0,0-6-.35q-7.58,0-12,2.79A11.35,11.35,0,0,0,409.5,267a13.62,13.62,0,0,0-.34,4.1,5.28,5.28,0,0,0,1.23,3,8.41,8.41,0,0,0,3.13,2.15,28.07,28.07,0,0,0,5.43,1.6c1.94.42,3.49.81,4.63,1.18a7.75,7.75,0,0,1,2.57,1.28,2.71,2.71,0,0,1,1,1.81,8.88,8.88,0,0,1-.24,2.75,6,6,0,0,1-3.45,4.55,19,19,0,0,1-7.56,1.21c-.94,0-2,0-3.07-.1s-2.18-.18-3.25-.31-2.08-.31-3.06-.49-1.83-.38-2.52-.57l-1.08,5.15c.54.14,1.27.29,2.2.46s2,.29,3.08.38,2.27.17,3.43.24,2.25.11,3.29.11q7.82,0,12.48-2.61a11.78,11.78,0,0,0,5.88-8.23,12.72,12.72,0,0,0,.29-4.59A5.65,5.65,0,0,0,432,276.85Z"
                        transform="translate(-232.15 -219.6)"
                    />
                    <Polygon fill="#3c3c3c" points="215.59 37.2 222.35 37.2 214.21 75.83 207.45 75.83 215.59 37.2" />
                    <Polygon fill="#4696f5" points="219.22 20 225.98 20 224.12 28.82 217.35 28.82 219.22 20" />
                    <Path
                        fill="#3c3c3c"
                        d="M494,256.8l-1.22,5.78a12.79,12.79,0,0,0-11.17-5.78h-1.24a22.52,22.52,0,0,0-9,1.62,18.85,18.85,0,0,0-6.29,4.4,21.22,21.22,0,0,0-4.12,6.47,44.09,44.09,0,0,0-2.43,7.83,26.55,26.55,0,0,0-.77,6.51,13.07,13.07,0,0,0,1.36,6.4,9.73,9.73,0,0,0,4.46,4.14c2,1,4.83,1.26,8.38,1.26,4.06,0,7.27-.61,9.64-1.91.26-.15.51-.33.76-.49l1.13-5.39a12.72,12.72,0,0,1-2.91,1.51,18.9,18.9,0,0,1-6.54,1c-2.5,0-4.43-.22-5.74-1a6.08,6.08,0,0,1-2.71-3.22,11.07,11.07,0,0,1-.5-4.76,22.32,22.32,0,0,1,.63-4,53.78,53.78,0,0,1,1.52-5.61,15.81,15.81,0,0,1,2.51-4.81,12,12,0,0,1,4.12-3.33,14.11,14.11,0,0,1,6.32-1.25,14.57,14.57,0,0,1,6.1,1.07,7.26,7.26,0,0,1,3.34,3,9.56,9.56,0,0,1,1,4.73,30.88,30.88,0,0,1-.89,6v0l0,.12s0,.06,0,.09c-.06.27-.11.53-.17.8l-2.73,12.95-.66,3.14c-1,4.59-2.92,7.83-5.61,9.81s-6.37,2.95-11.06,2.95c-3.7,0-10.29-1.34-13.4-2.06l-1.16,5.39a69.05,69.05,0,0,0,7.9,1.85,50.89,50.89,0,0,0,5.41.29q10.69,0,16.66-4.65a21.66,21.66,0,0,0,7.81-12.94l8-37.84Z"
                        transform="translate(-232.15 -219.6)"
                    />
                    <Path
                        fill="#3c3c3c"
                        d="M541.73,264.67a7.93,7.93,0,0,0-1.38-4.15,7.85,7.85,0,0,0-3.76-2.72,18.76,18.76,0,0,0-6.66-1,27.9,27.9,0,0,0-4,.33,20.46,20.46,0,0,0-4,1l-.83.34a19.25,19.25,0,0,0-5.93,3.74l4.76-22.62h-6.73l-11.77,55.84h6.74l5.17-24.53a17.72,17.72,0,0,1,3.29-4.52,13.78,13.78,0,0,1,3.56-2.42,13.23,13.23,0,0,1,3.83-1.12,29.43,29.43,0,0,1,4-.26,9.57,9.57,0,0,1,4,.67,4.24,4.24,0,0,1,2.09,1.9,6,6,0,0,1,.56,3.06,27.45,27.45,0,0,1-.67,4.15l-4.87,23.07h6.73l5.37-25.49A19.53,19.53,0,0,0,541.73,264.67Z"
                        transform="translate(-232.15 -219.6)"
                    />
                    <Path
                        fill="#3c3c3c"
                        d="M561.08,290c-.71.07-1.54.11-2.48.11a10.63,10.63,0,0,1-3.05-.34,2.59,2.59,0,0,1-1.59-1.19,4.28,4.28,0,0,1-.39-2.35,25.68,25.68,0,0,1,.59-3.87l4-18.83h10.46l.4-1.88,1-4.79H559.52l3.63-17.21h-6.7l-9.09,43.09a25.68,25.68,0,0,0-.71,6.45A6.31,6.31,0,0,0,548,293a6.07,6.07,0,0,0,3.5,1.9,28.06,28.06,0,0,0,5.79.49c.89,0,1.7,0,2.45-.11l2.17-.24,1.14-5.44C562.3,289.79,561.8,289.87,561.08,290Z"
                        transform="translate(-232.15 -219.6)"
                    />
                    <Polygon fill="#235fa5" points="12.52 16.41 0 75.83 12.16 75.83 21.69 30.67 12.52 16.41" />
                    <Polygon fill="#235fa5" points="78.81 35.77 70.38 75.84 82.56 75.84 94.06 21.17 78.81 35.77" />
                    <Path
                        fill="#4696f5"
                        d="M324.16,219.6l-40.74,39a2.34,2.34,0,0,1-3.58-.43l-15.1-23.44a3.32,3.32,0,0,0-2.8-1.53H246.8l.33.51h0l30,46.72a1.64,1.64,0,0,0,2.51.3l63.87-61.13Z"
                        transform="translate(-232.15 -219.6)"
                    />
                </Svg>
            ) : null}
        </View>
    );
}

export default Logo;
