import { Image, Page, Text, View } from '@react-pdf/renderer';
import msCover from '@/components/HighPdfReporter/assets/ms/cover-bg.png';
import ysCover from '@/components/HighPdfReporter/assets/ys/cover-bg.png';
import { useStyles } from '@/components/PdfReporter/utils/styles';
import { useContext } from 'react';
import { Context } from '../utils/common';

const CoverPage = () => {
    const { styles, commonStyles } = useStyles();
    const { type, data } = useContext(Context);
    const { user_info, create_at } = data;
    const d = new Date(create_at!);
    const Y = d.getUTCFullYear();
    let M: string | number = d.getUTCMonth() + 1;
    M = `${M < 10 ? '0' : ''}${M}`;

    return (
        <Page style={{ ...styles.body }}>
            <View style={styles.homeBodyBg}>
                <Image style={styles.homeBodyBgImage} src={type === 'ys' ? ysCover : msCover} />
            </View>
            <View style={{ ...styles.homeCoverText }}>
                <View
                    style={{
                        ...commonStyles.flex,
                        alignItems: 'flex-end',
                    }}
                >
                    <Text>姓{'         '}名</Text>
                    <Text
                        style={{
                            width: 120,
                            borderBottom: '1px solid #0D1E39',
                            textAlign: 'center',
                        }}
                    >
                        {user_info?.name || ''}
                    </Text>
                    <Text
                        style={{
                            width: 60,
                        }}
                    >
                        学{'           '}历
                    </Text>
                    <Text
                        style={{
                            width: 180,
                            borderBottom: '1px solid #0D1E39',
                            textAlign: 'center',
                        }}
                    >
                        {user_info?.education || ''}
                    </Text>
                </View>
                <View
                    style={{
                        ...commonStyles.flex,
                        alignItems: 'flex-end',
                        margin: '20pt 0',
                    }}
                >
                    <Text>性{'         '}别</Text>
                    <Text
                        style={{
                            width: 120,
                            borderBottom: '1px solid #0D1E39',
                            textAlign: 'center',
                        }}
                    >
                        {user_info?.sex || ''}
                    </Text>

                    <Text
                        style={{
                            width: 60,
                        }}
                    >
                        毕业学校
                    </Text>
                    <Text
                        style={{
                            width: 180,
                            borderBottom: '1px solid #0D1E39',
                            textAlign: 'center',
                        }}
                    >
                        {user_info?.college || ''}
                    </Text>
                </View>
                <View
                    style={{
                        ...commonStyles.flex,
                        alignItems: 'flex-end',
                    }}
                >
                    <Text>出生日期</Text>
                    <Text
                        style={{
                            width: 120,
                            borderBottom: '1px solid #0D1E39',
                            textAlign: 'center',
                        }}
                    >
                        {user_info?.birthday || ''}
                    </Text>

                    <Text
                        style={{
                            width: 60,
                        }}
                    >
                        应聘岗位
                    </Text>
                    <Text
                        style={{
                            width: 180,
                            borderBottom: '1px solid #0D1E39',
                            textAlign: 'center',
                        }}
                    >
                        {user_info?.position || ''}
                    </Text>
                </View>
                <View
                    style={{
                        ...commonStyles.flex,
                        borderBottom: '1px solid #0D1E39',
                        position: 'absolute',
                        bottom: 40,
                        left: 0,
                        right: 0,
                        fontSize: 12,
                        lineHeight: 1,
                        paddingBottom: 8,
                    }}
                >
                    <Text>人力资源部</Text>
                    <Text>
                        {Y}年{M}月
                    </Text>
                </View>
            </View>
        </Page>
    );
};

export default CoverPage;
