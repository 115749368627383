import { Svg, Path, G } from '@react-pdf/renderer';
import { usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

function IconCircle(props: { stroke?: string }) {
    const pdfType = usePdfType();

    const { stroke = 'rgba(235, 193, 84)' } = props;

    return (
        <>
            {pdfType === 'ys' ? (
                <Svg viewBox="0 0 48 48">
                    <Path
                        fill={stroke}
                        d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48Zm0-35.63A11.63,11.63,0,1,0,35.63,24,11.65,11.65,0,0,0,24,12.37Z"
                    />
                </Svg>
            ) : null}
            {pdfType === 'ms' ? (
                <Svg viewBox="0 0 70 70">
                    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <G transform="translate(11.000000, 11.000000)" stroke={stroke} strokeWidth="20.501529">
                            <Path d="M24,0 C37.2555578,0 48,10.7444422 48,24 C48,37.2506629 37.2555578,48 24,48 C10.7493371,48 0,37.2506629 0,24 C0,10.7444422 10.7493371,0 24,0 Z" />
                        </G>
                    </G>
                </Svg>
            ) : null}
        </>
    );
}

export default IconCircle;
