import { useEffect, useState } from "react";

import { login } from "@/services/users";
import { redirectToTargetPage } from "@/utils";
import { saveToken } from "@/utils/getToken";
import { useRequest } from "@/utils/request";
import { Button, Card, Form, Icon } from "ysd-pp";

import FieldCaptcha from "./FieldCaptcha";
import FieldPassword from "./FieldPassword";
import FieldUserName from "./FieldUserName";
import { getLoginErrorTimes, setLoginErrorTimes } from "./utils";

import "./LoginForm.less";

interface ILoginUser {
  username: string;
  password: string;
  captcha?: string;
}
function LoginForm() {
  const [form] = Form.useForm();

  // 用户名
  const [username, setUserName] = useState<string>("");
  // 登录错误次数
  const [errorTimes, setErrorTimes] = useState<number>(0);
  // 根据 api 返回的错误码 设置的错误消息
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>();

  const { runAsync: doLogin } = useRequest(login);

  const { captchaLoading, captchaImg, refreshCaptchaImg } =
    FieldCaptcha.useWithCaptcha({
      form,
      // username,
      // forScene: "login",
    });

  /**
   * 由于一个 username 对应着一个 错误计数器，当登录错误次数 >= 3 次的时候，需要出现验证码
   * 所以当 username 变更的时候特殊处理，是否显示验证码
   */
  useEffect(() => {
    if (username) {
      const times = getLoginErrorTimes();
      const _errorTimes = times?.[username] || 0;
      setErrorTimes(_errorTimes);

      if (_errorTimes >= 3) {
        refreshCaptchaImg();
      }
    }
  }, [username]);

  /**
   * 提交数据登录：
   * 正确的测试账户：'lxy@yeastar.com'
   * 正确的测试密码：'Yeastar123'
   *
   * 表单校验
   *
   * 登录成功后，需要做的事情：
   * 1-1. 保存 token 到 localStorage，让后续的所有 api 请求都能携带这个 token
   * 1-2. 清空错误次数计数器
   * 1-3. 清空返回错误时的错误消息
   * 1-4. 清空表单
   * 1-5. 跳转页面
   *
   * 登录失败时，需要做的事情：
   * 2-1. 根据 api 返回的 错误码 显示错误消息
   * 2-2. 设置错误次数计数器
   * 2-3. 错误次数 >= 3 的时候，刷新且显示验证码
   */
  const handleLogin = async () => {
    try {
      const data: ILoginUser = await form.validateFields();

      await doLogin(data).then((res: any) => {
        if (res && res?.code === 200) {
          if (!res?.data?.token) {
            return; // 接口数据有错，Token 不存在！
          }

          saveToken(res?.data?.token); // 1-1
          setLoginErrorTimes(data?.username, -1); // 1-2
          setErrorTimes(0); // 1-2
          setResponseErrorMessage(""); // 1-3
          form.resetFields(); // 1-4
          return redirectToTargetPage();
        }
        const message = res?.message;
        setResponseErrorMessage(message); // 2-1

        const times = setLoginErrorTimes(data?.username); // 2-2
        const newErrorTimes = times?.[data?.username] || 0; // 2-2
        setErrorTimes(newErrorTimes); // 2-2

        2 - 3;
        if (newErrorTimes >= 3) {
          refreshCaptchaImg();
        }
      });
    } catch (e) {
      // do nothing
    }
  };

  return (
    <Card className="login-box">
      <h1 className="login-title">性格测评系统</h1>
      <Form className="login-form" form={form} name="login-form">
        {responseErrorMessage ? (
          <div className="login-error-message">
            <Icon
              type="InfoIcon"
              style={{ transform: "rotate(180deg)", verticalAlign: "middle" }}
            />
            {responseErrorMessage}
          </div>
        ) : null}
        <FieldUserName
          onBlur={(e) => {
            setUserName(e.target.value);
          }}
        />
        <FieldPassword />
        {errorTimes >= 3 ? (
          <FieldCaptcha
            loading={captchaLoading}
            captchaImg={captchaImg}
            refreshCaptchaImg={refreshCaptchaImg}
          />
        ) : null}
        <Button
          id="hash__btn-login"
          className="btn-login"
          block
          type="primary"
          htmlType="submit"
          onClick={() => {
            handleLogin();
          }}
        >
          登录
        </Button>
      </Form>
    </Card>
  );
}

export default LoginForm;
