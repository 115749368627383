import copyToClipboard from 'copy-to-clipboard';
import { message } from 'ysd-pp';

export default function useCopy() {
    /**
     * 复制
     * @param prompt 提示语
     * @param value 复制的值
     */
    const copy = (prompt, value) => {
        copyToClipboard(value);
        message.success(prompt);
    };
    return copy;
}
