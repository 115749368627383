// import React, { useEffect, useMemo, useState } from "react";
// import { useRequest } from "@utils/request";
import { FieldResetPassword } from '@/pages/user/components/FieldPassword';
import { Form, Input, Modal, Select, message } from 'ysd-pp';

import styles from './index.module.less';
import { useEffect } from 'react';
import { IResponse, useRequest } from '@/utils/request';
import { registerUser, updateUser } from '@/services/users';
import { mockPwd } from '@/utils';

export interface IRegionAddEditModalProps {
    /**
     * 状态：抽屉是否展示
     */
    visible: boolean;
    /**
     * 设置抽屉是否展示的状态值
     * 通过外部传递控制 visible 字段的方法
     */
    setVisible: (visible: boolean) => void;
    reloadRegionsList?: (withSearchParams?: boolean) => void;
    /**
     * 当前正在编辑的角色，从角色列表中携带过来
     */
    record?: any;
    optionsList?: {
        label?: string;
        value?: number;
    }[];
    refreshList?: () => void;
    setCurrentRecord?: (record) => void;
}

function AddEditModal(props: IRegionAddEditModalProps) {
    const { visible, setVisible, record, optionsList, refreshList, setCurrentRecord } = props;
    const [form] = Form.useForm();

    const { runAsync: runAdd } = useRequest(registerUser);
    const { runAsync: runUpdate } = useRequest(updateUser);

    /** 初始化挂载的时候执行 */
    useEffect(() => {
        if (record?.account_id && visible) {
            form?.setFieldsValue({
                role: record?.role_ids?.[0],
                username: record?.username,
                registerPassword: mockPwd,
            });
        }
    }, [record, visible]);

    /**
     * 取消的时候做两件事情：
     * 1. 清空表单
     * 2. 关闭抽屉
     */
    const cancel = () => {
        form.setFieldsValue({
            role: 2,
            username: '',
            registerPassword: '',
        });
        setVisible(false);
    };

    /** 处理关闭事件：关闭前需要做数据比对，有数据变更需要弹窗提醒 */
    const handleOnClose = () => {
        setCurrentRecord?.({});
        cancel();
    };

    /** 处理确认事件 */
    const handleOnOk = async () => {
        try {
            const data = await form.validateFields();
            data.role_ids = [data?.role];
            data.password = data?.registerPassword;
            delete data?.role;
            delete data?.registerPassword;
            if (data?.password === mockPwd) {
                data.update_password = false;
            } else {
                data.update_password = true;
            }
            let res: IResponse<any>;
            if (record?.account_id) {
                data.account_id = record?.account_id;
                res = await runUpdate(data);
            } else {
                res = await runAdd(data);
            }

            if (res?.code === 200) {
                cancel();
                refreshList?.();
                message.success(record?.account_id ? '修改成功' : '创建成功');
            } else {
                message.error(res?.message);
            }
        } catch (e) {
            // do nothing
        }
    };

    return (
        <Modal
            visible={visible}
            title={record?.account_id ? '编辑用户' : '创建用户'}
            onOk={handleOnOk}
            onCancel={handleOnClose}
            okText="保存"
            cancelText="取消"
        >
            {/* <Spin> */}
            <Form form={form} autoComplete="new-password" layout="vertical" initialValues={{ role: 2 }}>
                <Form.Item label="用户角色" name="role" rules={[{ required: true }]}>
                    <Select
                        options={optionsList as any}
                        placeholder="请选择用户角色"
                        virtual={true}
                        className={styles['user-select-input']}
                        disabled={record?.user_id === '13ac9112-bc4c-40f5-a4e4-3183b3d1424a'}
                    />
                </Form.Item>
                <Form.Item
                    label="用户名"
                    name="username"
                    rules={[{ required: true, message: '请输入用户名' }]}
                    // initialValue=""
                >
                    <Input placeholder="请输入用户名" className={styles['username-input']} />
                </Form.Item>

                <FieldResetPassword
                    prefixIcon={false}
                    name="registerPassword"
                    label="密码"
                    placeholder="请输入密码"
                    form={form}
                    visibilityToggle={!record?.account_id}
                    onKeyDown={() => {
                        if (form?.getFieldValue('registerPassword') === mockPwd) {
                            form?.setFieldsValue({ registerPassword: '' });
                        }
                    }}
                />
                {/* <Form.Item label="密码" name="remark">
          <Input placeholder="请输入密码" type="password" />
        </Form.Item> */}
            </Form>
            {/* </Spin> */}
        </Modal>
    );
}

export default AddEditModal;
