import { StyleSheet, View } from "@react-pdf/renderer";
import { usePdfData, useStyles } from "../utils/styles";
import TableRiskIndicator from "./TableRiskIndicator";
import TextWithBlank from "./TextWithBlank";

/**
 * 3、风险指标预警
 */
function Reporter3() {
    const { commonStyles } = useStyles();

    const styles = StyleSheet.create({
        heading: { ...commonStyles.heading },
        text: { ...commonStyles.text },
    });

    const { risk_indicator_warning } = usePdfData();

    return (
        <View style={{ height: "auto" }} break>
            <TextWithBlank
                style={styles.heading}
            >{`3、风险指标预警`}</TextWithBlank>
            <View style={{ height: "auto", marginTop: 8 }}>
                <TableRiskIndicator
                    mainTitle={["心理健康的", "预警指标"]}
                    titles={["心理健康水平", "焦虑", "抑郁"]}
                    values={
                        risk_indicator_warning?.mental_health ?? ["", "", ""]
                    }
                />
            </View>
            <View style={{ height: "auto", marginTop: 8 }}>
                <TableRiskIndicator
                    mainTitle={["合作相关的", "预警指标"]}
                    titles={["乐群", "独立", "怀疑"]}
                    values={risk_indicator_warning?.cooperation ?? ["", "", ""]}
                />
            </View>
            <View style={{ height: "auto", marginTop: 8 }}>
                <TableRiskIndicator
                    mainTitle={["完成任务相关的", "预警指标"]}
                    titles={["规范", "自律", "聪慧"]}
                    values={
                        risk_indicator_warning?.complete_the_task ?? [
                            "",
                            "",
                            "",
                        ]
                    }
                />
            </View>
            <TextWithBlank
                style={{
                    ...styles.text,
                    fontSize: 12,
                    color: "#A9AAAB",
                    marginTop: 8,
                }}
            >{`注：“—”表示无风险`}</TextWithBlank>
        </View>
    );
}

export default Reporter3;
