/**
 * 为每个字之间添加空格，配合 letterSpacing: -1.8 处理 中文文本无法自动换行的问题
 */
export function addBlank(str: string) {
    return str?.split(``)?.join(` `);
}

/**
 * 绘制右侧带有圆角的矩形
 *
 * @param x 左上角的起点 x 轴
 * @param y 左上角的起点 y 轴
 * @param width 矩形的宽度
 * @param height 矩形的高度
 */
export function rectPathD(x: number, y: number, width: number, height: number) {
    const w = width - 4;
    const h = height - 2;

    return [
        `M${x},${y}`,
        `L${x + w},${y}`,
        `C${x + w + 1.1045695},${y} ${x + w + 2},${y + 0.8954305} ${
            x + w + 2
        },${y + 2}`,
        `L${x + w + 2},${y + h}`,
        `C${x + w + 2},${y + h + 1.1045695} ${x + w + 1.1045695},${y + h + 2} ${
            x + w
        },${y + h + 2}`,
        `L${x},${y + h + 2}`,
        `L${x},${y + h + 2}`,
        `L${x},${y}`,
        `Z`,
    ].join(" ");
}

/**
 * 根据值计算柱状图的宽度
 */
export function calcRectWidth(
    val: number | string | undefined,
    totalVal: number | string,
    widthScope: number
) {
    return typeof val === "undefined"
        ? 0
        : Math.floor(
              (parseFloat(val ? `${val}` : "0") / parseFloat(`${totalVal}`)) *
                  widthScope
          );
}
