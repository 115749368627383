import { View } from '@react-pdf/renderer';
import IconCircle from '@components/MbtiPdfReporterA4/components/IconCircle';
import TextWithBlank from '@components/PdfReporter/components/TextWithBlank';
import { usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

function ScoreTitle(props: { color: string; title: string; style?: any }) {
    const pdfType = usePdfType();
    const { color, title, style } = props;

    return (
        <View style={{ justifyContent: 'flex-start', flexDirection: 'row', ...style }}>
            <View
                style={{
                    width: 20,
                    height: 20,
                    marginRight: 6,
                    position: 'relative',
                    top: 1,
                    left: 0,
                    ...(pdfType === 'ms' ? { width: 20, height: 20, top: 0 } : {}),
                }}
            >
                <IconCircle stroke={color} />
            </View>
            <TextWithBlank style={{ fontSize: 17.01, letterSpacing: -1.7 }}>{title}</TextWithBlank>
        </View>
    );
}

export default ScoreTitle;
