import { StyleSheet } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import { PdfData } from '../services';
import { fontSize } from './fontSize';

export type TpDFType = 'ms' | 'ys';

const textStyles = StyleSheet.create({
    normalText: {
        fontFamily: 'PingFangSC400',
        fontWeight: 400,
    },
    strongText: {
        fontFamily: 'PingFangSC500',
        fontWeight: 500,
    },
    fontSize10: fontSize(10),
    fontSize12: fontSize(12),
    fontSize14: fontSize(14),
    fontSize16: fontSize(16),
    fontSize18: fontSize(18),
});

const getColors = (type: TpDFType) => {
    /**
     * pdf 的背景颜色
     */
    const bgColor = {
        ms: '#EDF3FC',
        ys: '#F2F4F6',
    };

    /**
     * pdf 的主色调
     */
    const themeColor = {
        ms: '#4696F5',
        ys: '#006EE1',
    };

    return {
        bgColor: bgColor[type],
        themeColor: themeColor[type],
    };
};

/**
 * 通用的基础样式
 */
const commonStyles = () =>
    StyleSheet.create({
        ...textStyles,
        heading: {
            ...textStyles.strongText,
            ...textStyles.fontSize16,
            color: '#000000',
        },
        text: {
            color: '#000000',
            ...textStyles.normalText,
            ...textStyles.fontSize12,
        },
        borderLeft: {
            borderLeftWidth: 1,
            borderLeftColor: '#666666',
            borderLeftStyle: 'solid',
        },
        borderBottom: {
            borderBottomWidth: 1,
            borderBottomColor: '#666666',
            borderBottomStyle: 'solid',
        },
        borderRight: {
            borderRightWidth: 1,
            borderRightColor: '#666666',
            borderRightStyle: 'solid',
        },
        borderTop: {
            borderTopWidth: 1,
            borderTopColor: '#666666',
            borderTopStyle: 'solid',
        },
        border: {
            borderWidth: 1,
            borderColor: '#666666',
            borderStyle: 'solid',
        },
        indent: {
            // textIndent: 32,
        },
        flex: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    });

/**
 * 表格的基础样式
 */
const tableStyles = (type: TpDFType) =>
    StyleSheet.create({
        table: {
            //
        },
        tableRow: {
            flexDirection: 'row',
            alignItems: 'stretch',
        },
        tableHeadRow: {
            backgroundColor: getColors(type).themeColor,
            color: '#FFFFFF',
            textAlign: 'center',
        },
        tableBodyRow: {
            backgroundColor: '#F9F9F9',
            textAlign: 'center',
        },
        tableCell: {
            alignItems: 'center',
        },
        tableHeadCell: {
            //
        },
        tableBodyCell: {
            //
        },
    });

/**
 * 图表相关的基础样式
 */
const chartStyles = () => {
    const cs = commonStyles();

    return StyleSheet.create({
        title: {
            ...cs.normalText,
            fontSize: 10,
        },
        value: {
            ...cs.normalText,
            fontSize: 10,
        },
        labelText: {
            ...cs.normalText,
            fontSize: 9,
        },
        levelText: {
            ...cs.normalText,
            fontSize: 10,
        },
    });
};

/**
 * 报告文本的一些基础样式
 */
const styles = (type: TpDFType) =>
    StyleSheet.create({
        body: {
            padding: 0,
            width: 595,
            height: 842,
        },
        homeBodyBg: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        homeBodyBgImage: {
            width: '100%',
            height: '100%',
        },
        homeBodyText: {
            position: 'absolute',
            top: 430,
            left: 72,
            // lineHeight: 28,
            ...textStyles.fontSize14,
            fontFamily: 'Alibaba PuHuiTi',
            color: '#9D9D9D',
        },
        homeBodyTextForYs: {
            position: 'absolute',
            bottom: 0,
            left: 72,
            fontFamily: 'DingTalk JinBuTi',
            color: '#0D1E39',
            height: 180,
            right: 72,
            ...fontSize(16),
        },
        homeCoverText: {
            position: 'absolute',
            bottom: 0,
            left: 42,
            fontFamily: 'DingTalk JinBuTi',
            color: '#101F3A',
            height: 210,
            right: 42,
            ...fontSize(16),
        },
        homeBodyContent: {
            position: 'absolute',
            top: 80,
            bottom: 0,
            left: 42,
            fontFamily: 'DingTalk JinBuTi',
            color: '#0D1E39',
            right: 42,
            ...fontSize(16),
        },
        homeYsLabel: {
            position: 'absolute',
            width: 80,
            justifyContent: 'space-between',
        },
        homeYsValue: {
            position: 'absolute',
            width: 180,
            paddingBottom: 3,
            borderBottom: '2px solid #0D1E39',
        },
        nameFont: {
            fontFamily: 'Alibaba PuHuiTi Medium',
            transform: 'skewX(-5deg)',
        },
        pageNumber: {
            bottom: 0,
        },
        heading: {
            fontSize: 18,
        },
        strong: {
            fontWeight: 'bold',
        },
        content: {
            fontSize: 14,
            textAlign: 'left',
            fontFamily: 'PingFangSC400',
            padding: 24,
            letterSpacing: -1.8,
            backgroundColor: getColors(type).bgColor,
        },
        paragraph: {
            textIndent: 32,
            margin: '12pt 0',
        },
        table: {
            display: 'flex',
            width: '100%',
            margin: '0',
        },
        tableRow: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        tableCell: {
            textAlign: 'center',
            color: 'black',
        },
    });

export const Context = React.createContext<{
    type: TpDFType;
    data: PdfData;
}>({
    type: 'ys',
    data: {},
});

export function usePdfData() {
    const { data } = useContext(Context);

    return {
        ...data,
        risk_indicator_warning: {
            mental_health: data.risk_indicator_warning?.mental_health?.map(item => (item === '--' ? '' : item)),
            complete_the_task: data.risk_indicator_warning?.complete_the_task?.map(item => (item === '--' ? '' : item)),
            cooperation: data.risk_indicator_warning?.cooperation?.map(item => (item === '--' ? '' : item)),
        },
        career_related: data.career_related?.map(
            item =>
                ({
                    低: 1,
                    中: 2,
                    高: 3,
                })[item],
        ),
        career_prediction: data.career_prediction?.map(
            item =>
                ({
                    低: 1,
                    中: 2,
                    高: 3,
                })[item],
        ),
    } as PdfData;
}

export function useStyles(type?: TpDFType) {
    const { type: _type } = useContext(Context);
    const realType = type ?? _type;

    return {
        colors: getColors(realType),
        commonStyles: commonStyles(),
        chartSTyles: chartStyles(),
        tableStyles: tableStyles(realType),
        styles: styles(realType),
    };
}
