import React from 'react';
import { TpDFType } from '@/components/PdfReporter/utils/styles';
import { PdfDataForHigh } from '@/components/PdfReporter/services/high';

export const Context = React.createContext<{
    type: TpDFType;
    data: PdfDataForHigh;
}>({
    type: 'ys',
    data: {},
});
