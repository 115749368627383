// import React, { useEffect, useMemo, useState } from "react";
// import { useRequest } from "@utils/request";
import { Form, Icon, Modal, Select } from 'ysd-pp';

import styles from './index.module.less';
import useCopy from '@/utils/hooks/useCopy';
import { useRequest } from '@/utils/request';
import { apiEntrancePost } from '@/pages/appraisals/services';
export interface ILinkModalProps {
    /**
     * 状态：抽屉是否展示
     */
    visible: boolean;
    /**
     * 设置抽屉是否展示的状态值
     * 通过外部传递控制 visible 字段的方法
     */
    setVisible: (visible: boolean) => void;
    reloadRegionsList?: (withSearchParams?: boolean) => void;
    /**
     * 当前正在编辑的角色，从角色列表中携带过来
     */
    record?: any;
    optionsList?: {
        label?: string;
        value?: number;
    }[];
    refreshList?: () => void;
    setCurrentRecord?: (record) => void;
    companyOptions?: any[];
    surveyTypeOptions?: any[];
}

function LinkModal(props: ILinkModalProps) {
    const { visible, setVisible, refreshList, companyOptions, surveyTypeOptions } = props;
    const [form] = Form.useForm();
    const copy = useCopy();

    // 生成链接
    const { runAsync: getEntranceUrl } = useRequest(params =>
        apiEntrancePost(params).then(res => {
            return res;
        }),
    );
    const renderLink = async values => {
        // 生成复制链接
        const obj = {
            ...values,
            effective_time: 24 * 60 * 60,
        };
        const res = await getEntranceUrl(obj);
        if (res?.code === 200) {
            let url = res?.data?.url;
            // const origin = window.location.origin;
            // const substring = path?.substring(path?.indexOf('/client'), path?.length);
            // const newString = origin + substring;
            if (!import.meta.env.PROD) {
                url = url?.replace('client/', `${values?.company_code === 0 ? 'ys' : 'ms'}.html`);
            }
            onCancel();
            copy('生成成功，链接已复制', url);
            refreshList?.();
        } else {
            // message.error(res?.message);
            Modal.error({
                title: '生成失败',
                content: '系统遇到一些问题，请联系技术人员。',
                okText: '确认',
            });
        }
    };
    /** 处理确认事件 */
    const handleOnOk = async () => {
        renderLink(form?.getFieldsValue());
    };
    const onCancel = () => {
        form.resetFields();
        setVisible(false);
    };
    return (
        <Modal
            title="生成测评链接"
            visible={visible}
            onCancel={onCancel}
            // footer={null}
            // width={300}
            className={styles['link-modal']}
            okText="生成链接"
            onOk={handleOnOk}
        >
            <div className={styles['top-tip']}>
                <Icon type="info-f" className={styles['icon']} />
                <div>注意：请点击生成对应的测评链接，链接一旦生成，24小时内有效，请尽快发给对应的测评者。</div>
            </div>
            <Form layout="vertical" form={form} initialValues={{ company_code: 1, survey_code: 0 }}>
                <Form.Item label="品牌" name="company_code" rules={[{ required: true, message: '请选择品牌' }]}>
                    <Select placeholder="请选择品牌" options={companyOptions} />
                </Form.Item>
                <Form.Item label="测评工具" name="survey_code" rules={[{ required: true, message: '请选择测评工具' }]}>
                    <Select placeholder="请选择测评工具" options={surveyTypeOptions} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default LinkModal;
