import { useEffect } from 'react';
import { DatePicker, Form, Icon, Modal } from 'ysd-pp';
import styles from '../LinkModal/index.module.less';
import { useRequest } from '@/utils/request';
import { apiGetMbtiValidTime, apiUpdateMbtiValidTime } from '@/pages/appraisals/services';
import moment from 'moment';
import classNames from 'classnames';

const { RangePicker } = DatePicker;

export interface IMbtiValidModalProps {
    /**
     * 状态：抽屉是否展示
     */
    visible: boolean;
    // 关闭弹窗
    onCancel: () => void;
}

function MbtiValidModal(props: IMbtiValidModalProps) {
    const { visible, onCancel } = props;
    const [form] = Form.useForm();
    const format = 'YYYY-MM-DD HH:mm:ss'; // 字符串的格式

    // 生成链接
    const { runAsync: getMbtiValidTime } = useRequest(apiGetMbtiValidTime);
    const { runAsync: updateMbtiValidTime } = useRequest(apiUpdateMbtiValidTime);

    useEffect(() => {
        if (visible) {
            getMbtiValidTime().then(res => {
                if (res?.code === 200) {
                    const { start_time, end_time } = res.data || {};
                    form.setFieldsValue({
                        time: [moment(start_time, format), moment(end_time, format)],
                    });
                }
            });
        }
    }, [visible]);

    /** 处理确认事件 */
    const handleOnOk = async () => {
        const data = await form.validateFields();
        const start_time = data.time[0].format(format);
        const end_time = data.time[1].format(format);
        const res = await updateMbtiValidTime({ start_time, end_time });
        if (res?.code === 200) {
            onCancel();
        }
    };
    return (
        <Modal
            title="测评有效期设置"
            visible={visible}
            onCancel={onCancel}
            className={classNames(styles['link-modal'], styles['mbti-valid-modal'])}
            okText="保存"
            onOk={handleOnOk}
            destroyOnClose
        >
            <div className={styles['top-tip']}>
                <Icon type="info-f" className={styles['icon']} />
                <div>请进行MBTI社区入口测评有效期时间设置。</div>
            </div>
            <Form layout="vertical" form={form} preserve={false}>
                <Form.Item label="MBTI测评有效期" name="time" rules={[{ required: true, message: '请选择有效期' }]}>
                    <RangePicker
                        showTime
                        className={styles['mbti-valid-date']}
                        dropdownClassName={styles['mbti-valid-date-dropdown']}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default MbtiValidModal;
