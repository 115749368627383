import { StyleSheet, View } from '@react-pdf/renderer';
import { usePdfData, useStyles } from '../utils/styles';
import TextWithBlank from './TextWithBlank';
import { fontSize } from '../utils/fontSize';

/**
 * 1、整体结果
 */
function Reporter1() {
    const { commonStyles, colors } = useStyles();

    const styles = StyleSheet.create({
        heading: { ...commonStyles.heading },
        text: { ...commonStyles.text },
        paragraph: {
            ...commonStyles.indent,
        },
    });

    const { overall_results } = usePdfData();

    /**
     * 根据字符数划分行数，
     * 不同行数，显示不同的行高和字号
     */
    let sizeHeight = {};
    if (overall_results) {
        const len = overall_results.length;

        if (len > 259 && len <= 343) {
            // >= 7 行
            sizeHeight = fontSize(12, 16);
        } else if (len > 343 && len <= 386) {
            // >= 9 行
            sizeHeight = fontSize(11, 15);
        } else if (len > 386) {
            // >= 10 行
            sizeHeight = fontSize(10, 14);
        }
    }

    return (
        <>
            <View
                style={{
                    ...commonStyles.strongText,
                    ...commonStyles.fontSize14,
                    backgroundColor: colors.themeColor,
                    color: 'white',
                    height: 36,
                    width: 595,
                    flexDirection: 'row',
                    textAlign: 'center',
                    alignItems: 'center',
                    lineHeight: 1,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }}
                break
            >
                <TextWithBlank>{`测评结果`}</TextWithBlank>
            </View>

            <View style={{ height: 'auto', marginTop: 24 + 16 }}>
                <TextWithBlank style={styles.heading}>{`1、整体结果`}</TextWithBlank>
                <TextWithBlank
                    style={{
                        ...styles.text,
                        ...styles.paragraph,
                        marginTop: 8,
                        color: colors.themeColor,
                        paddingRight: 10,
                        ...sizeHeight,
                    }}
                >
                    {`　　${overall_results}`}
                </TextWithBlank>
            </View>
        </>
    );
}

export default Reporter1;
