import { Svg, Polygon } from '@react-pdf/renderer';
import { useContext } from 'react';
import { Context } from '../utils/common';
import { useStyles } from '@/components/PdfReporter/utils/styles';

const Triangle = () => {
    const { type } = useContext(Context);
    const { colors } = useStyles(type);

    return (
        <Svg width="6.43" height="6.99" viewBox="0 0 6.43 6.99">
            <Polygon points="0,0 6.43,3.5 0,6.99" fill={colors.themeColor} />
        </Svg>
    );
};

export default Triangle;
