import { useNavigate } from "react-router-dom";

import type { TChangePasswordParams } from "@/services/users";
import { logout } from "@/services/users";
import { changePassword } from "@/services/users";
import { clearToken } from "@/utils/getToken";
import { useRequest } from "@/utils/request";
// import { stringify } from 'querystring';
import { Form, Input, Modal } from "ysd-pp";

import FieldPassword, { FieldResetPassword } from "./FieldPassword";

import "./ChangePassword.less";

export interface IChangePasswordProps {
    visible?: boolean;
    onVisibleChange?: (val: boolean) => void;
}
function ChangePassword(props: IChangePasswordProps) {
    const { visible, onVisibleChange } = props;

    const [form] = Form.useForm();
    const navigate = useNavigate();

    // const [visible, setVisible] = useState<boolean>(false);

    const { loading, runAsync: doChangePassword } = useRequest(changePassword);

    /**
     * 提交数据登录：
     * 正确的测试账户：'lxy@yeastar.com'
     * 正确的测试密码：'Yeastar123'
     *
     * 表单校验
     *
     * 密码重置成功后，需要做的事情：
     * 1-1. 清空表单
     * 1-2. 清空错误信息
     * 1-3. 提示重置密码成功
     * 1-4. 跳转到登录页面
     *
     * 密码重置失败时，需要做的事情：
     * 2-1. 根据 api 返回的 错误码 显示错误消息
     */
    const handleOnOk = async () => {
        try {
            const data: TChangePasswordParams = await form.validateFields();
            // data.old_password = data?.oldPassword;
            // data.new_password = data?.newPassword;
            // data.confirm_new_password = data?.confirmNewPassword;
            await doChangePassword({
                ...data,
            }).then((res: any) => {
                if (res?.code === 200) {
                    form.resetFields(); // 1-4

                    return Modal.success({
                        content: "重置密码成功，请使用新密码重新登录",
                        onOk() {
                            logout().then((_res: any) => {
                                if (_res?.code === 200) {
                                    clearToken();
                                    onVisibleChange?.(false);
                                    navigate("/user/login");
                                } else {
                                    // message.error(apiErrorMessage(_res?.code, formatMessage));
                                }
                            });
                        },
                    });
                } else {
                    // message.error(apiErrorMessage(res?.code, formatMessage));
                }
            });
        } catch (e) {
            // do nothing
        }
    };

    return (
        <Modal
            width={600}
            maskClosable={true}
            visible={visible}
            title="修改密码"
            okText="保存"
            onOk={handleOnOk}
            okButtonProps={{
                loading,
            }}
            onCancel={() => {
                form.resetFields();
                onVisibleChange?.(false);
            }}
            cancelText="取消"
            // zIndex={1051}
        >
            <Form
                className="change-password-form"
                layout="vertical"
                form={form}
            >
                <FieldPassword
                    prefixIcon={false}
                    name="old_password"
                    label="旧密码"
                    placeholder="请输入旧密码"
                />
                <FieldResetPassword
                    prefixIcon={false}
                    name="new_password"
                    label="新密码"
                    placeholder="请输入新密码"
                    form={form}
                />
                <Form.Item
                    name="confirm_new_password"
                    label="再次输入新密码"
                    validateFirst
                    rules={[
                        {
                            required: true,
                            message: "请再次输入新密码",
                        },
                        {
                            validator(_, value) {
                                const newPassword =
                                    form.getFieldValue("new_password");
                                if (value !== newPassword) {
                                    return Promise.reject(
                                        "两次输入的密码不一致，请重新输入"
                                    );
                                }

                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input.Password placeholder="请再次输入新密码" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ChangePassword;
