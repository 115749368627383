import React, { memo, useCallback, useEffect, useState } from "react";

import classNames from "classnames";
import moment from "moment";
import { Button, Checkbox, Empty, Input, Radio, Space, Tooltip } from "ysd-pp";
import RangePicker from "./RangePicker";
import type { IProps } from "./data";

import "./index.less";
import { usePrevious } from "ahooks";
import { difference } from "lodash";

const HeaderSearchDropdown: React.FC<IProps> = ({
  // visible,
  confirm,
  columnItem: {
    dataIndex,
    searchType,
    filterList,
    filterListDefaultValue = "",
    customSearchRender,
  },
  onSearch,
  onSearchValueChange,
  searchParams = {},
  rangePikerProps = {},
}) => {
  const [sTime, setStartTime] = useState<moment.Moment | undefined>(undefined);
  const [eTime, setEndTime] = useState<moment.Moment | undefined>(undefined);
  const [localFilterList, setLocalFilterList] = useState<any[]>(filterList);
  // searchType='input'时，输入框值
  const [inputValue, setInputValue] = useState<string>("");
  // searchType='checkbox' | 'searchCheckbox'时，多选框值
  const [checkValue, setCheckValue] = useState<any[]>([]);
  // 支持搜索复选框的输入框值，用于组件内部搜索筛选
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  // searchType = 'radio'
  const [radioValue, setRadioValue] = useState<string | number>("");
  const prevChecked = usePrevious(searchParams[dataIndex] || []);

  // 数据回填
  useEffect(() => {
    const param = searchParams?.[dataIndex];
    if (searchType === "rangePicker" && dataIndex && param?.[0] && param?.[1]) {
      setStartTime(moment(new Date(param?.[0])));
      setEndTime(moment(new Date(param?.[1])));
    }
    if (searchType === "input") {
      setInputValue(typeof param === "string" ? param : "");
    }
    if (searchType === "checkbox" || searchType === "searchCheckbox") {
      setCheckValue(Array.isArray(param) ? param : []);
    }
    if (searchType === "radio") {
      setRadioValue(param ?? filterListDefaultValue);
    }
  }, [dataIndex, searchParams, searchType, filterListDefaultValue]);

  // 处理多选的数据
  useEffect(() => {
    if (searchType === "searchCheckbox") {
      const searchFilterList = filterList.filter((item) => {
        if (typeof item.label === "string")
          return item.label
            ?.toLowerCase()
            ?.includes(searchInputValue?.toLowerCase());
        return false;
      });
      setLocalFilterList(searchFilterList);
    }
  }, [filterList, searchInputValue, searchType]);

  //对日历进行赋值，选择第二个日期后，进行查询
  const handleCalendarSearch = (e: [moment.Moment, moment.Moment]) => {
    if (e?.filter((item) => item).length === 2) {
      const [startTime, endTime] = e;
      const startLastConnectedTime = moment(startTime).valueOf();
      const endLastConnectedTime = moment(endTime).valueOf();
      setStartTime(
        startLastConnectedTime === endLastConnectedTime
          ? undefined
          : moment(startTime)
      );
      setEndTime(
        startLastConnectedTime === endLastConnectedTime
          ? undefined
          : moment(endTime)
      );
      const startLastConnectedTimeTemp = parseInt(
        String(startLastConnectedTime),
        10
      );
      const endLastConnectedTimeTemp = parseInt(
        String(endLastConnectedTime),
        10
      );
      onSearchValueChange(
        dataIndex,
        [startLastConnectedTimeTemp, endLastConnectedTimeTemp],
        true
      );
      confirm();
    }
  };

  //重置日历时间
  const handleCalendarReset = (e: any) => {
    if (e === undefined) {
      setStartTime(undefined);
      setEndTime(undefined);
      onSearchValueChange(dataIndex, "", true);
      confirm();
    }
  };

  const handleReset = () => {
    onSearchValueChange(dataIndex, "", true);
    setCheckValue([]);
    setInputValue("");
    setRadioValue(filterListDefaultValue);
    if (searchInputValue) {
      setTimeout(() => {
        if (searchType === "searchCheckbox") {
          setSearchInputValue("");
        }
      }, 300);
    } else {
      setSearchInputValue("");
    }
    onSearch();
    confirm();
  };

  //输入框，多选框进行搜索
  const handleSearch = () => {
    if (searchType === "input") {
      onSearchValueChange(dataIndex, inputValue);
    }
    if (searchType === "checkbox" || searchType === "searchCheckbox") {
      onSearchValueChange(dataIndex, checkValue);
    }
    if (searchType === "radio") {
      onSearchValueChange(dataIndex, radioValue);
    }
    onSearch();
    confirm();
  };

  const handleCheckboxGroupChange = (checkedValue: any[]) => {
    if (searchType === "searchCheckbox") {
      const currentOptionValue = localFilterList.map((item) => item.value);
      const prevCheckedValue = difference(prevChecked, currentOptionValue);
      setCheckValue([...checkedValue, ...prevCheckedValue]);
    } else {
      setCheckValue(checkedValue);
    }
  };

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      //   const realValue =
      //     value.length > SEARCH_LIMIT ? value.substr(0, SEARCH_LIMIT) : value;
      setInputValue(value);
    },
    []
  );

  const handleSearchCheckboxInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInputValue(e.target.value);
    },
    []
  );

  const handleRadioChange = useCallback((e: any) => {
    setRadioValue(() => {
      const _radioValue = e.target.value;

      onSearchValueChange(dataIndex, _radioValue, true);
      confirm();
      return _radioValue;
    });
  }, []);

  const getRadioRender = () => {
    const radioArr = [] as any[];
    let filterListWithAll = [...filterList];
    /**
     * 注意：All 一般默认是空字符串，如果不是就会出错
     *
     * 这里如果是 All 就是空字符串，空字符串作为 key 可能会存在错误，所以默认使用 * 代替
     */
    if (!filterList.find((radio) => radio.value === "")) {
      filterListWithAll = [
        {
          label: "全部",
          value: "",
        },
        ...filterList,
      ];
    }
    filterListWithAll?.map((radio: any) => {
      radioArr.push(
        <Radio key={radio.value || "*"} value={radio.value}>
          <Tooltip title={radio.label} type="ellipsisTooltip" />
        </Radio>
      );
    });
    return radioArr;
  };

  return searchType === "custom" ? (
    <>{customSearchRender?.()}</>
  ) : (
    <div
      className={classNames(
        "ysd-bus-table-header-search-dropdown",
        searchType === "rangePicker" &&
          "ysd-bus-table-header-search-dropdown-range-picker"
      )}
    >
      {searchType === "input" && (
        <Input
          className={"ysd-bus-table-header-search-dropdown-input"}
          type="text"
          autoFocus
          value={inputValue}
          onChange={handleInputChange}
          //   placeholder={getMessage("common.search_placeholder", "") as string}
          onPressEnter={handleSearch}
        />
      )}
      {searchType === "checkbox" && (
        <div className={"ysd-bus-table-header-search-dropdown-checkbox"}>
          <Checkbox.Group
            value={checkValue || []}
            onChange={handleCheckboxGroupChange}
            options={filterList.map((item) => ({
              ...item,
              label: <Tooltip title={item.label} type="ellipsisTooltip" />,
            }))}
          />
        </div>
      )}
      {searchType === "searchCheckbox" && (
        <>
          <div
            className={
              "ysd-bus-table-header-search-dropdown-search-checkbox-input"
            }
          >
            <Input
              className={"ysd-bus-table-header-search-dropdown-input"}
              type="text"
              autoFocus
              allowClear
              value={searchInputValue}
              onChange={handleSearchCheckboxInputChange}
              //   placeholder={
              //     getMessage("common.search_placeholder", "") as string
              //   }
            />
          </div>
          <div
            className={
              "ysd-bus-table-header-search-dropdown-search-checkbox-wrap"
            }
          >
            {localFilterList.length ? (
              <Checkbox.Group
                value={checkValue || []}
                onChange={handleCheckboxGroupChange}
                options={localFilterList.map((item) => ({
                  ...item,
                  label: <Tooltip title={item.label} type="ellipsisTooltip" />,
                }))}
              />
            ) : (
              <Empty />
            )}
          </div>
        </>
      )}
      {searchType === "rangePicker" && (
        <RangePicker
          {...rangePikerProps}
          format={rangePikerProps.format}
          showTime={{
            format: "HH:mm:ss",
            defaultValue: [
              moment("00:00:00", "HH:mm:ss"),
              moment("23:59:59", "HH:mm:ss"),
            ],
          }}
          onOk={(e: any) => handleCalendarSearch(e)}
          onChange={(e: any) => handleCalendarSearch(e)}
          onCalendarChange={(e: any) => handleCalendarReset(e)}
          value={sTime && eTime ? [moment(sTime), moment(eTime)] : undefined}
        />
      )}
      {searchType === "radio" && filterList?.length && (
        <div className={"ysd-bus-table-header-search-dropdown-radio"}>
          <Radio.Group onChange={handleRadioChange} value={radioValue}>
            {getRadioRender()}
          </Radio.Group>
        </div>
      )}
      {["input", "checkbox", "searchCheckbox"].includes(String(searchType)) && (
        <Space className={"ysd-bus-table-header-search-dropdown-btn"} size="lg">
          <Button type="default" onClick={handleReset}>
            重置
          </Button>
          <Button type="primary" onClick={handleSearch}>
            搜索
          </Button>
        </Space>
      )}
    </div>
  );
};

export default memo(HeaderSearchDropdown);
