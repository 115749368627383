import { Polyline, StyleSheet, Svg, View } from '@react-pdf/renderer';
import { usePdfData, useStyles } from '../utils/styles';
import Star from './Star';
import TextWithBlank from './TextWithBlank';

function TableOccupationalFactorPoints() {
    const { commonStyles, colors } = useStyles();

    const tbStyles = StyleSheet.create({
        table: {
            textAlign: 'center',
            ...commonStyles.text,
        },
        blueCellBg: {
            backgroundColor: colors.themeColor,
            color: 'white',
        },
        row: {
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexDirection: 'row',
            textAlign: 'center',
            backgroundColor: '#F9F9F9',
            color: 'black',
        },
        head: {
            //
        },
        cell: {
            height: 64,
            paddingLeft: 6,
            paddingRight: 6,
            lineHeight: 16 / 12,
        },
        titlePointCellText: { marginTop: 8, lineHeight: 1 },
    });

    const { career_related: scopes = [] } = usePdfData();

    const dataSource: {
        factor: string;
        lowScopeFeature: string;
        highScopeFeature: string;
        /**
         * 文字居中需要的 marginTop 大小
         * 分别对应着：人格因素、低分特征、高分特征
         */
        marginTops: [number, number, number];
        /**
         * 星星要定位的位置 y
         */
        y: number;
    }[] = [
        // 32, 48
        {
            factor: '焦虑性',
            lowScopeFeature: '放松：|对预期的目标有把握，有时易随遇而安',
            highScopeFeature: '紧张：|激动、焦虑、情绪调节不良，可能影响身体健康',
            marginTops: [28, 8, 8],
            y: (64 + 1) * 0 + 64 / 2,
        },
        {
            factor: '外向性',
            lowScopeFeature: '内向：|羞怯而审慎，与人相处多拘谨不自然',
            highScopeFeature: '外向：|善于交际，不计小节，不受拘束',
            marginTops: [28, 8, 8],
            y: (64 + 1) * 1 + 64 / 2,
        },
        {
            factor: '进取性',
            lowScopeFeature: '多愁善感：|含蓄敏感，温文尔雅，讲究生活艺术',
            highScopeFeature: '刚毅进取：|有时过分现实，忽视生活情趣',
            marginTops: [28, 8, 8],
            y: (64 + 1) * 2 + 64 / 2,
        },
        {
            factor: '果断性',
            lowScopeFeature: '优柔寡断：|缺乏主见和独立性',
            highScopeFeature: '独立果断：|锋芒毕露，有气魄',
            marginTops: [28, 16, 16],
            y: (64 + 1) * 3 + 64 / 2,
        },
    ];

    const points = scopes.map((item, itemIndex) => {
        return [item * 67 - 67 / 2, dataSource[itemIndex].y];
    });

    const splitText = (text: string) => {
        if (text.indexOf('|') > -1) {
            const [title, desc] = text.split('|');

            return (
                <View style={{ height: 'auto' }}>
                    <TextWithBlank style={{ ...commonStyles.strongText, marginBottom: 4 }}>{title}</TextWithBlank>
                    <TextWithBlank>{desc}</TextWithBlank>
                </View>
            );
        }

        return <TextWithBlank>{text}</TextWithBlank>;
    };

    return (
        <View style={{ ...tbStyles.table, ...commonStyles.text, width: 547 }}>
            <View
                style={{
                    ...tbStyles.row,
                    ...tbStyles.head,
                    ...tbStyles.blueCellBg,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    height: 56,
                    alignItems: 'stretch',
                }}
            >
                <View
                    style={{
                        width: 56,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                        borderTopLeftRadius: 4,
                    }}
                >
                    <TextWithBlank style={{ ...commonStyles.strongText, marginTop: 10 }}>人格</TextWithBlank>
                    <TextWithBlank style={{ ...commonStyles.strongText }}>因素</TextWithBlank>
                </View>
                <View
                    style={{
                        width: 145,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                    }}
                >
                    <TextWithBlank style={{ ...commonStyles.strongText, marginTop: 22 }}>低分特征</TextWithBlank>
                </View>
                <View
                    style={{
                        width: 200,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                    }}
                >
                    <View style={{ ...commonStyles.borderBottom, height: 30 }}>
                        <TextWithBlank
                            style={{
                                ...tbStyles.titlePointCellText,
                                ...commonStyles.strongText,
                            }}
                        >
                            标准分
                        </TextWithBlank>
                    </View>
                    <View
                        style={{
                            ...tbStyles.row,
                            ...tbStyles.blueCellBg,
                            height: 26,
                            alignItems: 'stretch',
                            ...commonStyles.fontSize12,
                        }}
                    >
                        <View
                            style={{
                                width: 67,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                低
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 67,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                中
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 66,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                高
                            </TextWithBlank>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        width: 146,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderRight,
                        ...commonStyles.borderBottom,
                        borderTopRightRadius: 4,
                    }}
                >
                    <TextWithBlank style={{ marginTop: 22, ...commonStyles.strongText }}>高分特征</TextWithBlank>
                </View>
            </View>
            {dataSource.map((item, itemIndex) => {
                const isLastItem = itemIndex === dataSource.length - 1;

                return (
                    <View
                        key={item.factor}
                        style={{
                            backgroundColor: '#F9F9F9',
                            flexDirection: 'row',
                            alignItems: 'stretch',
                            ...commonStyles.text,
                            ...commonStyles.fontSize12,
                            ...commonStyles.borderBottom,
                            ...(isLastItem
                                ? {
                                      borderBottomLeftRadius: 4,
                                      borderBottomRightRadius: 4,
                                  }
                                : {}),
                        }}
                    >
                        <View
                            style={{
                                ...tbStyles.cell,
                                width: 56,
                                ...commonStyles.borderLeft,
                                ...(isLastItem ? { borderBottomLeftRadius: 4 } : {}),
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...commonStyles.strongText,
                                    marginTop: item.marginTops[0],
                                }}
                            >
                                {item.factor}
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                ...tbStyles.cell,
                                ...commonStyles.borderLeft,
                                width: 145,
                                textAlign: 'left',
                            }}
                        >
                            <View
                                style={{
                                    marginTop: item.marginTops[1],
                                }}
                            >
                                {splitText(item.lowScopeFeature)}
                            </View>
                        </View>
                        <View
                            style={{
                                ...commonStyles.borderLeft,
                                width: 67,
                            }}
                        ></View>
                        <View
                            style={{
                                ...commonStyles.borderLeft,
                                borderLeftStyle: 'dashed',
                                borderLeftColor: '#CCCCCC',
                                width: 67,
                            }}
                        ></View>
                        <View
                            style={{
                                ...commonStyles.borderLeft,
                                borderLeftStyle: 'dashed',
                                borderLeftColor: '#CCCCCC',
                                width: 66,
                            }}
                        ></View>
                        <View
                            style={{
                                ...tbStyles.cell,
                                ...commonStyles.borderLeft,
                                ...commonStyles.borderRight,
                                width: 146,
                                textAlign: 'left',
                                ...(isLastItem
                                    ? {
                                          borderBottomRightRadius: 4,
                                      }
                                    : {}),
                            }}
                        >
                            <View
                                style={{
                                    marginTop: item.marginTops[2],
                                }}
                            >
                                {splitText(item.highScopeFeature)}
                            </View>
                        </View>
                    </View>
                );
            })}
            <View
                style={{
                    position: 'absolute',
                    top: 60,
                    left: 201,
                    width: 200,
                    height: 660,
                }}
            >
                <Svg
                    style={{
                        width: 200,
                        height: 660,
                    }}
                >
                    <Polyline
                        stroke={colors.themeColor}
                        strokeWidth={1}
                        points={points.map(item => item.join(',')).join(' ')}
                    />
                </Svg>
                {dataSource.map((item, itemIndex) => {
                    return (
                        <Star
                            key={`${item.y}-${itemIndex}`}
                            top={item.y - 8}
                            left={scopes[itemIndex] * 67 - 67 / 2 - 8}
                            width={16}
                            height={16}
                            padding={2}
                            backgroundColor="#F9F9F9"
                        />
                    );
                })}
            </View>
        </View>
    );
}

export default TableOccupationalFactorPoints;
