import {
    G,
    Line,
    Path,
    Rect,
    Svg,
    Text,
    Tspan,
    View,
} from "@react-pdf/renderer";
import { calcRectWidth, rectPathD } from "../utils";
import { usePdfData, useStyles } from "../utils/styles";

function ChartDevelopmentPotential() {
    const { chartSTyles: cStyles, colors } = useStyles();
    const { development_potential } = usePdfData();

    const width = 531 - 67;

    const generateProps = (w: number) => {
        return {
            fill: w < 30 ? colors.themeColor : "#fff",
            textAnchor: w < 30 ? "start" : "end",
            x: w + 67 + (w < 30 ? 8 : -4),
        };
    };

    /** 亲和动机 */
    const valA = calcRectWidth(development_potential![0], 10, width);
    const valAProps = generateProps(valA);
    /** 成就动机 */
    const valB = calcRectWidth(development_potential![1], 10, width);
    const valBProps = generateProps(valB);
    /** 权力动机 */
    const valC = calcRectWidth(development_potential![2], 10, width);
    const valCProps = generateProps(valC);

    return (
        <View style={{ width: 531, height: 121 }}>
            <Svg style={{ width: 531, height: 121 }} viewBox="0 0 531 121">
                <G>
                    <G transform="translate(64.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.08203125" y="13">
                                0
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(110.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.829101562" y="13">
                                1
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(156.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.200683594" y="13">
                                2
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(201.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.0952148438" y="13">
                                3
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(247.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.0205078125" y="13">
                                4
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(293.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.134765625" y="13">
                                5
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(339.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.0512695312" y="13">
                                6
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(385.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.354492188" y="13">
                                7
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(430.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.0424804688" y="13">
                                8
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(476.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.0512695312" y="13">
                                9
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(522.000000, 100.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-2.08886719" y="13">
                                10
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                </G>
                <G>
                    <Rect
                        fill="#E8E8E8"
                        x="67"
                        y="0"
                        width="137"
                        height="100"
                    ></Rect>
                    <Line
                        x1="66.5"
                        y1="0.5"
                        x2="66.5"
                        y2="103.5"
                        stroke="#666666"
                    ></Line>
                    <Rect
                        fill="#E6F6FF"
                        x="204"
                        y="0"
                        width="183"
                        height="100"
                    ></Rect>
                    <Line
                        x1="203.5"
                        y1="1.5"
                        x2="203.5"
                        y2="103.5"
                        stroke="#666666"
                        strokeLineCap="square"
                        strokeDasharray="4"
                    ></Line>
                    <Rect
                        fill="#FFE2D6"
                        x="388"
                        y="0"
                        width="137"
                        height="100"
                    ></Rect>
                    <Line
                        x1="388"
                        y1="0.5"
                        x2="388"
                        y2="102.5"
                        stroke="#666666"
                        strokeLineCap="square"
                        strokeDasharray="4"
                    ></Line>
                    <Line
                        x1="66.5"
                        y1="100"
                        x2="525"
                        y2="100"
                        stroke="#666666"
                    ></Line>
                    <Text style={cStyles.levelText} fillOpacity="0.6">
                        <Tspan x="130" y="11">
                            低
                        </Tspan>
                    </Text>
                    <Text style={cStyles.levelText} fillOpacity="0.6">
                        <Tspan x="291" y="11">
                            中
                        </Tspan>
                    </Text>
                    <Text style={cStyles.levelText} fillOpacity="0.6">
                        <Tspan x="451" y="11">
                            高
                        </Tspan>
                    </Text>
                </G>
                <G transform="translate(0.000000, 18.000000)">
                    <Text style={cStyles.title} fillOpacity="0.87">
                        <Tspan x="10" y="11">
                            管理潜质
                        </Tspan>
                    </Text>
                    <Path
                        d={rectPathD(67, 0, valA, 16)}
                        fill={colors.themeColor}
                    ></Path>
                    <Text
                        style={cStyles.value}
                        fill={valAProps.fill}
                        textAnchor={valAProps.textAnchor as any}
                    >
                        <Tspan x={valAProps.x} y="11">
                            {development_potential?.[0]}
                        </Tspan>
                    </Text>
                </G>
                <G transform="translate(0.000000, 44.000000)">
                    <Text style={cStyles.title} fillOpacity="0.87">
                        <Tspan x="30" y="11">
                            情商
                        </Tspan>
                    </Text>
                    <Path
                        d={rectPathD(67, 0, valB, 16)}
                        fill={colors.themeColor}
                    ></Path>
                    <Text
                        style={cStyles.value}
                        fill={valBProps.fill}
                        textAnchor={valBProps.textAnchor as any}
                    >
                        <Tspan x={valBProps.x} y="11">
                            {development_potential?.[1]}
                        </Tspan>
                    </Text>
                </G>
                <G transform="translate(0.000000, 70.000000)">
                    <Text style={cStyles.title} fillOpacity="0.87">
                        <Tspan x="0" y="11">
                            社会成熟度
                        </Tspan>
                    </Text>
                    <Path
                        d={rectPathD(67, 0, valC, 16)}
                        fill={colors.themeColor}
                    ></Path>
                    <Text
                        style={cStyles.value}
                        fill={valCProps.fill}
                        textAnchor={valCProps.textAnchor as any}
                    >
                        <Tspan x={valCProps.x} y="11">
                            {development_potential?.[2]}
                        </Tspan>
                    </Text>
                </G>
            </Svg>
        </View>
    );
}
export default ChartDevelopmentPotential;
