import { Form, Icon, Modal, Select, message } from 'ysd-pp';
import styles from '../AppraisalsList/LinkModal/index.module.less';
import { useEffect, useState } from 'react';
import { useRequest } from '@/utils/request';
import { surveyTypeList } from './enums';
import { AddJobRulesParams, apiAddJobRules, apiGetStationList, apiUpdateJobRules } from '../../services';
import { returnArray } from '@/utils';

export interface IAddEditModalProps {
    /**
     * 状态：抽屉是否展示
     */
    visible: boolean;
    /**
     * 设置抽屉关闭并清空数据的方法
     */
    onCancel: () => void;
    /**
     * 当前正在编辑的角色，从角色列表中携带过来
     */
    record?: any;
    refreshList?: () => void;
    setCurrentRecord?: (record) => void;
}

function AddEditModal(props: IAddEditModalProps) {
    const { visible, onCancel, record, refreshList } = props;
    const [form] = Form.useForm();
    const isEdit = !!record?.id;

    const [stationOptionList, setStationOptionList] = useState<
        {
            label?: string;
            value?: number;
        }[]
    >([]); // 获取岗位数据过滤掉岗位列表已选的岗位

    const { runAsync: runAdd } = useRequest(apiAddJobRules);
    const { runAsync: runUpdate } = useRequest(apiUpdateJobRules);
    const { runAsync: runGetStationList } = useRequest(apiGetStationList);

    // 获取岗位数据

    /** 初始化挂载的时候执行 */
    useEffect(() => {
        if (isEdit && visible) {
            form?.setFieldsValue({
                stationList: record?.station,
                surveyType: record?.survey_type,
            });
        }
        if (visible) {
            runGetStationList().then(res => {
                const data = returnArray(res?.data)?.map(item => {
                    return {
                        label: item?.station,
                        value: item?.station_id,
                    };
                });
                setStationOptionList(data);
            });
        }
    }, [visible]);

    /** 处理确认事件 */
    const handleOnOk = async () => {
        try {
            const data = await form.validateFields();
            let res;
            if (isEdit) {
                const params: AddJobRulesParams = {
                    survey_type: data?.surveyType,
                    id: record?.id,
                };
                res = await runUpdate(params);
            } else {
                const params: AddJobRulesParams = {
                    station_list: data?.stationList?.map(item => ({ station_id: item?.value, station: item?.label })),
                    survey_type: data?.surveyType,
                };
                res = await runAdd(params);
            }

            if (res?.code === 200) {
                onCancel();
                refreshList?.();
                message.success(isEdit ? '修改成功' : '创建成功');
            } else {
                message.error(res?.message);
            }
        } catch (e) {
            // do nothing
        }
    };

    return (
        <Modal
            className={styles['link-modal']}
            visible={visible}
            title="岗位测评设置"
            onOk={handleOnOk}
            onCancel={() => onCancel()}
            okText="保存"
            cancelText="取消"
            destroyOnClose
        >
            <div className={styles['top-tip']}>
                <Icon type="info-f" className={styles['icon']} />
                <div>社招自动发送测评根据岗位优先匹配职场适应性测评，岗位未匹配到时默认发送16PF测评。</div>
            </div>
            <Form form={form} preserve={false} layout="vertical">
                <Form.Item label="岗位" name={'stationList'} rules={[{ required: true }]} style={{ display: 'block' }}>
                    <Select
                        options={stationOptionList as any}
                        placeholder="请选择岗位"
                        virtual={true}
                        disabled={isEdit}
                        optionFilterProp="sourceLabel"
                        {...(isEdit ? {} : { mode: 'multiple', labelInValue: true })}
                    />
                </Form.Item>
                <Form.Item label="测评工具" name="surveyType" rules={[{ required: true }]}>
                    <Select
                        options={surveyTypeList}
                        placeholder="请选择测评工具"
                        virtual={true}
                        disabled={record?.user_id === '13ac9112-bc4c-40f5-a4e4-3183b3d1424a'}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AddEditModal;
