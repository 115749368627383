import { Document, Font, Page, Text } from '@react-pdf/renderer';

import { getToken } from '@/utils/getToken';
import { useEffect, useState } from 'react';
import fontAlibabaPuHuiTiBold from './assets/fonts/Alibaba-PuHuiTi-Bold.ttf';
import fontAlibabaPuHuiTiMedium from './assets/fonts/Alibaba-PuHuiTi-Medium.ttf';
import fontDingTalkJinBuTi from './assets/fonts/DingTalk JinBuTi.ttf';
import fontPingFangSC400 from './assets/fonts/PingFangSC-Regular.ttf';
import fontPingFangSC500 from './assets/fonts/PingFangSC-Semibold.ttf';
import Introduction from './components/Introduction';
import PageMs from './components/PageMs';
import PageYs from './components/PageYs';
import Reporter1 from './components/Reporter1';
import Reporter2 from './components/Reporter2';
import Reporter3 from './components/Reporter3';
import Reporter4 from './components/Reporter4';
import Reporter5 from './components/Reporter5';
import Reporter6 from './components/Reporter6';
import Reporter7 from './components/Reporter7';
import Reporter8 from './components/Reporter8';
import ReporterContents from './components/ReporterContents';
import TestValidityCheck from './components/TestValidityCheck';
import { PdfData, fetchPdfData } from './services';
import { Context, TpDFType, useStyles } from './utils/styles';

Font.register({
    family: 'DingTalk JinBuTi',
    src: fontDingTalkJinBuTi,
});
Font.register({
    family: 'Alibaba PuHuiTi',
    src: fontAlibabaPuHuiTiBold,
});
Font.register({
    family: 'Alibaba PuHuiTi Medium',
    src: fontAlibabaPuHuiTiMedium,
});
Font.register({
    family: 'PingFangSC400',
    src: fontPingFangSC400,
});
Font.register({
    family: 'PingFangSC500',
    src: fontPingFangSC500,
});

function PdfReporter(
    { type, data: outerData }: { type: TpDFType; data?: PdfData } = {
        type: 'ys',
    },
) {
    const { styles } = useStyles(type);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<PdfData>(outerData ?? {});

    useEffect(() => {
        if (typeof outerData === 'undefined') {
            const token = getToken();
            if (!token) {
                return;
            }

            fetchPdfData({
                token,
                entrance_ids: ['O6GM41NRVS'],
            })
                .then(res => {
                    if (res.status === 200 && res?.data?.code === 200) {
                        setData(res?.data?.data?.[0]);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [outerData]);

    return (
        <Document title={`卡特尔 16PF 测评个人报告-${data?.user_info?.name}`}>
            {typeof outerData === 'undefined' && loading ? null : (
                <Context.Provider
                    value={{
                        type,
                        data,
                    }}
                >
                    {type === 'ms' ? <PageMs /> : <PageYs />}
                    <Page style={{ ...styles.body, ...styles.content }}>
                        <Introduction />
                        <TestValidityCheck />
                        <ReporterContents />
                        <Reporter1 />
                        <Reporter2 />
                        <Reporter3 />
                        <Reporter4 />
                        <Reporter5 />
                        <Reporter6 />
                        <Reporter7 />
                        <Reporter8 />

                        <Text
                            style={{
                                position: 'absolute',
                                fontSize: 12,
                                bottom: 8,
                                left: 0,
                                right: 0,
                                textAlign: 'center',
                                color: '#333333',
                                paddingBottom: 4,
                                letterSpacing: 0,
                                fontFamily: type === 'ys' ? 'DingTalk JinBuTi' : 'PingFangSC400',
                                ...(type === 'ys'
                                    ? {}
                                    : {
                                          fontSize: 10,
                                          left: '50%',
                                          bottom: 12,
                                          lineHeight: 1,
                                          width: 20,
                                          height: 20,
                                          paddingTop: 5,
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          backgroundColor: '#4696F5',
                                          color: '#FFFFFF',
                                          borderRadius: 18,
                                          marginLeft: 10,
                                      }),
                            }}
                            render={({ pageNumber }) => `${pageNumber < 10 ? '0' : ''}${pageNumber}`}
                            fixed
                        />
                    </Page>
                </Context.Provider>
            )}
        </Document>
    );
}

export default PdfReporter;
