import { saveToken } from '@/utils/getToken';
import request from '@/utils/request';

const MOCK_PREFIX = '/oa_web_survey';

/** 获取当前的用户 GET /user/api/user/v1/login_info */
export async function getUserInfo() {
    return request<TUserApiUserV1LoginInfoGetRes>(`${MOCK_PREFIX}/user/current`, {
        method: 'GET',
    });
}

/**
 * 登录成功，获取用户基本信息
 * response body类型
 * https://app.apifox.com/link/project/3297894/apis/api-112631734
 */
export type TUserApiUserV1LoginInfoGetRes = {
    /** 权限列表*/
    authority_list?: string[];
    /** 北森申请人ID */
    bei_sen_applicant_id?: string;
    /** 生日*/
    birthday?: string;
    /**公司*/
    company?: number;
    /** 性别*/
    gender?: string;
    /** 身份证号*/
    id_card?: string;
    /** 姓名*/
    name?: string;
    /** 手机号*/
    phone?: string;
    /** 岗位*/
    position?: string;
    /**学校*/
    school?: string;
    /**用户ID*/
    user_id?: string;
};

/** 退出登录接口 POST /auth/oauth/logout */
export async function logout() {
    return request(`${MOCK_PREFIX}/account/logout`, {
        method: 'POST',
    });
}

/** 认证（登录）接口 POST /auth/oauth/token */
export interface ILoginParams {
    username: string; // 账户
    password: string; // 密码
    captcha?: string; // 验证码
}
export async function login(body: ILoginParams) {
    return request(`${MOCK_PREFIX}/public/login`, {
        method: 'POST',
        // headers: {
        //   "Content-Type": "application/x-www-form-urlencoded",
        // },
        data: {
            // client_id: "ppv3",
            // client_secret: "Yeastar123",
            // grant_type: "password",
            ...body,
        },
    }).then((res: any) => {
        if (res && res?.code === 200) {
            if (!res?.data?.token) {
                return res; // 接口数据有错，Token 不存在！
            }

            const { data } = res;
            saveToken(data?.token);
        }

        return res;
    });
}

/**
 * 获取验证码接口
 * @param params
 * @returns
 */
export async function fetchCaptcha() {
    return request(`${MOCK_PREFIX}/public/captcha`, {
        method: 'GET',
    });
}
export interface ISetPasswordParams extends Pick<ILoginParams, 'username' | 'password'> {
    new_password: string;
    confirm_new_password: string;
}
/**
 * 登录后修改密码
 * @param params
 * @returns
 */
export type TChangePasswordParams = Pick<ISetPasswordParams, 'new_password' | 'confirm_new_password'> & {
    old_password: ISetPasswordParams['password'];
};
export async function changePassword(params: TChangePasswordParams) {
    return request<string>(`${MOCK_PREFIX}/account/password`, {
        method: 'PUT',
        data: {
            old_password: params.old_password,
            new_password: params.new_password,
            confirm_new_password: params.confirm_new_password,
        },
    });
}

/**
 * 注册账号/更新账号
 * request body类型
 * https://app.apifox.com/link/project/3297894/apis/api-112631734
 */
export type TPublicRegisterReq = {
    /** 角色id*/
    role_ids?: string;
    /** 用户名*/
    username?: string;
    /** 密码*/
    password?: string;
    /** 用户id*/
    account_id?: string;
};
/**
 * 注册账号
 * @param params
 * @returns
 */
export function registerUser(data: TPublicRegisterReq) {
    return request(`${MOCK_PREFIX}/register`, {
        method: 'POST',
        data,
    });
}

/**
 * 更新账号
 * @param params
 * @returns
 */
export function updateUser(data: TPublicRegisterReq) {
    return request(`${MOCK_PREFIX}/account`, {
        method: 'PUT',
        data,
    });
}

/**
 * 删除账号
 * request body类型
 * https://app.apifox.com/link/project/3297894/apis/api-112631734
 */
export type TDeleteUserReq = {
    /** 用户id*/
    user_id?: string;
};
/**
 * 删除账号
 * @param params
 * @returns
 */
export function apiDeleteUser(data: TDeleteUserReq) {
    return request(`${MOCK_PREFIX}/account/${data.user_id}`, {
        method: 'DELETE',
        data,
    });
}
