import axios from 'axios';
import { REQUEST_BASE_URL } from '@/utils/constants';
import { IFetchPdfDataQueryParams } from '.';
import { stringify } from 'qs';

const API_PREFIX = '/oa_web_survey';

export interface PdfDataForHigh {
    /**
     * 报告创建时间，单位:ms<br>
     */
    create_at?: number | null;
    /**
     * 入口ID
     */
    entrance_id?: null | string;
    /**
     * 心理健康风险
     */
    mental_health_risk?: WaMentalHealthRiskDTO;
    /**
     * 整体报告可参考性
     */
    overall_reporting_reference?: OverallReportingReferenceDTO;
    /**
     * 职业相关人格因素
     */
    personality_factor?: WaPersonalityFactorDTO;
    /**
     * 职业适应性风险信息
     */
    risk_info?: WaRiskInfoDTO;
    /**
     * 评测类型：0-16PF，1-MBTI，2-高危测试<br>
     * {@link SurveyEnum}
     */
    survey_type?: string | null;
    /**
     * 用户信息
     */
    user_info?: WaUserInfoDataDTO;
}

/**
 * 心理健康风险
 *
 * WaMentalHealthRiskDTO
 */
export interface WaMentalHealthRiskDTO {
    /**
     * 焦虑
     */
    anxiety?: null | string;
    /**
     * 妄想幻觉
     */
    delusion_hallucination?: null | string;
    /**
     * 抑郁
     */
    depression?: null | string;
    /**
     * 敌对
     */
    hostility?: null | string;
    /**
     * 人际关系敏感
     */
    interpersonal_relationship_sensitivity?: null | string;
    /**
     * 强迫症状
     */
    obsessive_symptoms?: null | string;
    /**
     * 偏执
     */
    paranoia?: null | string;
    /**
     * 恐怖症
     */
    phobia?: null | string;
    /**
     * 心理健康风险描述
     */
    risk_description?: null | string;
    /**
     * 躯体化
     */
    somatization?: null | string;
}

/**
 * 整体报告可参考性
 *
 * OverallReportingReferenceDTO
 */
export interface OverallReportingReferenceDTO {
    /**
     * 作答时长
     */
    answer_duration?: null | string;
    /**
     * 作答时长描述
     */
    answer_duration_description?: null | string;
    /**
     * 作答规律性
     */
    answer_regular?: null | string;
    /**
     * 作答规律性描述
     */
    answer_regular_description?: null | string;
    /**
     * 参考性等级  low-低，normal-正常
     */
    reference_level?: null | string;
    /**
     * 社会许可性
     */
    social_desirability?: null | string;
    /**
     * 社会许可性描述
     */
    social_desirability_description?: null | string;
}

/**
 * 职业相关人格因素
 *
 * WaPersonalityFactorDTO
 */
export interface WaPersonalityFactorDTO {
    /**
     * 优势
     */
    advantage?: null | string;
    /**
     * 团建合作
     */
    cooperation?: null | string;
    /**
     * 情绪稳定
     */
    emotional_stability?: null | string;
    /**
     * 外向性
     */
    extroversion?: null | string;
    /**
     * 开括创新
     */
    innovation?: null | string;
    /**
     * 抗压性
     */
    pressure_resistance?: null | string;
    /**
     * 责任心
     */
    responsibility?: null | string;
    /**
     * 风险
     */
    risk?: null | string;
}

/**
 * 职业适应性风险信息
 *
 * WaRiskInfoDTO
 */
export interface WaRiskInfoDTO {
    /**
     * 职业适应性风险描述
     */
    risk_description?: null | string;
    /**
     * 职业适应性风险等级  low-低，lower-较低, middle-中，higher-较高,high-高
     */
    risk_level?: null | string;
}

/**
 * 用户信息
 *
 * WaUserInfoDataDTO
 */
export interface WaUserInfoDataDTO {
    /**
     * 出生日期
     */
    birthday?: null | string;
    /**
     * 毕业大学
     */
    college?: null | string;
    /**
     * 学历
     */
    education?: null | string;
    /**
     * 姓名
     */
    name?: null | string;
    /**
     * 应聘岗位
     */
    position?: null | string;
    /**
     * 性别
     */
    sex?: null | string;
}

export interface IHighReportResponse {
    code: number;
    data: PdfDataForHigh[];
    message: string;
    [property: string]: any;
}

export function fetchPdfDataForHigh(params: IFetchPdfDataQueryParams) {
    return axios.get<IHighReportResponse>(`${API_PREFIX}/report/workplace_adaptability`, {
        baseURL: REQUEST_BASE_URL,
        params: {
            entrance_ids: params.entrance_ids,
        },
        paramsSerializer: search => stringify(search, { arrayFormat: 'repeat' }),
        headers: {
            Authorization: !params?.token ? void 0 : `Bearer ${params.token}`,
        },
    });
}
