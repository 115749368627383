import { Svg, Circle } from '@react-pdf/renderer';
import { useContext } from 'react';
import { Context } from '../utils/common';
import { useStyles } from '@/components/PdfReporter/utils/styles';

const Ring = () => {
    const { type } = useContext(Context);
    const { colors } = useStyles(type);

    return (
        <Svg width="16" height="16" viewBox="0 0 16 16">
            <Circle cx="8" cy="8" r="8" fill={colors.themeColor}></Circle>
            <Circle cx="8" cy="8" r="4" fill="#fff"></Circle>
        </Svg>
    );
};

export default Ring;
