import { View } from '@react-pdf/renderer';
import IconPoint from '@components/MbtiPdfReporterA4/components/IconPoint';
import { fontSize } from '@components/PdfReporter/utils/fontSize';
import { usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

function Section(props: { head: any; children: any; style?: any; break?: boolean }) {
    const pdfType = usePdfType();
    const { head, children, style, break: pageBreak } = props;

    return (
        <View style={{ ...style }} break={pageBreak}>
            <View style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
                <IconPoint
                    style={{
                        marginLeft: 2,
                        marginTop: 4,
                        marginRight: 13,
                        ...(pdfType === 'ms'
                            ? {
                                  marginTop: 3,
                              }
                            : {}),
                    }}
                />
                <View style={{ fontSize: 17.01 }}>{head}</View>
            </View>
            <View style={{ ...fontSize(12.85, 22.68), marginTop: 13, paddingLeft: 4 }}>{children}</View>
        </View>
    );
}

export default Section;
