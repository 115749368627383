import { View, Text } from '@react-pdf/renderer';

import IntroductionOneRow from '@components/MbtiPdfReporterMobile/components/IntroductionOneRow';
import Avatar from '@components/MbtiPdfReporterMobile/components/Avatar';
import { usePdfData, usePdfTheme, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

function Introduction() {
    const pdfType = usePdfType();
    const theme = usePdfTheme();
    const { userInfo: data } = usePdfData();

    return (
        <>
            <View style={{ marginTop: 24 }}>
                <IntroductionOneRow label="姓　　名" value={data?.name} />
                <IntroductionOneRow
                    label="MBTI类型"
                    value={data?.mbtiType}
                    style={{
                        marginTop: 7,
                    }}
                    labelStyle={{
                        marginTop: 6,
                    }}
                    valueStyle={{
                        fontSize: 30,
                        lineHeight: 1,
                        marginTop: 0,
                    }}
                />
                <IntroductionOneRow
                    label="性格特点"
                    value={data?.features}
                    style={{
                        marginTop: 13,
                    }}
                    valueStyle={{
                        width: 100,
                    }}
                />
                <IntroductionOneRow
                    label="类型代表"
                    value={data?.representation}
                    style={{
                        marginTop: 9,
                    }}
                />
            </View>

            {pdfType === 'ms' ? (
                <View
                    style={{
                        // width: 144,
                        // height: 60,
                        fontFamily: 'Alibaba PuHuiTi',
                        position: 'absolute',
                        top: 185,
                        right: 13,
                        fontSize: 60,
                        letterSpacing: -1,
                        lineHeight: 1,
                        color: theme?.main ?? 'white',
                        opacity: 0.45,
                    }}
                >
                    <Text>MB TI</Text>
                </View>
            ) : null}

            <View
                style={{
                    width: 228,
                    height: 51,
                    position: 'absolute',
                    top: 60,
                    right: 0,
                    ...(pdfType === 'ms' ? { right: 8 } : {}),
                }}
            >
                <Avatar.Name name={data?.mbtiType} />
            </View>
            <View
                style={
                    pdfType === 'ys'
                        ? { width: 141, height: 182, position: 'absolute', top: 72, right: 18 }
                        : { width: 139, height: 188, position: 'absolute', top: 75, right: 18 }
                }
            >
                <Avatar name={data?.mbtiType} />
            </View>
        </>
    );
}

export default Introduction;
