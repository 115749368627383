import { Circle, Svg } from "@react-pdf/renderer";

function Point(props: { style?: Record<string, any> }) {
    const { style } = props;
    return (
        <Svg width="5" height="5" viewBox="0 0 5 5" style={style}>
            <Circle
                cx="2.5"
                cy="2.5"
                r="2.5"
                fill="#000000"
                fillOpacity="0.87"
            ></Circle>
        </Svg>
    );
}

export default Point;
