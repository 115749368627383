import { View, Image } from '@react-pdf/renderer';
import { usePdfTheme } from '@components/MbtiPdfReporterMobile/utils/styles';

function PageBackground() {
    const theme = usePdfTheme();

    return (
        <View
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                backgroundColor: theme.bg,
            }}
            fixed
        >
            <Image src={theme?.bgFull} style={{ height: '100%' }} />
        </View>
    );
}

export default PageBackground;
