import React, { ReactNode } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import Ring from './Ring';

interface ModuleHeaderProps {
    titleText: string; // 每个模块的标题文字
    extraCom?: ReactNode; // 额外的组件
}
/**
 * @description 每个模块的标题
 * @param props
 */
const ModuleHeader: React.FC<ModuleHeaderProps> = props => {
    const { titleText, extraCom } = props;
    const styles = StyleSheet.create({
        flex: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 20,
            marginTop: 26,
            width: '100%',
        },
    });
    return (
        <view style={styles.flex}>
            <View style={{ marginRight: 6 }}>
                <Ring />
            </View>
            <View style={{ paddingTop: 4 }}>
                <Text style={{ fontSize: 16 }}>{titleText}</Text>
            </View>
            <View>{extraCom}</View>
        </view>
    );
};

export default ModuleHeader;
