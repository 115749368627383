import { View } from "@react-pdf/renderer";
import { useStyles } from "../utils/styles";
import TableOccupationalFactorPoints from "./TableOccupationalFactorPoints";
import TextWithBlank from "./TextWithBlank";

/**
 * 7、职业相关人格因素
 */
function Reporter7() {
    const { commonStyles } = useStyles();

    return (
        <View style={{ height: "auto" }} break>
            <TextWithBlank
                style={commonStyles.heading}
            >{`7、职业相关人格因素`}</TextWithBlank>
            <View style={{ marginTop: 8 }}>
                <TableOccupationalFactorPoints />
            </View>
        </View>
    );
}

export default Reporter7;
