import bgBlue1 from '@components/MbtiPdfReporterMobile/assets/ys/bg_blue_1@2x.png';
import bgBlue2 from '@components/MbtiPdfReporterMobile/assets/ys/bg_blue_2@2x.png';
import bgPurple1 from '@components/MbtiPdfReporterMobile/assets/ys/bg_purple_1@2x.png';
import bgPurple2 from '@components/MbtiPdfReporterMobile/assets/ys/bg_purple_2@2x.png';
import bgYellow1 from '@components/MbtiPdfReporterMobile/assets/ys/bg_yellow_1@2x.png';
import bgYellow2 from '@components/MbtiPdfReporterMobile/assets/ys/bg_yellow_2@2x.png';
import bgGreen1 from '@components/MbtiPdfReporterMobile/assets/ys/bg_green_1@2x.png';
import bgGreen2 from '@components/MbtiPdfReporterMobile/assets/ys/bg_green_2@2x.png';

import bgBlue from '@components/MbtiPdfReporterA4/assets/ys/bg_blue.jpg';
import bgPurple from '@components/MbtiPdfReporterA4/assets/ys/bg_purple.jpg';
import bgYellow from '@components/MbtiPdfReporterA4/assets/ys/bg_yellow.jpg';
import bgGreen from '@components/MbtiPdfReporterA4/assets/ys/bg_green.jpg';

import bgBlue1ms from '@components/MbtiPdfReporterMobile/assets/ms/bg_blue_1@2x.png';
import bgBlue2ms from '@components/MbtiPdfReporterMobile/assets/ms/bg_blue_2@2x.png';
import bgPurple1ms from '@components/MbtiPdfReporterMobile/assets/ms/bg_purple_1@2x.png';
import bgPurple2ms from '@components/MbtiPdfReporterMobile/assets/ms/bg_purple_2@2x.png';
import bgYellow1ms from '@components/MbtiPdfReporterMobile/assets/ms/bg_yellow_1@2x.png';
import bgYellow2ms from '@components/MbtiPdfReporterMobile/assets/ms/bg_yellow_2@2x.png';
import bgGreen1ms from '@components/MbtiPdfReporterMobile/assets/ms/bg_green_1@2x.png';
import bgGreen2ms from '@components/MbtiPdfReporterMobile/assets/ms/bg_green_2@2x.png';

import iconPointBlue from '@components/MbtiPdfReporterMobile/assets/ys/IconPoint_blue.png';
import iconPointPurple from '@components/MbtiPdfReporterMobile/assets/ys/IconPoint_purple.png';
import iconPointYellow from '@components/MbtiPdfReporterMobile/assets/ys/IconPoint_yellow.png';
import iconPointGreen from '@components/MbtiPdfReporterMobile/assets/ys/IconPoint_green.png';

import avatarESFJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ESFJ.png';
import avatarESFP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ESFP.png';
import avatarINTJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/INTJ.png';
import avatarISFJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ISFJ.png';
import avatarESTP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ESTP.png';
import avatarESTJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ESTJ.png';
import avatarISTP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ISTP.png';
import avatarENFJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ENFJ.png';
import avatarINFP from '@components/MbtiPdfReporterMobile/assets/ys/personality/INFP.png';
import avatarISTJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ISTJ.png';
import avatarINTP from '@components/MbtiPdfReporterMobile/assets/ys/personality/INTP.png';
import avatarINFJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/INFJ.png';
import avatarENTJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ENTJ.png';
import avatarISFP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ISFP.png';
import avatarENTP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ENTP.png';
import avatarENFP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ENFP.png';

import cnNameESFJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ESFJ_Name.png';
import cnNameESFP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ESFP_Name.png';
import cnNameINTJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/INTJ_Name.png';
import cnNameISFJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ISFJ_Name.png';
import cnNameESTP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ESTP_Name.png';
import cnNameESTJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ESTJ_Name.png';
import cnNameISTP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ISTP_Name.png';
import cnNameENFJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ENFJ_Name.png';
import cnNameINFP from '@components/MbtiPdfReporterMobile/assets/ys/personality/INFP_Name.png';
import cnNameISTJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ISTJ_Name.png';
import cnNameINTP from '@components/MbtiPdfReporterMobile/assets/ys/personality/INTP_Name.png';
import cnNameINFJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/INFJ_Name.png';
import cnNameENTJ from '@components/MbtiPdfReporterMobile/assets/ys/personality/ENTJ_Name.png';
import cnNameISFP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ISFP_Name.png';
import cnNameENTP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ENTP_Name.png';
import cnNameENFP from '@components/MbtiPdfReporterMobile/assets/ys/personality/ENFP_Name.png';

import avatarESFJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ESFJ.png';
import avatarESFPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ESFP.png';
import avatarINTJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/INTJ.png';
import avatarISFJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ISFJ.png';
import avatarESTPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ESTP.png';
import avatarESTJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ESTJ.png';
import avatarISTPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ISTP.png';
import avatarENFJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ENFJ.png';
import avatarINFPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/INFP.png';
import avatarISTJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ISTJ.png';
import avatarINTPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/INTP.png';
import avatarINFJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/INFJ.png';
import avatarENTJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ENTJ.png';
import avatarISFPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ISFP.png';
import avatarENTPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ENTP.png';
import avatarENFPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ENFP.png';

import cnNameESFJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ESFJ_Name.png';
import cnNameESFPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ESFP_Name.png';
import cnNameINTJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/INTJ_Name.png';
import cnNameISFJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ISFJ_Name.png';
import cnNameESTPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ESTP_Name.png';
import cnNameESTJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ESTJ_Name.png';
import cnNameISTPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ISTP_Name.png';
import cnNameENFJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ENFJ_Name.png';
import cnNameINFPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/INFP_Name.png';
import cnNameISTJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ISTJ_Name.png';
import cnNameINTPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/INTP_Name.png';
import cnNameINFJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/INFJ_Name.png';
import cnNameENTJms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ENTJ_Name.png';
import cnNameISFPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ISFP_Name.png';
import cnNameENTPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ENTP_Name.png';
import cnNameENFPms from '@components/MbtiPdfReporterMobile/assets/ms/personality/ENFP_Name.png';

/** 以下这一套人格中文名是白色的，用于非首页使用 */
import cnNameESFJms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ESFJ_Name.png';
import cnNameESFPms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ESFP_Name.png';
import cnNameINTJms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/INTJ_Name.png';
import cnNameISFJms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ISFJ_Name.png';
import cnNameESTPms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ESTP_Name.png';
import cnNameESTJms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ESTJ_Name.png';
import cnNameISTPms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ISTP_Name.png';
import cnNameENFJms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ENFJ_Name.png';
import cnNameINFPms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/INFP_Name.png';
import cnNameISTJms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ISTJ_Name.png';
import cnNameINTPms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/INTP_Name.png';
import cnNameINFJms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/INFJ_Name.png';
import cnNameENTJms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ENTJ_Name.png';
import cnNameISFPms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ISFP_Name.png';
import cnNameENTPms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ENTP_Name.png';
import cnNameENFPms2 from '@components/MbtiPdfReporterA4/assets/ms/personality/ENFP_Name.png';

/** A4 YS 的人格中文 */
import cnNameESFJa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ESFJ_Name.png';
import cnNameESFPa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ESFP_Name.png';
import cnNameINTJa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/INTJ_Name.png';
import cnNameISFJa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ISFJ_Name.png';
import cnNameESTPa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ESTP_Name.png';
import cnNameESTJa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ESTJ_Name.png';
import cnNameISTPa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ISTP_Name.png';
import cnNameENFJa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ENFJ_Name.png';
import cnNameINFPa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/INFP_Name.png';
import cnNameISTJa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ISTJ_Name.png';
import cnNameINTPa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/INTP_Name.png';
import cnNameINFJa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/INFJ_Name.png';
import cnNameENTJa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ENTJ_Name.png';
import cnNameISFPa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ISFP_Name.png';
import cnNameENTPa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ENTP_Name.png';
import cnNameENFPa4 from '@components/MbtiPdfReporterA4/assets/ys/personality/ENFP_Name.png';

/**
 * 16 种人格
 */
export enum EPersonality {
    /** 东道主 */
    'ESFJ' = 'ESFJ',
    /** 乐天派 */
    'ESFP' = 'ESFP',
    /** 军师 */
    'INTJ' = 'INTJ',
    /** 守护者 */
    'ISFJ' = 'ISFJ',
    /** 实践者 */
    'ESTP' = 'ESTP',
    /** 总经理 */
    'ESTJ' = 'ESTJ',
    /** 探险家 */
    'ISTP' = 'ISTP',
    /** 教育家 */
    'ENFJ' = 'ENFJ',
    /** 治愈者 */
    'INFP' = 'INFP',
    /** 物流师 */
    'ISTJ' = 'ISTJ',
    /** 科学家 */
    'INTP' = 'INTP',
    /** 精神导师 */
    'INFJ' = 'INFJ',
    /** 统帅 */
    'ENTJ' = 'ENTJ',
    /** 艺术家 */
    'ISFP' = 'ISFP',
    /** 辩论家 */
    'ENTP' = 'ENTP',
    /** 追梦人 **/
    'ENFP' = 'ENFP',
}

/**
 * 16 种人格对应的卡通头像
 */
export const AVATAR_YS = {
    [EPersonality.ESFJ]: avatarESFJ,
    [EPersonality.ESFP]: avatarESFP,
    [EPersonality.INTJ]: avatarINTJ,
    [EPersonality.ISFJ]: avatarISFJ,
    [EPersonality.ESTP]: avatarESTP,
    [EPersonality.ESTJ]: avatarESTJ,
    [EPersonality.ISTP]: avatarISTP,
    [EPersonality.ENFJ]: avatarENFJ,
    [EPersonality.INFP]: avatarINFP,
    [EPersonality.ISTJ]: avatarISTJ,
    [EPersonality.INTP]: avatarINTP,
    [EPersonality.INFJ]: avatarINFJ,
    [EPersonality.ENTJ]: avatarENTJ,
    [EPersonality.ISFP]: avatarISFP,
    [EPersonality.ENTP]: avatarENTP,
    [EPersonality.ENFP]: avatarENFP,
};

/**
 * 16 种人格对应的中文名称 for Yeastar
 */
export const CN_NAME_YS = {
    [EPersonality.ESFJ]: cnNameESFJ,
    [EPersonality.ESFP]: cnNameESFP,
    [EPersonality.INTJ]: cnNameINTJ,
    [EPersonality.ISFJ]: cnNameISFJ,
    [EPersonality.ESTP]: cnNameESTP,
    [EPersonality.ESTJ]: cnNameESTJ,
    [EPersonality.ISTP]: cnNameISTP,
    [EPersonality.ENFJ]: cnNameENFJ,
    [EPersonality.INFP]: cnNameINFP,
    [EPersonality.ISTJ]: cnNameISTJ,
    [EPersonality.INTP]: cnNameINTP,
    [EPersonality.INFJ]: cnNameINFJ,
    [EPersonality.ENTJ]: cnNameENTJ,
    [EPersonality.ISFP]: cnNameISFP,
    [EPersonality.ENTP]: cnNameENTP,
    [EPersonality.ENFP]: cnNameENFP,
};

/**
 * 16 种人格对应的卡通头像 for Milesight
 */
export const AVATAR_MS = {
    [EPersonality.ESFJ]: avatarESFJms,
    [EPersonality.ESFP]: avatarESFPms,
    [EPersonality.INTJ]: avatarINTJms,
    [EPersonality.ISFJ]: avatarISFJms,
    [EPersonality.ESTP]: avatarESTPms,
    [EPersonality.ESTJ]: avatarESTJms,
    [EPersonality.ISTP]: avatarISTPms,
    [EPersonality.ENFJ]: avatarENFJms,
    [EPersonality.INFP]: avatarINFPms,
    [EPersonality.ISTJ]: avatarISTJms,
    [EPersonality.INTP]: avatarINTPms,
    [EPersonality.INFJ]: avatarINFJms,
    [EPersonality.ENTJ]: avatarENTJms,
    [EPersonality.ISFP]: avatarISFPms,
    [EPersonality.ENTP]: avatarENTPms,
    [EPersonality.ENFP]: avatarENFPms,
};

/**
 * 16 种人格对应的中文名 for MileSight
 */
export const CN_NAME_MS = {
    [EPersonality.ESFJ]: cnNameESFJms,
    [EPersonality.ESFP]: cnNameESFPms,
    [EPersonality.INTJ]: cnNameINTJms,
    [EPersonality.ISFJ]: cnNameISFJms,
    [EPersonality.ESTP]: cnNameESTPms,
    [EPersonality.ESTJ]: cnNameESTJms,
    [EPersonality.ISTP]: cnNameISTPms,
    [EPersonality.ENFJ]: cnNameENFJms,
    [EPersonality.INFP]: cnNameINFPms,
    [EPersonality.ISTJ]: cnNameISTJms,
    [EPersonality.INTP]: cnNameINTPms,
    [EPersonality.INFJ]: cnNameINFJms,
    [EPersonality.ENTJ]: cnNameENTJms,
    [EPersonality.ISFP]: cnNameISFPms,
    [EPersonality.ENTP]: cnNameENTPms,
    [EPersonality.ENFP]: cnNameENFPms,
};

/**
 * 16 种人格对应的中文名 for MileSight 白色风格的，用于非首页
 */
export const CN_NAME_MS_2 = {
    [EPersonality.ESFJ]: cnNameESFJms2,
    [EPersonality.ESFP]: cnNameESFPms2,
    [EPersonality.INTJ]: cnNameINTJms2,
    [EPersonality.ISFJ]: cnNameISFJms2,
    [EPersonality.ESTP]: cnNameESTPms2,
    [EPersonality.ESTJ]: cnNameESTJms2,
    [EPersonality.ISTP]: cnNameISTPms2,
    [EPersonality.ENFJ]: cnNameENFJms2,
    [EPersonality.INFP]: cnNameINFPms2,
    [EPersonality.ISTJ]: cnNameISTJms2,
    [EPersonality.INTP]: cnNameINTPms2,
    [EPersonality.INFJ]: cnNameINFJms2,
    [EPersonality.ENTJ]: cnNameENTJms2,
    [EPersonality.ISFP]: cnNameISFPms2,
    [EPersonality.ENTP]: cnNameENTPms2,
    [EPersonality.ENFP]: cnNameENFPms2,
};

/**
 * A4 YS 的人格中文
 */
export const CN_NAME_YS_A4 = {
    [EPersonality.ESFJ]: cnNameESFJa4,
    [EPersonality.ESFP]: cnNameESFPa4,
    [EPersonality.INTJ]: cnNameINTJa4,
    [EPersonality.ISFJ]: cnNameISFJa4,
    [EPersonality.ESTP]: cnNameESTPa4,
    [EPersonality.ESTJ]: cnNameESTJa4,
    [EPersonality.ISTP]: cnNameISTPa4,
    [EPersonality.ENFJ]: cnNameENFJa4,
    [EPersonality.INFP]: cnNameINFPa4,
    [EPersonality.ISTJ]: cnNameISTJa4,
    [EPersonality.INTP]: cnNameINTPa4,
    [EPersonality.INFJ]: cnNameINFJa4,
    [EPersonality.ENTJ]: cnNameENTJa4,
    [EPersonality.ISFP]: cnNameISFPa4,
    [EPersonality.ENTP]: cnNameENTPa4,
    [EPersonality.ENFP]: cnNameENFPa4,
};

/**
 * 主题名称
 */
export enum ETheme {
    /** 蓝色-传统主义：ISTJ 物流师、ISFJ守护者、ESTJ总经理、ESFJ东道主 */
    blue = 'blue',
    /** 紫色-理性主义：INTJ军师、INTP科学家、ENTJ统帅、ENTP辩论家 */
    purple = 'purple',
    /** 黄色-实用主义：ISTP探险家、ISFP艺术家、ESTP实践者、ESFP乐天派 */
    yellow = 'yellow',
    /** 绿色-理想主义：INFJ精神导师、INFP治愈者、ENFJ教育家、ENFP追梦人 */
    green = 'green',
}

/**
 * 不同人格使用不同主题，该函数根据人格名称获取对应的主题名称
 */
export const getThemeByPersonalityName = (name?: EPersonality) => {
    return name
        ? {
              [EPersonality.ESFJ]: ETheme.blue,
              [EPersonality.ESFP]: ETheme.yellow,
              [EPersonality.INTJ]: ETheme.purple,
              [EPersonality.ISFJ]: ETheme.blue,
              [EPersonality.ESTP]: ETheme.yellow,
              [EPersonality.ESTJ]: ETheme.blue,
              [EPersonality.ISTP]: ETheme.yellow,
              [EPersonality.ENFJ]: ETheme.green,
              [EPersonality.INFP]: ETheme.green,
              [EPersonality.ISTJ]: ETheme.blue,
              [EPersonality.INTP]: ETheme.purple,
              [EPersonality.INFJ]: ETheme.green,
              [EPersonality.ENTJ]: ETheme.purple,
              [EPersonality.ISFP]: ETheme.yellow,
              [EPersonality.ENTP]: ETheme.purple,
              [EPersonality.ENFP]: ETheme.green,
          }[name]
        : void 0;
};

export type TThemeConfig = {
    /**
     * 背景颜色
     */
    bg: string;
    /**
     * 背景：上部分
     */
    bg1: string;
    /**
     * 背景：下部分
     */
    bg2: string;
    /**
     * 这个字段是只有在 A4 纸面前才有意义
     */
    bgFull?: string;
    /**
     * 主题颜色
     */
    main: string;
    /**
     * 只针对 yeastar 有用，应用场景：
     *
     * Section 组件中的 IconPoint 组件，包括：
     * 个性特征、优势与盲点、合适的职业、合适的工作环境、潜在阻碍、发展建议
     */
    iconPoint?: string;
    scoreCardMain: string;
    scoreCardSecondary: string;
    /**
     * 针对 A4 的 Yeastar 和 MileSight 有用
     */
    scoreCardUpperLetterMain?: string;
    /**
     * 针对 A4 的 Yeastar 和 MileSight 有用
     */
    scoreCardUpperLetterSecondary?: string;
    footerTextColor: string;
    /**
     * 星宝颜色，只针对 milesight 有用
     */
    starBabyColor?: string;
    /**
     * 只针对 milesight 有用
     */
    cardHeaderGradientColors?: [string, string];
    /**
     * 全局的文本颜色
     */
    textColor: string;
};

/********** Yeastar ***********/
/**
 * 紫色：
 * 背景 #e7dfea
 * 主题色 #7e6499
 * scoreCard 主题色 #a687b9 辅助色 #5b9bb0
 */
export const THEME_YS_PURPLE: TThemeConfig = {
    bg: '#e7dfea',
    bg1: bgPurple1,
    bg2: bgPurple2,
    bgFull: bgPurple,
    main: '#7e6499',
    iconPoint: iconPointPurple,
    scoreCardMain: '#a687b9',
    scoreCardSecondary: '#5b9bb0',
    scoreCardUpperLetterMain: '#796193',
    scoreCardUpperLetterSecondary: '#287486',
    footerTextColor: '#e7dfea',
    textColor: '#101f3a',
};
/**
 * 绿色：
 * 背景 #d6ecd8
 * 主题色 #6d973f
 * scoreCard 主题色 #7ba947 辅助色 #d8b968
 */
export const THEME_YS_GREEN: TThemeConfig = {
    bg: '#d6ecd8',
    bg1: bgGreen1,
    bg2: bgGreen2,
    bgFull: bgGreen,
    main: '#6d973f',
    iconPoint: iconPointGreen,
    scoreCardMain: '#7ba947',
    scoreCardSecondary: '#d8b968',
    scoreCardUpperLetterMain: '#5f8141',
    scoreCardUpperLetterSecondary: '#b48934',
    footerTextColor: '#d6ecd8',
    textColor: '#101f3a',
};
/**
 * 蓝色：
 * 背景 #d9eaf0
 * 主题色 #4298b4
 * scoreCard 主题色 #3aa6c9 辅助色 #d397a4
 */
export const THEME_YS_BLUE: TThemeConfig = {
    bg: '#d9eaf0',
    bg1: bgBlue1,
    bg2: bgBlue2,
    bgFull: bgBlue,
    main: '#4298b4',
    iconPoint: iconPointBlue,
    scoreCardMain: '#3aa6c9',
    scoreCardSecondary: '#d397a4',
    scoreCardUpperLetterMain: '#358297',
    scoreCardUpperLetterSecondary: '#bd7788',
    footerTextColor: '#d9eaf0',
    textColor: '#101f3a',
};
/**
 * 黄色：
 * 背景 #f9eed7
 * 主题色 #ebc154
 * scoreCard 主题色 #ebc154 辅助色 #efbba6
 */
export const THEME_YS_YELLOW: TThemeConfig = {
    bg: '#f9eed7',
    bg1: bgYellow1,
    bg2: bgYellow2,
    bgFull: bgYellow,
    main: '#ebc154',
    iconPoint: iconPointYellow,
    scoreCardMain: '#ebc154',
    scoreCardSecondary: '#efbba6',
    scoreCardUpperLetterMain: '#c69630',
    scoreCardUpperLetterSecondary: '#d88e75',
    footerTextColor: '#f9eed7',
    textColor: '#101f3a',
};

/********** MileSight ***********/
/**
 * 紫色：
 * 背景 #e7dfea
 * 主题色 #7e6499
 * scoreCard 主题色 #a687b9 辅助色 #5b9bb0
 */
export const THEME_MS_PURPLE: TThemeConfig = {
    ...THEME_YS_PURPLE,
    bg: '#e7dfea',
    bg1: bgPurple1ms,
    bg2: bgPurple2ms,
    bgFull: bgPurple,
    main: '#7e6499',
    iconPoint: void 0,
    scoreCardMain: '#7e6499',
    scoreCardSecondary: '#738ec4',
    scoreCardUpperLetterMain: '#372547',
    scoreCardUpperLetterSecondary: '#2e3668',
    footerTextColor: '#fffefe',
    starBabyColor: '#6500a0',
    cardHeaderGradientColors: ['#693f94', '#7e6499'],
    textColor: '#251707',
};
/**
 * 绿色：
 * 背景 #d6ecd8
 * 主题色 #6d973f
 * scoreCard 主题色 #7ba947 辅助色 #d8b968
 */
export const THEME_MS_GREEN: TThemeConfig = {
    ...THEME_YS_GREEN,
    bg: '#d6ece3',
    bg1: bgGreen1ms,
    bg2: bgGreen2ms,
    bgFull: bgGreen,
    main: '#6c973e',
    iconPoint: void 0,
    scoreCardMain: '#6c973e',
    scoreCardSecondary: '#ecc462',
    scoreCardUpperLetterMain: '#305732',
    scoreCardUpperLetterSecondary: '#ac585d',
    footerTextColor: '#fffefe',
    starBabyColor: '#39591b',
    cardHeaderGradientColors: ['#487419', '#6c973e'],
    textColor: '#251707',
};
/**
 * 蓝色：
 * 背景 #d9eaf0
 * 主题色 #4298b4
 * scoreCard 主题色 #3aa6c9 辅助色 #d397a4
 */
export const THEME_MS_BLUE: TThemeConfig = {
    ...THEME_YS_BLUE,
    bg: '#d9eaf0',
    bg1: bgBlue1ms,
    bg2: bgBlue2ms,
    bgFull: bgBlue,
    main: '#4696f5',
    iconPoint: void 0,
    scoreCardMain: '#4696f5',
    scoreCardSecondary: '#abcddf',
    scoreCardUpperLetterMain: '#2d3c74',
    scoreCardUpperLetterSecondary: '#4589ae',
    footerTextColor: '#fffefe',
    starBabyColor: '#4696f5',
    cardHeaderGradientColors: ['#2a73e1', '#4696f5'],
    textColor: '#251707',
};
/**
 * 黄色：
 * 背景 #f9eed7
 * 主题色 #ebc154
 * scoreCard 主题色 #ebc154 辅助色 #efbba6
 */
export const THEME_MS_YELLOW: TThemeConfig = {
    ...THEME_YS_YELLOW,
    bg: '#f9eed7',
    bg1: bgYellow1ms,
    bg2: bgYellow2ms,
    bgFull: bgYellow,
    main: '#ffc600',
    iconPoint: void 0,
    scoreCardMain: '#f2b100',
    scoreCardSecondary: '#f5bb9e',
    scoreCardUpperLetterMain: '#603820',
    scoreCardUpperLetterSecondary: '#ac585d',
    footerTextColor: '#fffefe',
    starBabyColor: '#ffc015',
    cardHeaderGradientColors: ['#ffd200', '#f4d65d'],
    textColor: '#251707',
};
