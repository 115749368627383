import { REQUEST_BASE_URL } from '@/utils/constants';
import axios from 'axios';
import { stringify } from 'qs';

const API_PREFIX = '/oa_web_survey';

export interface Response {
    code: number;
    data: PdfData[];
    message: string;
    [property: string]: any;
}

export interface PdfData {
    /** 报告 ID：只有批量现在时才需要用到这个字段 */
    entrance_id?: string | number;
    /**
     * 职业预测人格因素
     */
    career_prediction?: number[];
    /**
     * 职业相关人格因素
     */
    career_related?: number[];
    /**
     * 创建时间
     */
    create_at?: number;
    /**
     * 发展潜力
     */
    development_potential?: number[];
    /**
     * 主导动机
     */
    leitmotiv?: number[];
    /**
     * 整体结果
     */
    overall_results?: string;
    /**
     * 16种人格因素得分
     */
    personality_factor_score?: number[];
    /**
     * 风险指标预警
     */
    risk_indicator_warning?: RiskIndicatorWarning;
    /**
     * 测评类型
     */
    survey_type?: string;
    /**
     * 测验有效性校验
     */
    test_validity_verification?: TestValidityVerification;
    /**
     * 用户信息
     */
    user_info?: UserInfo;
    /**
     * 工作能力
     */
    working_ability?: number[];
}

/**
 * 风险指标预警
 */
export interface RiskIndicatorWarning {
    /**
     * 完成任务相关的指标预警
     */
    complete_the_task?: string[];
    /**
     * 合作相关的指标预警
     */
    cooperation?: string[];
    /**
     * 心理健康的指标预警
     */
    mental_health?: string[];
}

/**
 * 测验有效性校验
 */
export interface TestValidityVerification {
    /**
     * 作答时长
     */
    answer_duration?: string;
    /**
     * 测验有效性校验说明
     */
    description?: string;
    /**
     * 选项分布
     */
    option_distribution?: string[];
    /**
     * 选项分布描述
     */
    option_distribution_description?: string;
    /**
     * 社会许可性
     */
    social_desirability?: string;
    /**
     * 社会许可性描述
     */
    social_desirability_description?: string;
}

/**
 * 用户信息
 */
export interface UserInfo {
    /**
     * 大学
     */
    college?: string;
    /**
     * 姓名
     */
    name?: string;
    /**
     * 岗位
     */
    position?: string;
    /**
     * 性别
     */
    sex?: string;
    /**
     * 用户标识
     */
    user_id?: string;
}

export interface IFetchPdfDataQueryParams {
    /**
     * 测评ID
     */
    // survey_id: number | string;
    /**
     * 用户标识
     */
    // user_id: string;
    /**
     * 入口标识
     */
    entrance_ids: (string | number)[];

    /**
     * 登录用户的 Token
     */
    token: string;
}
export function fetchPdfData(params: IFetchPdfDataQueryParams) {
    return axios.get<Response>(`${API_PREFIX}/report/16pf`, {
        baseURL: REQUEST_BASE_URL,
        params: {
            entrance_ids: params.entrance_ids,
        },
        paramsSerializer: search => stringify(search, { arrayFormat: 'repeat' }),
        headers: {
            Authorization: !params?.token ? void 0 : `Bearer ${params.token}`,
        },
    });
}
