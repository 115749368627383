import { permissionCodes } from "./code";

export function hasPermissions(code) {
  const permissionList = JSON.parse(
    localStorage.getItem("permissions") || "{}"
  );
  if (permissionList?.length) {
    return permissionList?.includes(permissionCodes?.[code]);
  }
  return false;
}
