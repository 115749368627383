import React, { ReactNode } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { TpDFType, useStyles } from '@/components/PdfReporter/utils/styles';
import { Style } from '@react-pdf/types';

interface HighTableProps {
    width: number; // 表格的宽度
    height: number; // 表格的高度
    pdfType: TpDFType; // pdf的类型
    header?: ReactNode; // 表格的头部
    headerStyle?: Style; // 表格的头部
}

const HighTable: React.FC<HighTableProps> = props => {
    const { width, height, headerStyle, header, pdfType } = props;
    const { colors } = useStyles(pdfType);
    const borderColor = pdfType === 'ys' ? '#fff' : '#4696F5';

    const styles = StyleSheet.create({
        tableBox: {
            borderRadius: '18.9',
            border: `1 solid ${borderColor}`,
            position: 'relative',
        },
        tableHeader: {
            height: 37.8,
            position: 'absolute',
            top: -1,
            left: -1,
            borderRadius: '18.9',
            backgroundColor: colors.themeColor,
        },
    });

    return (
        <View
            style={{
                width,
                height,
                ...styles.tableBox,
                backgroundColor: '#fff',
            }}
        >
            <View
                style={{
                    ...styles.tableHeader,
                    width,
                    ...headerStyle,
                }}
            >
                {header}
            </View>
            {props.children}
        </View>
    );
};

export default HighTable;
