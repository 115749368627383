import TextWithBlank from '@components/PdfReporter/components/TextWithBlank';
import { View, Image, Text, Svg, Path } from '@react-pdf/renderer';
import { usePdfTheme, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';
import scoreCardShadowLeft from '@components/MbtiPdfReporterA4/assets/ys/scoreCardShadow_left.png';
import scoreCardShadowRight from '@components/MbtiPdfReporterA4/assets/ys/scoreCardShadow_right.png';
import scoreCardShadowFull from '@components/MbtiPdfReporterA4/assets/ms/scoreCardShadow_full.png';

export type TScoreCardData = {
    upperLetter: string;
    cnName: string;
    enName: string;
    description: string;
    upperLetterStyle?: any;
    cnNameStyle?: any;
    enNameStyle?: any;
    descriptionStyle?: any;
};
function ScoreCard(props: { type: 'left' | 'right'; data: [TScoreCardData, TScoreCardData] }) {
    const pdfType = usePdfType();
    const theme = usePdfTheme();
    const { type, data } = props;

    const offset: number = pdfType === 'ys' ? 0 : -3;

    return (
        <View style={{ width: 244, height: 85, position: 'relative', color: 'white' }}>
            {pdfType === 'ms' ? (
                <BgFull type={type} />
            ) : (
                <>
                    {type === 'right' ? <BgRight /> : null}
                    {type === 'left' ? <BgLeft /> : null}
                </>
            )}
            <View style={{ width: 122, height: 85, position: 'absolute', left: 0, top: -10 }}>
                <Text
                    style={{
                        fontSize: 78.55,
                        position: 'absolute',
                        bottom: -16,
                        left: 0,
                        width: 80,
                        // height: 80,
                        // textAlign: 'center',
                        // color: pdfType === 'ms' && type === 'left' ? theme?.scoreCardMain : void 0,
                        color: type === 'left' ? theme?.scoreCardUpperLetterMain : theme?.scoreCardUpperLetterSecondary,
                        ...data[0]?.upperLetterStyle,
                    }}
                >
                    {data[0].upperLetter}
                </Text>
                <Text
                    style={{
                        fontSize: 13.61,
                        position: 'absolute',
                        top: 22,
                        left: 14 + offset,
                        ...data[0]?.cnNameStyle,
                    }}
                >
                    {data[0].cnName}
                </Text>
                <Text
                    style={{
                        fontSize: 10.4,
                        position: 'absolute',
                        top: 26,
                        left: 48 + offset,
                        ...data[0]?.enNameStyle,
                    }}
                >
                    {data[0].enName}
                </Text>
                <TextWithBlank
                    style={{
                        fontSize: 8.51,
                        position: 'absolute',
                        top: 42,
                        left: 14 + offset,
                        width: 95,
                        letterSpacing: -1,
                        ...data[0]?.descriptionStyle,
                    }}
                >
                    {data[0].description}
                </TextWithBlank>
            </View>
            <View style={{ width: 122, height: 85, position: 'absolute', right: 0, top: -10 }}>
                <View
                    style={{
                        fontSize: 78.55,
                        position: 'absolute',
                        bottom: -16,
                        left: 0,
                        width: 80,
                        // height: 80,
                        // textAlign: 'center',
                        // color: pdfType === 'ms' && type === 'right' ? theme?.scoreCardMain : void 0,
                        color:
                            type === 'right' ? theme?.scoreCardUpperLetterMain : theme?.scoreCardUpperLetterSecondary,
                        ...data[1]?.upperLetterStyle,
                    }}
                >
                    <Text>{data[1].upperLetter}</Text>
                </View>
                <Text
                    style={{
                        fontSize: 13.61,
                        position: 'absolute',
                        top: 22,
                        left: 14 + offset,
                        ...data[1]?.cnNameStyle,
                    }}
                >
                    {data[1].cnName}
                </Text>
                <Text
                    style={{
                        fontSize: 10.4,
                        position: 'absolute',
                        top: 26,
                        left: 48 + offset,
                        ...data[1]?.enNameStyle,
                    }}
                >
                    {data[1].enName}
                </Text>
                <TextWithBlank
                    style={{
                        fontSize: 8.51,
                        position: 'absolute',
                        top: 42,
                        left: 14 + offset,
                        width: 95,
                        letterSpacing: -1,
                        ...data[1]?.descriptionStyle,
                    }}
                >
                    {data[1].description}
                </TextWithBlank>
            </View>
        </View>
    );
}

function BgLeft() {
    const theme = usePdfTheme();

    return (
        <>
            <View
                style={{
                    width: 244,
                    height: 85,
                    position: 'absolute',
                    left: 0,
                    top: -10,
                }}
            >
                <Svg viewBox="0 0 488 170">
                    <Path
                        fillRule="evenodd"
                        fill={theme.scoreCardSecondary}
                        d="M244.001001,0 L488,0 L488,0 L488,113.349988 C488,144.622809 462.595329,169.976018 431.256559,169.976018 L244.001001,169.976018 L244.001001,169.976018 L244.001001,0 L244.001001,0 Z"
                    />
                </Svg>
            </View>
            <View
                style={{
                    width: 267,
                    height: 130,
                    position: 'absolute',
                    left: -23,
                    top: -28,
                }}
            >
                <Image src={scoreCardShadowLeft} />
            </View>
            <View
                style={{
                    width: 244,
                    height: 85,
                    position: 'absolute',
                    left: 0,
                    top: -10,
                }}
            >
                <Svg viewBox="0 0 488 170">
                    <Path
                        fillRule="evenodd"
                        fill={theme.scoreCardMain}
                        d="M56.7454436,0.0239822249 L244.001001,0.0239822249 L244.001001,0.0239822249 L244.001001,170 L244.001001,170 L0,170 L0,170 L0,56.6480138 C0,25.3751925 25.4066739,0.0239822249 56.7454436,0.0239822249 Z"
                    />
                </Svg>
            </View>
        </>
    );
}
function BgRight() {
    const theme = usePdfTheme();

    return (
        <>
            <View
                style={{
                    width: 244,
                    height: 85,
                    position: 'absolute',
                    left: 0,
                    top: -10,
                }}
            >
                <Svg viewBox="0 0 488 170">
                    <Path
                        fillRule="evenodd"
                        fill={theme.scoreCardSecondary}
                        d="M56.7434408,0.0239825069 L387.372668,0.0239825069 L387.372668,0.0239825069 L387.372668,113.375303 C387.372668,144.648492 361.967997,170 330.629228,170 L0,170 L0,170 L0,56.6486798 C0,25.3754908 25.4046711,0.0239825069 56.7434408,0.0239825069 Z"
                    />
                </Svg>
            </View>
            <View
                style={{
                    width: 267,
                    height: 130,
                    position: 'absolute',
                    left: 0,
                    top: -28,
                }}
            >
                <Image src={scoreCardShadowRight} />
            </View>
            <View
                style={{
                    width: 244,
                    height: 85,
                    position: 'absolute',
                    left: 0,
                    top: -10,
                }}
            >
                <Svg viewBox="0 0 488 170">
                    <Path
                        fillRule="evenodd"
                        fill={theme.scoreCardMain}
                        d="M243.998999,0 L488,0 L488,0 L488,113.35132 C488,144.624509 462.593326,169.976017 431.254556,169.976017 L243.998999,169.976017 L243.998999,169.976017 L243.998999,0 L243.998999,0 Z"
                    />
                </Svg>
            </View>
        </>
    );
}

/**
 * 针对 Milesight
 */
function BgFull(props: { type: 'left' | 'right' }) {
    const theme = usePdfTheme();
    const { type } = props;

    return (
        <>
            <View
                style={{
                    width: 244,
                    height: 80,
                    position: 'absolute',
                    left: -11,
                    top: -16,
                }}
            >
                <Image src={scoreCardShadowFull} />
            </View>
            <View
                style={{
                    width: 244,
                    height: 80,
                    position: 'absolute',
                    left: -11,
                    top: -16,
                    overflow: 'hidden',
                    borderRadius: 80,
                    backgroundColor: theme?.scoreCardSecondary,
                }}
            >
                {type === 'left' ? (
                    <View
                        style={{
                            width: 122,
                            height: 80,
                            backgroundColor: theme?.scoreCardMain,
                            position: 'absolute',
                            left: 0,
                            top: 0,
                        }}
                    />
                ) : (
                    <View
                        style={{
                            width: 122,
                            height: 80,
                            backgroundColor: theme?.scoreCardMain,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        }}
                    />
                )}
            </View>
        </>
    );
}

function ScoreCardMsA4(props: { type: 'left' | 'right'; data: [TScoreCardData, TScoreCardData] }) {
    const theme = usePdfTheme();
    const { type, data } = props;

    return (
        <View
            style={{
                width: 244,
                height: 85,
                position: 'relative',
                color: 'white',
            }}
        >
            <View
                style={{
                    width: 244,
                    height: 85,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
            >
                <Image src={scoreCardShadowFull} />
            </View>
            <View
                style={{
                    width: 236.16,
                    height: 74.47,
                    backgroundColor: theme?.scoreCardSecondary,
                    overflow: 'hidden',
                    borderRadius: 74.47,
                    position: 'absolute',
                    top: 4.5,
                    left: 4,
                }}
            >
                <View
                    style={{
                        width: 118.08,
                        height: 74.47,
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        backgroundColor: type === 'left' ? theme?.scoreCardMain : void 0,
                    }}
                >
                    <View
                        style={{
                            width: 80,
                            height: 80,
                            overflow: 'hidden',
                            // backgroundColor: 'red',
                            // borderRadius: 80,
                            textAlign: 'right',
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 75.12,
                                lineHeight: 1,
                                color:
                                    type === 'left'
                                        ? theme?.scoreCardUpperLetterMain
                                        : theme?.scoreCardUpperLetterSecondary,
                                position: 'relative',
                                bottom: -6,
                                opacity: 0.16,
                                ...data[0]?.upperLetterStyle,
                            }}
                        >
                            {data[0].upperLetter}
                        </Text>
                    </View>
                    <Text
                        style={{
                            fontSize: 13.61,
                            position: 'absolute',
                            top: 16,
                            left: 12,
                            ...data[0]?.cnNameStyle,
                        }}
                    >
                        {data[0].cnName}
                    </Text>
                    <Text
                        style={{
                            fontSize: 10.4,
                            position: 'absolute',
                            top: 20,
                            left: 48,
                            ...data[0]?.enNameStyle,
                        }}
                    >
                        {data[0].enName}
                    </Text>
                    <TextWithBlank
                        style={{
                            fontSize: 8.51,
                            position: 'absolute',
                            top: 36,
                            left: 12,
                            width: 95,
                            letterSpacing: -1,
                            ...data[0]?.descriptionStyle,
                        }}
                    >
                        {data[0].description}
                    </TextWithBlank>
                </View>
                <View
                    style={{
                        width: 118.08,
                        height: 74.47,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        backgroundColor: type === 'right' ? theme?.scoreCardMain : void 0,
                    }}
                >
                    <View
                        style={{
                            width: 80,
                            height: 80,
                            overflow: 'hidden',
                            // backgroundColor: 'red',
                            // borderRadius: 80,
                            textAlign: 'right',
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 75.12,
                                lineHeight: 1,
                                color:
                                    type === 'right'
                                        ? theme?.scoreCardUpperLetterMain
                                        : theme?.scoreCardUpperLetterSecondary,
                                position: 'relative',
                                bottom: -6,
                                opacity: 0.16,
                                ...data[1]?.upperLetterStyle,
                            }}
                        >
                            {data[1].upperLetter}
                        </Text>
                    </View>
                    <Text
                        style={{
                            fontSize: 13.61,
                            position: 'absolute',
                            top: 16,
                            left: 12,
                            ...data[1]?.cnNameStyle,
                        }}
                    >
                        {data[1].cnName}
                    </Text>
                    <Text
                        style={{
                            fontSize: 10.4,
                            position: 'absolute',
                            top: 20,
                            left: 48,
                            ...data[1]?.enNameStyle,
                        }}
                    >
                        {data[1].enName}
                    </Text>
                    <TextWithBlank
                        style={{
                            fontSize: 8.51,
                            position: 'absolute',
                            top: 36,
                            left: 12,
                            width: 95,
                            letterSpacing: -1,
                            ...data[1]?.descriptionStyle,
                        }}
                    >
                        {data[1].description}
                    </TextWithBlank>
                </View>
            </View>
        </View>
    );
}

ScoreCard.ScoreCardMsA4 = ScoreCardMsA4;

export default ScoreCard;
