import { CSSProperties, ReactNode, forwardRef, useImperativeHandle, useState } from 'react';
import { Icon, Table, Input, TablePaginationConfig, TableProps, Row, Col } from 'ysd-pp';
import { useRequest } from '@/utils/request';
import { returnArray } from '@/utils';
import classNames from 'classnames';
import { SearchIcon } from 'ysd-pp/es/icon';
import HeaderSearchDropdown from '@/pages/components/HeaderSearchDropdown';
import { ColumnType } from 'ysd-pp/lib/table';
import styles from './index.module.less';
import { useDebounceFn } from 'ahooks';

interface IColumnsType extends ColumnType<any> {
    isFilter?: boolean; // 是否需要过滤
    filterSearchType?: string; // 过滤类型
    filterSearchName?: string; // 过滤字段名
}

interface IEnhancedTable extends TableProps<any> {
    noTabs?: boolean; // 是否有tabs
    columns: IColumnsType[]; // 表格列
    tableApi: (params: any) => Promise<any>; // 表格请求api
    showSearch?: boolean; // 是否显示搜索框
    operaButtons?: ReactNode; // 表格操作按钮
}

// 封装表格，包含表格搜索、分页、表格列过滤
const EnhancedTable = forwardRef((props: IEnhancedTable, tableRef) => {
    const { noTabs, tableApi, columns, showSearch } = props;
    const [searchParamsLocal, setSearchParamsLocal] = useState<Record<string, any>>({}); // 搜索参数

    useImperativeHandle(tableRef, () => ({
        handleReFreshTable,
    }));

    /** 分页 */
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        total: 0,
        current: 1,
        pageSize: 10,
    });
    // 列表api
    const {
        loading,
        run: runListData,
        data: data,
    } = useRequest(
        async params => {
            const res = await tableApi({
                page_no: pagination.current,
                page_size: pagination.pageSize,
                ...params,
            });

            setPagination({
                pageSize: params?.page_size || 10,
                current: params?.page_no || 1,
                total: res?.data?.total || 0,
            });

            return returnArray(res?.data?.list);
        },
        {
            manual: false,
        },
    );

    // 表格列过滤
    const columnSearchFilter = (dataIndex, _searchType = 'input', searchObj) => {
        const { searchParamsLocal, handleHeaderCellSearch, handleHeaderCellSearchValueChange } = searchObj;
        return {
            filterDropdown: ({ confirm, filterListSelected, filters }) => {
                const _searchParamsLocal = searchParamsLocal || {};

                if (filterListSelected !== undefined) {
                    _searchParamsLocal[dataIndex] = filterListSelected;
                }
                return (
                    <>
                        <HeaderSearchDropdown
                            searchParams={{ ...searchParamsLocal }}
                            confirm={confirm}
                            columnItem={
                                {
                                    dataIndex,
                                    searchType: _searchType,
                                    filterList: filters,
                                } as any
                            }
                            onSearch={handleHeaderCellSearch}
                            onSearchValueChange={handleHeaderCellSearchValueChange}
                        />
                    </>
                );
            },
            filterIcon: () => {
                let filtered = false;
                if (_searchType === 'input' || _searchType === 'radio') {
                    filtered = !!searchParamsLocal?.[`${dataIndex}`] || searchParamsLocal?.[`${dataIndex}`] === 0;
                } else {
                    filtered = !!searchParamsLocal?.[`${dataIndex}`] && searchParamsLocal?.[`${dataIndex}`]?.length;
                }
                if (_searchType === 'input') {
                    return <SearchIcon className={classNames('search-icon', filtered ? 'search-icon-active' : '')} />;
                }
                return (
                    <Icon type="filter" className={classNames('search-icon', filtered ? 'search-icon-active' : '')} />
                );
            },
            onFilter: (value, record) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        };
    };

    const handleTableChange = (params: Record<string, any>) => {
        const { searchColumns = searchParamsLocal, current = 1, pageSize = 10 } = params || {};
        runListData({
            ...searchColumns,
            page_no: current,
            page_size: pageSize,
        });
    };

    // 表头搜索回调（点击确认按钮，或者回车）
    const handleHeaderCellSearch = () => {
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            current: pNumber,
            page_size: pSize,
            ...searchColumns
        } = searchParamsLocal;
        setSearchParamsLocal(searchColumns);
        handleTableChange?.({
            searchColumns,
            current: 1,
            page_size: pSize,
        });
    };

    // 表头搜索值改变回调
    const handleHeaderCellSearchValueChange = (dataIndex: string, value: any, immediately?: boolean) => {
        const params = searchParamsLocal;
        params[dataIndex] = value;
        setSearchParamsLocal(params);
        if (immediately) {
            handleTableChange?.({
                searchParamsLocal,
                searchColumns: params,
                current: 1,
            });
        }
    };

    const handleReFreshTable = () => {
        handleTableChange({
            searchColumns: { ...searchParamsLocal },
            current: 1,
        });
    };

    const columnsWithFilter = columns.map((column: IColumnsType) => {
        if (column.isFilter) {
            return {
                ...column,
                ...columnSearchFilter(
                    column?.filterSearchName ? column?.filterSearchName : column.dataIndex,
                    column.filterSearchType,
                    {
                        searchParamsLocal,
                        handleHeaderCellSearch,
                        handleHeaderCellSearchValueChange,
                    },
                ),
            };
        }
        return column;
    });

    const handleSearchChange = (e: any) => {
        const value = e.target.value?.trim();
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            current: pNumber,
            page_size: pSize,
            ...searchColumns
        } = searchParamsLocal;
        setSearchParamsLocal({ ...searchColumns, keyword: value });
        handleTableChange?.({
            searchColumns: {
                ...searchColumns,
                keyword: value,
            },
            current: 1,
            page_size: pSize,
        });
    };

    const { run: debounceSearchChange } = useDebounceFn(handleSearchChange, { wait: 300 });

    return (
        <>
            <Row className={styles['table-top']}>
                <Col>{props.operaButtons}</Col>
                {showSearch && (
                    <Col className={styles['table-search']}>
                        <Input
                            allowClear
                            placeholder="Search"
                            className={styles['table-search-input']}
                            onChange={debounceSearchChange}
                            suffix={<SearchIcon />}
                        />
                    </Col>
                )}
            </Row>

            <Table
                loading={loading}
                rowKey="id"
                columns={columnsWithFilter as any}
                dataSource={data || []}
                onChange={handleTableChange}
                pagination={{
                    ...pagination,
                    onReload: handleReFreshTable,
                    showQuickJumper: true,
                    showSizeChanger: true,
                }}
                className={styles[loading ? 'hidden-empty' : '']}
                scroll={{ x: 120 * columns.length, y: 'var(--scroll-y, 200)' }}
                style={
                    {
                        ['--scroll-y']: noTabs ? 'calc(100vh - 252px)' : 'calc(100vh - 292px)',
                    } as CSSProperties
                }
            />
        </>
    );
});
export default EnhancedTable;
