/**
 * 生成的测评链接的类型
 */
export const permissionCodes = {
    // 性格测评相关权限
    entrance: 'entrance:query',
    entranceGenerateUrl: 'entrance:generateUrl',
    entranceDownloadReport: 'entrance:downloadReport',
    entranceDownloadAllReport: 'entrance:downloadAllReport',
    surveyTimeUpdate: 'survey:timeUpdate',
    // 用户管理相关权限
    user: 'user:query',
    userCreate: 'user:create',
    userUpdate: 'user:update',
    userDelete: 'user:delete',
    entranceEnable: 'entrance:enable',
    entranceDisable: 'entrance:disable',
    // 岗位规则相关权限
    station: 'station:query',
    stationCreate: 'station:create',
    stationUpdate: 'station:update',
    stationDelete: 'station:delete',
};
