import { Path, Svg, View } from '@react-pdf/renderer';
import { usePdfTheme } from '@components/MbtiPdfReporterMobile/utils/styles';
import WrapText from './WrapText';

function ListItem(props: { style?: any; text: string }) {
    const theme = usePdfTheme();
    const { style = {}, text } = props;
    const { fontSize, wrapTextWidth, ...otherStyle } = style;

    return (
        <View style={{ position: 'relative', paddingLeft: 10, marginTop: 2, ...otherStyle }}>
            <View style={{ width: 5, height: 5, position: 'absolute', left: 0, top: 5 }}>
                <Svg viewBox="0 0 5 5">
                    <Path
                        fillRule="evenodd"
                        fill={theme?.main}
                        d="M2.000,0.200 C3.105,0.200 4.000,1.095 4.000,2.200 C4.000,3.304 3.105,4.200 2.000,4.200 C0.895,4.200 -0.000,3.304 -0.000,2.200 C-0.000,1.095 0.895,0.200 2.000,0.200 Z"
                    />
                </Svg>
            </View>
            <WrapText
                width={wrapTextWidth}
                indent={0}
                style={{
                    fontSize,
                }}
                text={text}
            />
        </View>
    );
}

export default ListItem;
