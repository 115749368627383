import { useEffect, useRef } from 'react';
import type { FC } from 'react';

import { DatePicker } from 'ysd-pp';
import type { RangePickerProps } from 'ysd-pp/es/date-picker/RangePicker';

const { RangePicker } = DatePicker;

const RangePicketComponent: FC<RangePickerProps> = props => {
    const { format, ...rest } = props;

    const { open } = rest;

    const innerRef = useRef<any>(null);

    //列表下拉日期选择，二次打开，聚焦处理
    useEffect(() => {
        const ref = innerRef?.current?.getRef();
        // ref?.focus();
        if (ref) {
            const timer = setTimeout(() => {
                ref?.focus();
                clearTimeout(timer);
            }, 100);
        }
    }, [open, innerRef.current]);

    return (
        <RangePicker
            {...rest}
            ref={innerRef}
            autoFocus={true}
            open={true}
            defaultOpen={true}
            ranges={
                {
                    Reset: undefined,
                } as any
            }
            showTime={(typeof rest?.showTime === 'undefined' ? true : rest?.showTime) as any}
            format={format}
            getPopupContainer={node => node.parentNode?.parentNode as any}
            showInnerController
        />
    );
};

export default RangePicketComponent;
