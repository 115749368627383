import PdfMbti from '@/pages/pdf/mbti';
import RootLayout from '@layouts/index';
import Page403 from '@pages/403';
import Page404 from '@pages/404';
import Page500 from '@pages/500';
import Appraisals from '@pages/appraisals';
import Pdf from '@pages/pdf';
import PdfHigh from '@pages/pdf/high';
import UserLayout from '@pages/user';
import Login from '@pages/user/login';

export default [
    {
        path: '/',
        element: <RootLayout />,
        children: [
            {
                // 性格测评页面
                path: '',
                element: <Appraisals />,
            },
            {
                path: 'user',
                element: <UserLayout />,
                children: [
                    {
                        // 登陆页面
                        path: 'login',
                        element: <Login />,
                    },
                    {
                        path: '',
                        element: <Page404 />,
                    },
                    {
                        path: '*',
                        element: <Page404 />,
                    },
                ],
            },
            {
                path: 'pdf/:type',
                element: <Pdf />,
            },
            {
                path: 'pdfmbti/:type',
                element: <PdfMbti isMobile={true} />,
            },
            {
                path: 'pdfmbti_a4/:type',
                element: <PdfMbti isMobile={false} />,
            },
            {
                path: 'pdfhigh/:type',
                element: <PdfHigh />,
            },
            {
                path: '403',
                element: <Page403 />,
            },
            {
                path: '500',
                element: <Page500 />,
            },
            {
                path: '*',
                element: <Page404 />,
            },
        ],
    },
];
