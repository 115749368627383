import ScoreSection from '@components/MbtiPdfReporterA4/components/ScoreSection';
import { TSliderValue } from '@components/MbtiPdfReporterA4/components/SliderBar';
import { TScoreCardData } from '@components/MbtiPdfReporterA4/components/ScoreCard';
import { usePdfData, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';
import { View } from '@react-pdf/renderer';

function AllScoreSections() {
    const pdfType = usePdfType();
    const {
        extrovertIntrovert = [0, 0],
        sensingIntuition = [0, 0],
        thinkingFelling = [0, 0],
        judgingPerceiving = [0, 0],
    } = usePdfData();

    const data: {
        title: string;
        sliderValues: [TSliderValue, TSliderValue];
        scoreCardData: [TScoreCardData, TScoreCardData];
    }[] = [
        {
            title: '精力支配',
            sliderValues: [
                { value: extrovertIntrovert[0], type: '(E) 外向' },
                { value: extrovertIntrovert[1], type: '(I) 内向' },
            ],
            scoreCardData: [
                {
                    upperLetter: 'E',
                    cnName: '外向',
                    enName: 'Extrovert',
                    description: '注意和精力指向外部世界和与人交往上',
                    ...(pdfType === 'ms'
                        ? {}
                        : {
                              upperLetterStyle: {
                                  transform: 'translateX(-3)',
                              },
                          }),
                },
                {
                    upperLetter: 'I',
                    cnName: '内向',
                    enName: 'Introvert',
                    description: '注意力和精力指向内部的精神世界',
                    ...(pdfType === 'ms'
                        ? {
                              upperLetterStyle: {
                                  transform: 'translateX(-10)',
                              },
                          }
                        : {
                              upperLetterStyle: {
                                  transform: 'translateX(8)',
                              },
                          }),
                },
            ],
        },
        {
            title: '信息获取',
            sliderValues: [
                { value: sensingIntuition[0], type: '(S) 感觉' },
                { value: sensingIntuition[1], type: '(N) 直觉' },
            ],
            scoreCardData: [
                {
                    upperLetter: 'S',
                    cnName: '感觉',
                    enName: 'Sensing',
                    description: '关注五官感受到的真实的、当前存在的事实和信息',
                    ...(pdfType === 'ms'
                        ? {
                              descriptionStyle: {
                                  width: 100,
                              },
                          }
                        : {
                              descriptionStyle: {
                                  width: 100,
                              },
                          }),
                },
                {
                    upperLetter: 'N',
                    cnName: '直觉',
                    enName: 'Intuition',
                    description: '基于事实进行解释分析等来探索事物的含义、关系',
                    ...(pdfType === 'ms'
                        ? {
                              upperLetterStyle: {
                                  transform: 'translateX(-6)',
                              },
                          }
                        : {
                              upperLetterStyle: {
                                  transform: 'translateX(-3)',
                              },
                          }),
                },
            ],
        },
        {
            title: '决策方式',
            sliderValues: [
                { value: thinkingFelling[0], type: '(T) 思考' },
                { value: thinkingFelling[1], type: '(F) 情感' },
            ],
            scoreCardData: [
                {
                    upperLetter: 'T',
                    cnName: '思考',
                    enName: 'Thinking',
                    description: '基于问题进行客观的逻辑分析来做决定',
                    ...(pdfType === 'ms'
                        ? {
                              upperLetterStyle: {
                                  transform: 'translateX(-6)',
                              },
                          }
                        : {
                              upperLetterStyle: {
                                  transform: 'translateX(-4)',
                              },
                          }),
                },
                {
                    upperLetter: 'F',
                    cnName: '情感',
                    enName: 'Felling',
                    description: '基于自我价值观、他人的情绪体验来做决定',
                    ...(pdfType === 'ms'
                        ? {
                              upperLetterStyle: {
                                  transform: 'translateX(-2)',
                              },
                          }
                        : {
                              upperLetterStyle: {
                                  transform: 'translateX(-2)',
                              },
                          }),
                },
            ],
        },
        {
            title: '生活方式',
            sliderValues: [
                { value: judgingPerceiving[0], type: '(J) 判断' },
                { value: judgingPerceiving[1], type: '(P) 感知' },
            ],
            scoreCardData: [
                {
                    upperLetter: 'J',
                    cnName: '判断',
                    enName: 'Judging',
                    description: '喜欢有规划、有条理、可控的生活方式',
                    ...(pdfType === 'ms' ? {} : {}),
                },
                {
                    upperLetter: 'P',
                    cnName: '感知',
                    enName: 'Perceiving',
                    description: '喜欢灵活、即兴、变化的生活方式',
                    ...(pdfType === 'ms'
                        ? {
                              upperLetterStyle: {
                                  transform: 'translateX(-2)',
                              },
                          }
                        : {
                              upperLetterStyle: {
                                  transform: 'translateX(-3)',
                              },
                          }),
                },
            ],
        },
    ];
    return (
        <View
            style={{
                width: '100%',
                height: 450,
                marginTop: 42,
                position: 'relative',
                ...(pdfType === 'ms' ? {} : {}),
            }}
        >
            {data.map((item, itemIndex) => (
                <ScoreSection
                    index={itemIndex}
                    key={item.title}
                    title={item.title}
                    sliderValues={item.sliderValues}
                    scoreCardData={item.scoreCardData}
                />
            ))}
        </View>
    );
}

export default AllScoreSections;
