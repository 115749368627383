import {
    Defs,
    G,
    LinearGradient,
    Path,
    Rect,
    Stop,
    Svg,
    Text,
    Tspan,
    View,
} from "@react-pdf/renderer";
import { addBlank, calcRectWidth } from "../utils";
import { usePdfData, useStyles } from "../utils/styles";

function ChartWorkingCompetence() {
    const { chartSTyles: cStyles, commonStyles } = useStyles();

    const { working_ability } = usePdfData();

    const size = {
        width: 326,
        height: 299,
    };

    const [xPoint, yPoint] = working_ability ?? [0, 0];

    const x = calcRectWidth(xPoint, 10, 259 - 12) + 12 - 6;
    const y = 252 - 12 - calcRectWidth(yPoint, 10, 252 - 12) - 6;

    const textSize = [64, 14];

    const startPos = {
        x: x < 14 ? 14 : x,
        y: y > 252 - 12 - 14 ? 252 - 12 - 14 : y,
        textAnchor: 259 - x < textSize[0] ? "end" : "start",
        tSpanX: 259 - x < textSize[0] ? -4 : 16.75,
    };

    return (
        <View
            style={{
                width: size.width,
                height: size.height,
            }}
        >
            <Svg
                style={{ width: size.width, height: size.height }}
                viewBox={`0 0 ${size.width} ${size.height}`}
            >
                <Defs>
                    {/* 水平渐变：从左到右 */}
                    <LinearGradient
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="0%"
                        id="linearGradient-1"
                    >
                        <Stop stopColor="#00A7F2" offset="50%"></Stop>
                        <Stop stopColor="#006EE1" offset="100%"></Stop>
                    </LinearGradient>
                </Defs>
                {/* 网格 */}
                <G transform="translate(17.000000, 27.000000)">
                    <Rect
                        fill="#F9F9F9"
                        x="0"
                        y="0"
                        width="259"
                        height="252"
                    ></Rect>
                    <Path
                        d="M9.30048077,0.538916865 L9.30048077,248.440675 M258.399038,222.959184 L9.60096154,222.959184 M258.399038,246.428571 L9.60096154,246.428571 M258.399038,198.469388 L9.60096154,198.469388 M258.399038,172.959184 L9.60096154,172.959184 M258.399038,148.469388 L9.60096154,148.469388 M258.399038,123.979592 L9.60096154,123.979592 M258.399038,99.4897959 L9.60096154,99.4897959 M258.399038,73.9795918 L9.60096154,73.9795918 M258.399038,50.5102041 L9.60096154,50.5102041 M258.399038,26.0204082 L9.60096154,26.0204082 M258.399038,0.510204082 L9.60096154,0.510204082 M59.1802885,0.538916865 L59.1802885,248.440675 M33.9399038,0.538916865 L33.9399038,248.440675 M83.8197115,0.538916865 L83.8197115,248.440675 M109.060096,0.538916865 L109.060096,248.440675 M134.300481,0.538916865 L134.300481,248.440675 M158.939904,0.538916865 L158.939904,248.440675 M184.180288,0.538916865 L184.180288,248.440675 M208.819712,0.538916865 L208.819712,248.440675 M234.060096,0.538916865 L234.060096,248.440675 M258.699519,1.55932503 L258.699519,249.461083"
                        stroke="#A3DCFF"
                        strokeLineCap="square"
                        strokeDasharray="4"
                    ></Path>
                </G>
                {/* 箭头 */}
                <G>
                    <G>
                        <G transform="translate(10, 278) rotate(-90)">
                            <Path
                                d="M258,-1.8369702e-15 L278,12 L258,24 L258,18 L0,18 L0,6 L258,6 L258,-1.8369702e-15 Z"
                                fill="url(#linearGradient-1)"
                            ></Path>
                        </G>
                        <Text
                            style={{
                                ...cStyles.labelText,
                                fontSize: 8,
                                ...commonStyles.strongText,
                            }}
                            fill="#FFFFFF"
                        >
                            <Tspan x="18" y="117">
                                人
                            </Tspan>
                            <Tspan x="18" y="129">
                                际
                            </Tspan>
                            <Tspan x="18" y="141">
                                交
                            </Tspan>
                            <Tspan x="18" y="153">
                                往
                            </Tspan>
                            <Tspan x="18" y="165">
                                能
                            </Tspan>
                            <Tspan x="18" y="177">
                                力
                            </Tspan>
                        </Text>
                    </G>
                    <G>
                        <G transform="translate(16, 260)">
                            <Path
                                d="M287,-1.8369702e-15 L307,12 L287,24 L287,18 L0,18 L0,6 L287,6 L287,-1.8369702e-15 Z"
                                fill="url(#linearGradient-1)"
                            ></Path>
                        </G>
                        <Text
                            style={{
                                ...cStyles.labelText,
                                fontSize: 8,
                                ...commonStyles.strongText,
                            }}
                            fill="#FFFFFF"
                        >
                            <Tspan x="128.56" y="275">
                                {addBlank(`完成任务能力`)}
                            </Tspan>
                        </Text>
                    </G>
                </G>
                <G>
                    <G transform="translate(25.000000, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-0.5" y="13">
                                0
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                    <G transform="translate(49.697619, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-0.36375558" y="13">
                                1
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                    <G transform="translate(74.997619, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-0.992173549" y="13">
                                2
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                    <G transform="translate(99.695238, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-1.0976423" y="13">
                                3
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                    <G transform="translate(124.995238, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-1.17234933" y="13">
                                4
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                    <G transform="translate(149.692857, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-1.05809152" y="13">
                                5
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                    <G transform="translate(174.390476, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-1.14158761" y="13">
                                6
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                    <G transform="translate(199.690476, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-0.838364955" y="13">
                                7
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                    <G transform="translate(224.388095, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-1.15037667" y="13">
                                8
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                    <G transform="translate(249.688095, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-1.14158761" y="13">
                                9
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                    <G transform="translate(274.385714, 278.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-3.28172433" y="13">
                                10
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M1.7047619 0 L1.7047619 4" />
                    </G>
                </G>
                <G>
                    <G transform="translate(0.000000, 262.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="2" y="8">
                                0
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                    <G transform="translate(0.000000, 245.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="2.5" y="8">
                                1
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                    <G transform="translate(0.000000, 220.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="2" y="8">
                                2
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                    <G transform="translate(0.000000, 195.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="2" y="8">
                                3
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                    <G transform="translate(0.000000, 170.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="2" y="8">
                                4
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                    <G transform="translate(0.000000, 145.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="2" y="8">
                                5
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                    <G transform="translate(0.000000, 121.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="2" y="8">
                                6
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                    <G transform="translate(0.000000, 96.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="2" y="8">
                                7
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                    <G transform="translate(0.000000, 72.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="2" y="8">
                                8
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                    <G transform="translate(0.000000, 48.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="2" y="8">
                                9
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                    <G transform="translate(0.000000, 22.500000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0" y="8">
                                10
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M12 5 L16 5" />
                    </G>
                </G>
                {/* 星星位置 */}
                <G transform="translate(17.000000, 27.000000)">
                    <G transform={`translate(${startPos.x}, ${startPos.y})`}>
                        <Rect
                            x={0}
                            y={0}
                            width={64}
                            height={14}
                            // fill="yellow"
                        ></Rect>
                        <Text
                            style={cStyles.value}
                            fill="#000000"
                            fillOpacity="0.87"
                            textAnchor={startPos.textAnchor as any}
                        >
                            <Tspan x={startPos.tSpanX} y="11">
                                {`${xPoint}, ${yPoint}`}
                            </Tspan>
                        </Text>
                        <G transform="translate(0.000000, 1.000000)">
                            <Path
                                d="M5.66739483,1.69444294 C5.5473688,1.75925931 5.44889583,1.85773228 5.38407946,1.97775831 L4.33812016,3.9146508 C4.26539273,4.04932642 4.13498693,4.14327254 3.98421198,4.16961109 L1.9360117,4.52740674 C1.55517942,4.5939335 1.30038445,4.95658967 1.36691121,5.33742195 C1.3916638,5.47911805 1.45946729,5.6097487 1.56109715,5.71154186 L3.08425759,7.23714956 C3.20385603,7.35694016 3.25472443,7.52910852 3.2194331,7.69466243 L2.76913118,9.80705818 C2.68853032,10.1851621 2.92970417,10.5570157 3.30780805,10.6376166 C3.47560105,10.6733852 3.65068708,10.6462876 3.79981723,10.5614697 L5.75281516,9.45069989 C5.90608243,9.36352895 6.09393261,9.36352918 6.24719967,9.45070048 L8.20018429,10.5614689 C8.53623295,10.752598 8.96359497,10.6351175 9.15472409,10.2990689 C9.23954209,10.1499394 9.26664021,9.97485417 9.23087252,9.80706159 L8.78058068,7.69466228 C8.74529035,7.52910905 8.79615888,7.35694179 8.91575671,7.23715181 L10.4389194,5.71154186 C10.7120669,5.43795551 10.7117111,4.99474016 10.4381248,4.72159268 C10.3363316,4.61996282 10.2057009,4.55215932 10.0640048,4.52740674 L8.01580456,4.16961109 C7.86502962,4.14327254 7.73462382,4.04932642 7.66189638,3.9146508 L6.61593708,1.97775831 C6.44372084,1.65885073 6.0597062,1.52743448 5.73208722,1.66361512 L5.66739483,1.69444294 Z"
                                fill="#FE8C00"
                            ></Path>
                        </G>
                    </G>
                </G>
            </Svg>
        </View>
    );
}

export default ChartWorkingCompetence;
