import React, { useState } from "react";

import classNames from "classnames";
import type { FormItemProps } from "ysd-pp";
import { Form, Icon, Input } from "ysd-pp";
import type { FormInstance } from "ysd-pp/es/form";

import "./FieldPassword.less";

interface FieldPasswordProps extends FormItemProps {
    prefixIcon?: React.ReactNode;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    visibilityToggle?: boolean;
}
function FieldPassword(props: FieldPasswordProps) {
    const {
        className,
        label,
        onFocus,
        onBlur,
        placeholder,
        prefixIcon,
        onKeyDown,
        visibilityToggle,
        ...restProps
    } = props;
    return (
        <Form.Item
            label={label}
            className={className}
            name="password"
            validateFirst
            rules={[
                {
                    required: true,
                    message: "请输入密码",
                },
                {
                    min: 8,
                    message: "密码长度不能小于8位",
                },
                {
                    max: 127,
                    message: "密码长度不能大于127位",
                },
            ]}
            {...restProps}
        >
            <Input.Password
                prefix={
                    prefixIcon === null || prefixIcon === false
                        ? null
                        : prefixIcon || (
                              <Icon
                                  type="LockIcon"
                                  className="password-lock-icon "
                              />
                          )
                }
                placeholder={placeholder || "请输入密码"}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete="new-password"
                onKeyDown={onKeyDown}
                visibilityToggle={visibilityToggle}
            />
        </Form.Item>
    );
}

interface FieldResetPasswordProps extends FormItemProps {
    prefixIcon?: React.ReactNode;
    placeholder?: string;
    form?: FormInstance;
    onKeyDown?: () => void;
    visibilityToggle?: boolean;
}
export function FieldResetPassword(props: FieldResetPasswordProps) {
    const {
        form,
        name = "new-password",
        prefixIcon,
        onKeyDown,
        visibilityToggle,
        ...restProps
    } = props;

    const [isEmpty, setIsEmpty] = useState<boolean>(true);

    return (
        <FieldPassword
            prefixIcon={prefixIcon}
            name={name}
            // 这里的 'new-password' className 是样式处理 以及 getTooltipContainer 用的
            className={classNames([
                "with-rules-status",
                "new-password",
                { empty: isEmpty },
            ])}
            visibilityToggle={visibilityToggle}
            //   rules={rules}
            rules={[
                {
                    required: true,
                },
                // ...passwordCheckers(),
                // 1.字符长度8-127
                {
                    validator: (_rule, value) => {
                        if (value.length < 8 || value.length > 127) {
                            return Promise.reject("字符长度8-127");
                        }
                        return Promise.resolve();
                    },
                },
                // 2. 至少包含一位数宇
                {
                    validator: (_rule, value) => {
                        if (!/\d/.test(value)) {
                            return Promise.reject("至少包含一位数字");
                        }
                        return Promise.resolve();
                    },
                },
                // 3. 至少包含一个小写宇母
                {
                    validator: (_rule, value) => {
                        if (!/[a-z]/.test(value)) {
                            return Promise.reject("至少包含一个小写字母");
                        }
                        return Promise.resolve();
                    },
                },
                // 4. 至少包含一个大写字母或者一个特殊字符（@_#-!%）
                {
                    validator: (_rule, value) => {
                        if (!/[A-Z@_#\-!%]/.test(value)) {
                            return Promise.reject(
                                "至少包含一个大写字母或者一个特殊字符（@_#-!%）"
                            );
                        }
                        return Promise.resolve();
                    },
                },
                // 5. 不允许设置4位连续数字 （如：1234,4321）
                {
                    validator: (_rule, value) => {
                        if (
                            /(0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)){3}\d|(9(?=8)|8(?=7)|7(?=6)|6(?=5)|5(?=4)|4(?=3)|3(?=2)|2(?=1)|1(?=0)){3}\d/.test(
                                value
                            )
                        ) {
                            return Promise.reject("无连续数字");
                        }
                        return Promise.resolve();
                    },
                },
                // 6. 不允许重复出现3个相同字符（如：数字-222、字符-%%%、字母-AAA）
                {
                    validator: (_rule, value) => {
                        if (/([0-9a-zA-Z@_#\-!%])\1{2}/.test(value)) {
                            return Promise.reject(
                                "不允许重复出现3个相同字符（如：数字-222、字符-%%%、字母-AAA）"
                            );
                        }
                        return Promise.resolve();
                    },
                },
            ]}
            onChange={(e) => {
                setIsEmpty(!e.target.value);
                // setTooltipVisible(!!e.target.value);
            }}
            onFocus={(e) => {
                if (e.target.value) {
                    form?.validateFields([name]);
                    setIsEmpty(false);
                    //   setTooltipVisible(true);
                } else {
                    setIsEmpty(true);
                }
            }}
            onBlur={(e) => {
                setIsEmpty(!e.target.value);
                // setTooltipVisible(false);
            }}
            onKeyDown={onKeyDown}
            validateFirst={false}
            {...restProps}
        />
    );
}

export default FieldPassword;
