import { Dropdown, Icon, Menu, Tabs, message } from 'ysd-pp';
import styles from './index.module.less';

import AppraisalsList from './components/AppraisalsList';
import UserList from './components/UserList';
import ChangePassword from '../user/components/ChangePassword';
import { useEffect, useState } from 'react';
import { useRequest } from '@/utils/request';
import { clearToken } from '@/utils/getToken';
import { useNavigate } from 'react-router-dom';
import { getUserInfo, logout } from '@/services/users';
import { useConfirm } from '@/utils/hooks';
import { useModel } from '@/components/useModel';
import { hasPermissions } from '@/utils/permissions/index';
import JobRulesList from './components/JobRulesList';
// import type { TablePaginationConfig } from "ysd-pp";
const Home = () => {
    const [visible, setVisible] = useState(false);
    const { loading: logoutLoading, runAsync: doLogout } = useRequest(logout);
    const logoutConfirm = useConfirm(logoutLoading);
    const navigate = useNavigate();
    const { userInfo } = useModel('global');
    const { setUserInfo } = useModel('global', model => ({
        setUserInfo: model.setUserInfo,
    }));
    const { runAsync: runUserInfo } = useRequest(getUserInfo);
    useEffect(() => {
        runUserInfo().then(res => {
            if (res?.code === 200) {
                setUserInfo(res?.data);
                // localStorage.setItem("permissions", res?.data?.authority_list as any);
                localStorage.setItem('permissions', JSON.stringify(res?.data?.authority_list || []));
            } else {
                message.error(res?.message);
            }
        });
    }, []);
    const loginOut = () => {
        logoutConfirm({
            title: '退出登录',
            content: '你确认要退出吗？',
            okText: '确认',
            onOk: () => {
                doLogout().then((res: any) => {
                    if (res?.code === 200) {
                        clearToken();
                        // 跳转到登录页面
                        navigate('/user/login');
                    } else if (res?.message) {
                        message.error(res?.message);
                    }
                });
            },
        });
    };
    const handleMenuClick = (e: any) => {
        if (e.key === 'reset') {
            setVisible(true);
        } else if (e.key === 'loginOut') {
            loginOut();
        }
    };
    const userMenu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="reset">
                <a>重置密码</a>
            </Menu.Item>
            <Menu.Item key="loginOut">
                <a>退出登录</a>
            </Menu.Item>
        </Menu>
    );

    const tabs = [
        {
            title: '性格测评',
            key: 'entrance',
            component: AppraisalsList,
        },
        {
            title: '岗位规则',
            key: 'station',
            component: JobRulesList,
        },
        {
            title: '用户管理',
            key: 'user',
            component: UserList,
        },
    ];

    const renderTab = () => {
        // 过滤出有权限的tab
        const permissionTabs = tabs.filter(tab => hasPermissions(tab.key));
        if (permissionTabs.length === 0) return null;
        const [FirstPermissionTab] = permissionTabs;
        if (permissionTabs.length === 1) return <FirstPermissionTab.component noTabs={true} />;
        return (
            <Tabs defaultActiveKey={FirstPermissionTab.key} destroyInactiveTabPane>
                {permissionTabs.map(tab => (
                    <Tabs.TabPane tab={tab.title} key={tab.key}>
                        <tab.component />
                    </Tabs.TabPane>
                ))}
            </Tabs>
        );
    };

    return (
        <div className={styles['appraisals-layout']}>
            <div className={styles['header-container']}>
                <div className={styles['header-title']}>{userInfo?.name}，你好</div>
                <Dropdown overlay={userMenu}>
                    <Icon type="setting" className={styles['setting-icon']} />
                </Dropdown>
                <ChangePassword visible={visible} onVisibleChange={setVisible} />
            </div>
            <div className={styles.container}>{renderTab()}</div>
        </div>
    );
};

export default Home;
