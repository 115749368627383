export const allData = {
    ISTJ: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ISTJ',
            features: '物流师，最擅长检查和监督管理的类型',
            representation: '普京',
        },
        personality:
            'XXX严肃，安静，通过全神贯注和细致周到而赢得成功。能够引证准确的事实支持自己的观点，把过去的经历运用到现在的决策中。重视和利用符合逻辑、客观的分析，以坚持不懈的态度准时地完成工作，并且总是安排有序，很有条理。',
        strengthsAndBlindSpots: {
            strengths: [
                '严肃的、有责任心的和通情达理的；',
                '工作缜密，讲求实际，很有头脑也很现实；',
                '特别安静和勤奋，对于细节有很强的记忆和判断；',
                '遇事临危不乱，并能冷静处理。',
            ],
            blindSpots: [
                '对于新观念新想法，易固执己见；',
                '有时很难理解其他人的需求，尤其是那些与自身差异很大的需求；',
                '喜欢规则明确的工作，因此规则改变或新事物出现可能会引起不适。',
            ],
        },
        rightCareers:
            '那些能使他们在组织和准确性方面的才能得以发挥的职业。如：会计、建筑工程、律师、电影制片、建筑设计、卫生健康及事务性工作，在那里他们常常充当监督与管理的角色。',
        rightEnv: [
            '组织成员工作努力并且关注事实和结果；',
            '工作能够提供一定的安全感和稳定性，并能考虑个人隐私；',
            '领导认可和赞赏稳健的工作方式；',
            '结构化的、有序的工作取向。',
        ],
        potentialObstacle: [
            '忽略日常工作的长远影响；',
            '不注意微妙的人际信息；',
            '有时表现得不灵活，僵化；',
            '更期望他人循规蹈矩，而不鼓励创新。',
        ],
        developmentSuggestions: [
            '除了眼前的现实，需要关注问题的更广泛的枝节；',
            '需要考虑人的因素；',
            '需要尝试新的东西以避免墨守陈规；',
            '需要对那些不太在意规则而努力创新的人保持足够耐心。',
        ],
    },
    ISFJ: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ISFJ',
            features: '守护者，最擅长提供保护的守护者类型',
            representation: '约翰·H·华生',
        },
        personality:
            'XXX安静，友好，有责任感。周到、刻苦、准确，工作投入，力图满足职责要求，热心为朋友和群体服务。尽管其兴趣并不常在技术领域，但愿意花时间去掌握技术方面的知识和技能。对细节和常规工作有耐心。忠诚，体贴为他人着想，关心他人的感受。',
        strengthsAndBlindSpots: {
            strengths: [
                '平和谦虚、勤奋严肃；',
                '有奉献精神，喜欢实际可行地帮助他人；',
                '忠诚体贴、有同情心、有责任心；',
                '十分务实，对于细节则有很强的记忆力。',
            ],
            blindSpots: [
                '很难全面地观察问题和预见可能性结果；',
                '过于谦虚和害羞，拒绝表达自己的想法；',
                '过分利他主义，压抑自己感受，导致时常有压力和挫败感。',
            ],
        },
        rightCareers:
            '需要细致的观察和对人的关怀的职业，例如：教育、办公室工作以及提供服务或人际关怀的职业或者从事监督的工作。',
        rightEnv: ['提供安全感；', '工作结构清晰；', '安静平和；', '讲效率；', '考虑隐私权；', '服务导向。'],
        potentialObstacle: [
            '对未来过分悲观；',
            '在对人表达其观点时，易被视为不够强硬；',
            '僵化、不太灵活；',
            '由于总是避免出风头，其作用被低估。',
        ],
        developmentSuggestions: [
            '在寻求未来工作上需要以积极、全局的态度处之；',
            '需要培养自己的决断性和沟通过程中的直率性；',
            '需要学会积极面对外界；',
            '需要以更加开放的态度对待他人的做事方式。',
        ],
    },
    INFJ: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'INFJ',
            features: '精神导师，最擅长引导指引，遵循道德的类型',
            representation: '马丁·路德·金',
        },
        personality:
            'XXX独立的、有独创性的提倡者。具有本能的洞察力，能够看到事物更深层的含义。喜欢运用嘉许和赞扬说服别人，使他人相信自己的观点是正确的。会注意其他人的情感和利益，能够很好地处理复杂事情。并且在行动之前通常要专心、仔细地考虑，是有思考的决策者。',
        strengthsAndBlindSpots: {
            strengths: [
                '具有强烈的感情、坚定的原则和正直的人性；',
                '忠诚、坚定、正直，十分坚定以至达到倔强的地步；',
                '习惯运用嘉许和赞扬，赢得与他人的合作；',
                '满怀热情与同情心，强烈地渴望为他人的幸福做贡献。',
            ],
            blindSpots: [
                '有时不切实际，会忽视需要注意的常规细节；',
                '对于自己的原则过于坚定，以至于发展到眼光狭窄的地步；',
                '经常是完美主义者，会对批评过于敏感。',
            ],
        },
        rightCareers:
            '对于那种能满足INFJ人的直觉的情感性工作最为满意。教育对他们极有吸引力，不论是高等教育，还是通过艺术或行政教育，他们对能帮助学生个体的发展极为满意。当这一类型的人兴趣在于技术领域时，这种人可能在科学、研究和发展方面表现杰出。',
        rightEnv: [
            '关注于理想的同事；',
            '存在创造的机会；',
            '和谐并协调；',
            '安静，允许有仔细斟酌的时间和空间；',
            '组织化的。',
        ],
        potentialObstacle: [
            '能够发现新的思想，但可能不被重视或被低估；',
            '对于批评不太直率；',
            '不愿意强迫他人而自己承受过多；',
            '一根筋，无视其它需要做的事情。',
        ],
        developmentSuggestions: [
            '需要把握时机果断决策；',
            '需要学会适当的给人以建设性的反馈；',
            '需要学会将自己的观点或态度和他人一起分享或探讨；',
            '需要放松，对于目前情况下能够完成的事情，应当报有更开放的态度。',
        ],
    },
    INTJ: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'INTJ',
            features: '军师，最擅长制定计划和预测未来的类型',
            representation: '马克思',
        },
        personality:
            'XXX天生的谋略家，具有独特的思想、远见和梦想。思维严谨、有逻辑性、足智多谋，通常能清楚地看到局势的利处和缺陷。强烈地要求个人自由和能力，更喜欢以自己的方式行事。依靠超过或达到自己高标准的决心获得许多成就，也是完美主义者。',
        strengthsAndBlindSpots: {
            strengths: [
                '完美主义者，强烈的内驱力；',
                '思维严谨、有逻辑性、足智多谋；',
                '精于理论，对于复杂而综合的概念运转灵活；',
                '善于分析，能清楚地看到局势的利处和缺陷；',
                '对于感兴趣的问题，他们是很好的、具有远见和见解的组织者。',
            ],
            blindSpots: [
                '对自己和他人的期望过多；',
                '过多地重视对未来的见解和想法，易忽略现在的重要事情和现实；',
                '在工作中常常选择独立，忽视了在活动中邀请别人参加或协助。',
            ],
        },
        rightCareers:
            '适合工商业领域、政府机构金融银行业、政府机构技术领域、医务领域——审计师、会计、财务经理、办公室行政管理、公务(法律、税务)执行人员、税务经纪人、计算机程序员、数据库管理员、地质、气象学家、法律研究者、律师、外科医生、药剂师、实验室技术人员、牙科医生、医学研究员等。',
        rightEnv: [
            '同事贯彻长期规划、果断和喜欢挑战；',
            '给予反思的权利；',
            '有效率；',
            '鼓励和支持自主性；',
            '有创造的机会；',
            '关注任务。',
        ],
        potentialObstacle: [
            '表现得不太让步以致他人害怕接近；',
            '对自己和他人要求严格，可能忽视他人的情绪感受；',
            '难以忍受让不合实际的思想蔓延；',
            '易忽视自己的思想和行动风格对别人的影响。',
        ],
        developmentSuggestions: [
            '需要学会听取反馈和建议；',
            '需要学会欣赏他人；',
            '需要学会放弃不实际的想法；',
            '需要更加关注自己思想或行动对他人的影响。',
        ],
    },
    ISTP: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ISTP',
            features: '探险家，最擅长机械和实操相关技能的类型',
            representation: '贝尔·格里尔斯',
        },
        personality:
            'XXX冷静的旁观者，谦虚而寡言，往往显得冷酷而清高，以超然的好奇心、求知欲观察和分析生活，时而会出人意料的独特的幽默言行。通常对客观规律、机械事物的工作原理、因果关系感兴趣。因为ISTP型的人认为浪费精力是缺乏效率的，所以只有在ISTP型人认为必要的时候才会表达意见或发挥自己的影响。',
        strengthsAndBlindSpots: {
            strengths: [
                '坦率、诚实、讲求实效，喜欢行动而非漫谈；',
                '擅长分析，对于技巧性的事物有天生的理解力；',
                '好奇心强，善于观察，重视事实的现实主义者；',
                '平和而寡言，喜欢行动和兴奋的事情，乐于户外活动和运动。',
            ],
            blindSpots: [
                '过分的关注眼下的结果，以致于忽略了自己的决策和行动的长远影响；',
                '总是独立分析，独自判断，使得周围的人行动或配合起来比较被动；',
                '对所有的选择都持开放态度，所以不善于做决定；',
                '无视自己的情感和需要，忽视他人的感受。',
            ],
        },
        rightCareers:
            '适合技术领域证券、金融业贸易、商业领域户外、运动、艺术等领域——机械、电气各类技术专家和技师、计算机硬件、证券分析师、金融、财务顾问、贸易商、商品经销商、警察、侦探、体育工作者、飞行员、雕塑家等。',
        rightEnv: [
            '环境中的各种问题需要以行动予以解决；',
            '同事行动导向；',
            '不受规则限制；',
            '提供许多新的立即要解决的任务；',
            '培养独立性的环境。',
        ],
        potentialObstacle: [
            '只关注重要的事，而对他人似乎关心不够；',
            '缺乏一定的坚持性，在已付出的努力结出硕果之前就已经另谋他途了；',
            '过于权宜，走捷径，少付出；',
            '可能优柔寡断和漫无目的。',
        ],
        developmentSuggestions: [
            '需要深入与他人交流沟通；',
            '需要发展坚毅力，为达到期望结果需要作必要的计划并付出必要的努力；',
            '需要形成设立目标的习惯。',
        ],
    },
    ISFP: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ISFP',
            features: '艺术家，富有艺术天赋和审美感的类型',
            representation: '迈克尔·杰克逊',
        },
        personality:
            'XXX安宁和善亲切，且行事谦虚。喜于避开争论，不对他人强加已见或价值观。无意于领导却常是忠诚的追随者。办事不急躁，安于现状，且非成果导向。对于从经历中直接了解和感受的东西很感兴趣，常常富有艺术天赋和审美感，力求为自己创造一个美丽而隐蔽的环境。',
        strengthsAndBlindSpots: {
            strengths: [
                '能注意和记住琐碎细节；',
                '有耐心，办事不急躁，能耐得住烦闷的工作；',
                '灵活，友善，很容易与他人相处，对自己的能力保持谦虚的态度；',
                '很有艺术天份，对自然的美丽情有独钟。',
            ],
            blindSpots: [
                '完全着眼于现在，不善于为将来做打算；',
                '过分忽视事物之间的内在联系和逻辑思考，难以理解复杂的事情；',
                '对他人批评会感到生气和气馁，有时容易过分自责。',
            ],
        },
        rightCareers:
            '适合手工艺、艺术领域医护领域商业、服务业领域——时装、首饰设计师、园艺设计师、陶器、乐器、卡通、漫画制作者、舞蹈演员、画家、医生、护士、运动教练、餐饮业、导游、体育用品或个人理疗用品销售员等。',
        rightEnv: ['氛围默默地积极工作和合作的；', '工作时间具有灵活性；', '照顾个人的隐私空间；', '同事有礼貌。'],
        potentialObstacle: [
            '过于轻信别人并容易上当；',
            '即使在必要时也不批评他人，而只是过分地责难自己；',
            '没有看到现实背后的东西，不能全方位地审时度势；',
            '太容易受伤害和退缩。',
        ],
        developmentSuggestions: [
            '需要发展怀疑和分析信息的方法，而不是一味接受；',
            '需要学会在他人自鸣得意时作出否定性反馈；',
            '需要更果敢和更直接地对待他人。',
        ],
    },
    INFP: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'INFP',
            features: '治愈者，最擅长安抚人心和价值判定的类型',
            representation: '莎士比亚',
        },
        personality:
            'XXX安静的观察者。敏感，理想化，把内在的和谐视为高于其他一切。对于个人价值具有强烈的荣誉感，个人信仰坚定，有为自认为有价值的事业献身的精神。对于已知事物之外的可能性很感兴趣，精力集中于梦想和想象。思维开阔，有好奇心和洞察力。在日常事务中，通常灵活变通、具有忍耐力和适应性。',
        strengthsAndBlindSpots: {
            strengths: [
                '渴望打破常规思考，并考虑新的可能情况；',
                '对收集所需信息有一种天生的好奇与技巧；',
                '能通观全局以及看到意识与行为之间的联系；',
                '能洞察别人的需要与动机；',
                '适应能力强，能很快改变自己的行事速度及目标。',
            ],
            blindSpots: [
                '追求完美，会花很长时间酝酿自己的想法，难以用适当的方式来表达自己；',
                '经常忽略逻辑和具体现实，沉浸于梦想；',
                '非常固执，经常局限在自己的想法里；',
                '总是用高标准要求自己，导致对批评相当敏感。',
            ],
        },
        rightCareers:
            '适合创作、艺术类教育、研究、咨询领域——各类艺术家、插图画家、诗人、小说家、建筑师、设计师、文学编辑、艺术指导、记者、大学老师(人文类)、心理学工作者、社科类研究人员、社会工作者、教育顾问、图书管理者、翻译家等。',
        rightEnv: [
            '同事关注他人价值、和蔼可亲并讲信用；',
            '合作气氛；',
            '考虑个人的隐私权；',
            '安定而平静，给予反思的时间和空间。',
        ],
        potentialObstacle: [
            '由于追求完美主义，可能会拖延工作任务；',
            '同时取悦于太多的人；',
            '固执地不按照逻辑和事实调整自己的看法；',
            '花费太多的时间反复琢磨而不采取行动。',
        ],
        developmentSuggestions: [
            '需要学会现实地工作而不光是追求完美；',
            '需要发展坚韧、讲究实际和自觉行动；',
            '需要强调和重视事实和逻辑；',
            '需要制定和实施行动计划。',
        ],
    },
    INTP: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'INTP',
            features: '科学家，最擅长理论和思想系统构建的类型',
            representation: '牛顿',
        },
        personality:
            'XXX平静、缄默、超然世俗，但内心却专心致志于分析问题，努力寻找和利用原则以理解许多想法。注重逻辑，喜欢有条理和目的的交谈而不是闲聊，是足智多谋、有独立见解的思考者。灵活易变、思维开阔，喜欢冒险和富有想象力的活动。',
        strengthsAndBlindSpots: {
            strengths: [
                '能够理解非常复杂和高度抽象的概念；',
                '独立自主，有创造意识及克服困难的勇气；',
                '即使在压力很大的情况下，也能逻辑地分析事物；',
                '学习新知识的信心和动力都很大；',
                '能够客观地分析和处理问题，而不是感情用事。',
            ],
            blindSpots: [
                '过分依赖条理性分析，易忽视情感和现实；',
                '精通于观察，但对于表达自己的评价却沉默寡言；',
                '喜爱解决问题，却会对常规的细节缺少耐心；',
                '不喜欢按传统的、公式化的方式来办事；',
                '可能过于理论化，而忽视或无视现实性问题。',
            ],
        },
        rightCareers:
            '大多数这类型的人喜欢在技术领域工作，运用自己独特的能力来分析复杂的系统，形成富有创意的解决办法——软件设计师、电脑工程师、研究开发专业人员、战略规划、金融规划师、分析人员等。',
        rightEnv: [
            '需要有解决复杂问题的独立思考者；',
            '允许隐私权；',
            '培养独立性；',
            '灵活、安静、和谐的工作氛围；',
            '奖励自主。',
        ],
        potentialObstacle: [
            '太过抽象以至于实施起来不现实；',
            '太过理智，其解释过分理论化；',
            '过分关注微小的不一致，而以团队工作的和谐为代价；',
            '将批评性分析思考转向于人，行动起来没有人情味。',
        ],
        developmentSuggestions: [
            '需要关注实际的细节，发展坚定的实施能力；',
            '需要花力气将事情说得更简单些；',
            '需要对他人给予的信息表示欣赏；',
            '需要更多地了解他人以及他人的职业。',
        ],
    },
    ESTP: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ESTP',
            features: '实践者，活在当下，最擅长说服推进的类型',
            representation: '唐纳德·特朗普',
        },
        personality:
            'XXX直率、关注事实，不急躁，随遇而安。在大多数的社交场合中，都富有魅力、轻松自如而受人欢迎。是好奇而热心的观察者，能够在符合逻辑的基础上理解晦涩的原则，然后找到符合逻辑的明智的解决途径。此类型人员乐于享受现在的一切而不是为将来计划什么。',
        strengthsAndBlindSpots: {
            strengths: [
                '精力充沛，充满活力；',
                '活在当下，随遇而安，乐于享受现在；',
                '友善、善于交际、富有魅力，可以轻易地受人欢迎；',
                '喜欢行动而不是漫谈，当问题出现时，乐于去处理，是优秀的解决问题的人。',
            ],
            blindSpots: [
                '喜欢处理紧急情况，不愿意制定预防计划；',
                '注意力完全集中在有趣的活动上；',
                '喜欢接受新挑战，不愿意在沉闷的工作中消磨时间；',
                '当情况环境转变时，很容易忽视他人的情感，变得迟钝和鲁莽。',
            ],
        },
        rightCareers:
            '在需要现实主义、行动和适应性的职业中最为出色，例如：工程、警察工作、信用调查、市场、健康技术、建筑、制造、娱乐、饮食服务，以及许多调解性的工作。',
        rightEnv: [
            '需要工作环境中存在注重第一手经验的、结果取向的人；',
            '允许有娱乐时间；',
            '工作具有灵活性；',
            '技术导向；',
            '不官僚。',
        ],
        potentialObstacle: [
            '在迅速行动时对他人很生硬、率直和不敏感；',
            '过于依赖即兴发挥，而不注意其行动可能产生的影响；',
            '有时为突发问题而牺牲原有计划；',
            '沉溺于物欲。',
        ],
        developmentSuggestions: [
            '需要抑制独断而忽视他人感情的方面；',
            '需要在迅速决定之前，事先计划，考虑细节，三思而行；',
            '做事情尤其是需要时间周期比较长的事情，需要持之以恒；',
            '需要注意物质享受以外的东西。',
        ],
    },
    ESFP: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ESFP',
            features: '乐天派，最擅长娱乐和活跃气氛的类型',
            representation: '玛丽莲·梦露',
        },
        personality:
            'XXX脾气随和、适应性强，热情友好和慷慨大方。擅长交际，乐意与人相处，有真正的生活热情。他们往往信任自己能够听到、闻到、触摸和看到的事物，而不是依赖于理论上的解释。喜欢意料不到的事情，喜欢寻找给他人带来愉快和意外惊喜的方法。',
        strengthsAndBlindSpots: {
            strengths: [
                '精力充沛，喜欢合作，是位有协作精神的团队成员；',
                '热爱生活，喜欢创造生机勃勃、充满乐趣的气氛；',
                '善于观察，对于事物细节有很好的记忆力；',
                '忠实于关心的人和组织；',
                '愿意冒险，尝试新事物。',
            ],
            blindSpots: [
                '做事容易拖拉，难以约束自己；',
                '经常易忽视理论思考和逻辑分析，忙碌于于具体事情，难以制定计划；',
                '难以独自工作，尤其是要持续一段时间的独立工作；',
                '将失败当作针对个人的批评和负面回应；',
                '如果涉及到个人感情，就难以做出有逻辑的决定。',
            ],
        },
        rightCareers:
            '在需要现实主义、行动和适应性的工作中表现最好。例如：健康服务、销售、策划、运输、娱乐、秘书或办公室工作，饮食服务，工作组的监督，机器操作，以及许多调解性的工作。',
        rightEnv: [
            '关注目前现实、精力旺盛并易于相处的同事；',
            '氛围活泼；',
            '行动导向；',
            '有适应能力的人；',
            '人员密集的。',
        ],
        potentialObstacle: [
            '可能过分强调主观资料，而在投入之前并没有仔细考虑和提前准备；',
            '可能花费太多的时间于人际交往而忽略工作任务；',
            '有些虎头蛇尾。',
        ],
        developmentSuggestions: [
            '在做决定时，需要思考事务之间的逻辑关系；',
            '在管理项目之前，需要事先计划；',
            '需要平衡工作努力和社交活动；',
            '需要在时间管理上下功夫，做好时间管理。',
        ],
    },
    ENFP: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ENFP',
            features: '追梦人，最擅长鼓舞激励人心和追逐梦想的类型',
            representation: '比尔·盖茨',
        },
        personality:
            'XXX热情洋溢，情绪高昂，喜欢保持一种广泛的人际关系。一个想法可以改变世界，富有想象力，几乎能达成所有有兴趣的事。具有出色的洞察力和观察力，能注意常规以外的事物，能够找到解决问题的办法并能对有困难的人施予援手。依赖能改善的能力而无须提前作规划准备。',
        strengthsAndBlindSpots: {
            strengths: [
                '充满热情，平易近人，具有利他精神和友好性；',
                '愿意走出舒适区，寻找新的想法和体验；',
                '具有想象力、适应性和可变性，不墨守成规，善于发觉做事情的新方法；',
                '具有魅力、充满生机，富有同情心，愿意帮助别人解决问题。',
            ],
            blindSpots: [
                '不善于把握事情的轻重，难于决定优先处理哪些事；',
                '不愿以传统或常规的方式行事；',
                '易于烦躁或不耐烦，尤其是当创造性过程结束后；',
                '讨厌做重复性任务；',
                '不能容忍与过于严谨的机构或个人工作。',
            ],
        },
        rightCareers:
            '可以是富有灵感和鼓舞人心的教师。凭着天赋，他们能在几乎任何吸引他们兴趣的领域获得成功──艺术、新闻、科学、广告、销售、服务或写作等。',
        rightEnv: [
            '富有想象力、关注可能性的同事；',
            '富于色彩或多样的环境；',
            '参与气氛营造；',
            '提供变化和挑战；',
            '观念导向；',
            '不受限制。',
        ],
        potentialObstacle: [
            '在一个项目尚未完成的情况下就转向了新的计划或想法；',
            '很容易忽视有关细节；',
            '容易做事拖拉；',
            '过分扩张，尝试太多的事情。',
        ],
        developmentSuggestions: [
            '需要设立优先级，考虑轻重缓急，发展持之以恒；',
            '需要关注重要的细节；',
            '需要学会审查计划或规划，而不是尝试去做所有看起来有吸引力的事情；',
            '需要学会并运用时间管理技能。',
        ],
    },
    ENTP: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ENTP',
            features: '辩论家，最擅长创新和足智多谋的类型',
            representation: '马克·吐温',
        },
        personality:
            'XXX行动迅速，聪明灵巧，擅长于多样事务，常激励同伴，喜欢提升能力和个人权力。有极好的分析能力，会为了有趣对问题的两面加予争辩，是出色的策略谋划者，能够轻易地看出各种情况中的缺点。天生富有想象力，视灵感高于其他的一切，喜欢自在地生活，在每天的生活中寻找快乐和变化。',
        strengthsAndBlindSpots: {
            strengths: [
                '热情开放、足智多谋、健谈而聪明；',
                '天生富有想象力，具有很强的首创精神；',
                '有好奇心，适应性强，在解决挑战性和理论性问题时善于随机心变；',
                '灵活，有极好的分析能力，是出色的策略谋划者。',
            ],
            blindSpots: [
                '行事缺少稳定的计划和流程，经常依靠临场发挥；',
                '注意力容易游移，对目标的坚韧性和坚持性不够；',
                '容易忽略简单、常规的方法和一些重要的细节；',
                '对实际问题失去兴趣，不愿遵守规则和计划，计划很难落实。',
            ],
        },
        rightCareers:
            '不大可能从事不能提供新挑战的职业。凭着天赋，这一类型的人可能是发明家、科学家、记者、调解人、买卖人、推销商、电脑分析员或任何ENTP感兴趣的工作。',
        rightEnv: ['灵活而富于挑战性；', '讲求变化的；', '奖励冒风险的；', '鼓励自主性；', '不官僚。'],
        potentialObstacle: [
            '可能在构想中迷失，忘记了眼前的现实；',
            '对他人的投入视作是对手并不领情；',
            '可能对标准程序和规范很难适应；',
            '可能过分扩张自己，过分强调自己。',
        ],
        developmentSuggestions: [
            '需要注意各个方面的因素和基本的事实；',
            '需要承认他人贡献的有效性；',
            '需要设立现实性的开始与结束的期限，知道何时该结束；',
            '需要学会在组织里如何工作。',
        ],
    },
    ESTJ: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ESTJ',
            features: '总经理，管理者气质，最擅长组织管理的类型',
            representation: '彼得·德鲁克',
        },
        personality:
            'XXX直爽坦率，友善合群。注重实际，关注事实。更感兴趣的是真实的事物，而不是诸如抽象的想法和理论等无形的东西。客观，有条理性和分析能力，以及很强的推理能力。喜欢条理性并且能记住和组织安排许多细节，喜欢组织并管理活动，能高效率地、系统地去完成任务从而达成目标。',
        strengthsAndBlindSpots: {
            strengths: [
                '做事客观、讲求效率，有责任感，善于自我约束；',
                '善于分析，有很强的推理能力和组织能力；',
                '能够自始至终地关注着组织或任务的目标；',
                '办事精确、很少出差错，有把事做好的强烈愿望；',
                '有很好地遵循已经建立起来的工作安排和工作程序的习惯。',
            ],
            blindSpots: [
                '看问题具有很强的批判性，过多关注问题，忽视了人的贡献；',
                '喜欢把自己的标准强加给别人，对自己和他人都要求严格；',
                '遵照逻辑和客观的原则做事，较少考虑自己行为和决定给他人带来的影响；',
                '专注于实施自己细致的计划，容易错过外界的很多变化和信息。',
            ],
        },
        rightCareers:
            '很可能是那种同组织中物质方面的和个人方面细节打交道的职业。可能有志于从事诸如会计、生产、市场调查、计算机程序编制、办公室工作、调度、文稿编辑、起草、设计、统计、人事分析、图书管理、装配和应用科学这样一类职业。',
        rightEnv: [
            '组织化的、结构化的工作环境；',
            '喜欢有工作勤奋努力的同事；',
            '任务导向；',
            '提供稳定性和可预测性；',
            '集中于工作效率；',
            '在实现目标后有及时的支持和奖励。',
        ],
        potentialObstacle: [
            '作决定过快，而可能忽视工作发展的可能性；',
            '工作中忽略细枝末节；',
            '可能忽视工作中的人及情感交流的价值。',
        ],
        developmentSuggestions: [
            '在决策前，需考虑问题的各个方面，包括人的影响因素；',
            '需要督促自己仔细考虑变动所带来的得失；',
            '需要作出特殊的努力以夸赞别人的成绩。',
        ],
    },
    ESFJ: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ESFJ',
            features: '东道主，最擅长照顾关心他人的类型',
            representation: '克林顿',
        },
        personality:
            'XXX认真尽责，合群，热心健谈，是“天生的合作者”。总是善待他人，在受到充分的鼓励和赞扬时表现出色。对抽念的思考和技术性的课题缺乏兴趣，主要兴趣在于那些能直接的、可见地影响人们生活的事情。倾向于在自己的个人经历或在所信赖之人的经验之上制定计划或得出见解。',
        strengthsAndBlindSpots: {
            strengths: [
                '热衷于社交、擅长与人打交道，喜欢与他人共事去完成工作；',
                '重视与别人的关系，善于给他人实际关怀，很容易让人产生依赖感；',
                '有较强的实践能力，是日常任务的优秀管理者；',
                '具有责任感，尽管有时可能更多来自社会期望而不是内在驱动力。',
            ],
            blindSpots: [
                '过分在意别人的情感和想法，总是给与别人额外的关心和帮助；',
                '敏感、担心被忽视，对待批评自己习惯或传统的人，会有防御性；',
                '容易陷入情感和细节中，难以从宏观和客观的角度看待问题；',
                '很难变通，拒绝创新或即兴发挥，习惯根据经验做出决定。',
            ],
        },
        rightCareers:
            '适合卫生保健、教育、社会服务、咨询、商业、营销、服务业、文书等领域——销售代表、零售业主、房地产代理商、特殊教育老师、员工援助顾问（EAP）、体能训练师、护士、理疗师、营销经理、运动教练、口笔译人员、人力资源顾问、商品采购员、公关客户经理等。',
        rightEnv: [
            '同事认真、合作和乐于帮助他人；',
            '团队组织化、目标导向；',
            '有所尊重的同事；',
            '同事有敏感的，善解人意的人；',
            '组织根据事实办事。',
        ],
        potentialObstacle: [
            '回避冲突，只管无关痛痒的事；',
            '由于渴望取悦于他人，将自己组织的优势地位看得很轻；',
            '可能忽视工作发展的可能性和远景；',
            '可能误以为组织成员都能够认识到什么才是组织所需要的。',
        ],
        developmentSuggestions: [
            '需要学会如何看待和管理冲突；',
            '需要努力倾听其他人的愿望和要求；',
            '需要从宏观和客观的角度去看待问题；',
            '需要考虑决策逻辑以及对全局的影响。',
        ],
    },
    ENFJ: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ENFJ',
            features: '教育家，最擅长教导教育他人的类型',
            representation: '奥巴马',
        },
        personality:
            'XXX热忱、爱交际、善于鼓舞、领导他人。能做到在沟通中迅速和对方同频，并非常快速地理解对方需求。喜欢带领别人且帮助别人或团体发挥潜能。会根据个人价值判断做决定，倾向运用情感向周围的人传递充满温暖和活力的力量。天生具有条理性，喜欢有安排的世界。',
        strengthsAndBlindSpots: {
            strengths: [
                '性情平和，心胸宽阔；',
                '热忱、富有同情心、有责任感；',
                '善于社交，处事圆滑，很会促进周边关系的和睦；',
                '喜欢鼓励他人，喜欢带领别人或团体发挥潜能。',
            ],
            blindSpots: [
                '非常理想化，易忽略理想所需要的现实和细节问题；',
                '依照情感行事，习惯根据个人的价值观进行判断；',
                '急于迎接新的挑战，忽视计划中的细节；',
                '对于批评非常脆弱，容易忧虑，失去自信。',
            ],
        },
        rightCareers:
            '人力资源培训、销售、职业指导顾问、心理咨询工作者、大学教师(人文学科类)、教育学、心理学研究人员等记者、撰稿人、节目主持人(新闻、采访类)、公共关系专家、社会活动家、文艺工作者、平面设计师、音乐家等。',
        rightEnv: [
            '以人为导向的工作环境；',
            '能够提供支持和促进社交互动的环境；',
            '在人际关系和社会互动中追求和平及和谐；',
            '鼓励自我表现；',
            '组织有固定的、有序的结构。',
        ],
        potentialObstacle: [
            '回避冲突，在冲突时只关注无关紧要的问题；',
            '可能为了人际关系忽视任务，尤其是任务的细节问题；',
            '在判断决策时，倾向于采用个人的主观评判，易忽视逻辑性和现实性。',
        ],
        developmentSuggestions: [
            '需要防止盲目的信任和赞同；',
            '需要有成效地管理冲突；',
            '需要像关注人一样关注任务的细节；',
            '需要仔细倾听外界的反馈信息。',
        ],
    },
    ENTJ: {
        userInfo: {
            name: '星探局长',
            mbtiType: 'ENTJ',
            features: '统帅，指挥型人格，最擅长领导指挥的类型',
            representation: '拿破仑',
        },
        personality:
            'XXX热心而真诚的天生领导者。具有预见能力，能轻松地洞察事物潜在的可能性。很有条理和分析能力，所以通常在要求推理和机智的活动中表现突出。把事实看得高于一切，按照一套相当严格的规律生活，系统地计划和研究新情况，往往喜欢接受挑战性任务。',
        strengthsAndBlindSpots: {
            strengths: [
                '直率、果断，能够妥善解决组织的问题；',
                '逻辑性强，善于分析，善于把可能性变成计划；',
                '善于系统、全局的分析和解决各种错综复杂的问题；',
                '有追求成功的干劲和雄心，愿意接受挑战。',
            ],
            blindSpots: [
                '经常在没有了解细节和形势之前就草率的做决定；',
                '总是很客观、带有批判性对待生活；',
                '容易忽略他人的感受，显得不近人情；',
                '容易夸大自己的经验、能力。',
            ],
        },
        rightCareers:
            '适合工商业、政界金融和投资领域管理咨询、培训专业性领域——各类企业的高级主管、总经理、社会团体负责人、投资银行家、股票经纪人、公司财务经理、财务顾问、经济学家企业管理顾问、企业战略顾问、项目顾问、律师、法官、知识产权专家、大学教师、科技专家等。',
        rightEnv: ['团队目标导向；', '同事目标导向、结果导向；', '有效的系统和人；', '团队结构清晰、奖励果断。'],
        potentialObstacle: [
            '在关注事情时无视人的需要；',
            '无视实际要求和局限；',
            '太快地做出决定，表现得没有耐心和极权；',
            '忽视和压抑自己情感。',
        ],
        developmentSuggestions: [
            '需要学会区欣赏他人的贡献；',
            '需要在埋头苦干之前，仔细检查可资利用的、实际的人与情境资源；',
            '在决策前，需要花时间三思问题的所有方面；',
            '需要学会认同和重视感情。',
        ],
    },
};
