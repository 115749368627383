import { View, Text, Svg, Path } from '@react-pdf/renderer';
import { usePdfTheme, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

export type TSliderValue = {
    value: number;
    type: string;
};
function SliderBarLabel(props: TSliderValue & { color: string; style?: any }) {
    const { value, type, color, style } = props;

    return (
        <View style={{ textAlign: 'center', width: 50, ...style }}>
            <Text style={{ fontSize: 16 }}>{value}%</Text>
            <Text style={{ fontSize: 10, color }}>{type}</Text>
        </View>
    );
}

function SliderBar(props: { type: 'left' | 'right'; values: [TSliderValue, TSliderValue]; style?: any }) {
    const pdfType = usePdfType();
    const theme = usePdfTheme();
    const { type, values, style } = props;

    const width = 227;

    let d: string = '';
    if (type === 'left') {
        const value: number = values[0].value / 100;

        const basicValue = 222.0 * value;

        d =
            pdfType === 'ys'
                ? `M5.000,0.000 L${basicValue},0.000 C${basicValue + 2.761},0.000 ${basicValue + 5.0},2.239 ${
                      basicValue + 5.0
                  },5.000 C${basicValue + 5.0},7.761 ${
                      basicValue + 2.761
                  },10.000 ${basicValue},10.000 L5.000,10.000 C2.239,10.000 0.000,7.761 0.000,5.000 C0.000,2.239 2.239,0.000 5.000,0.000 Z`
                : `M4.000,0.000 L${basicValue},0.000 C${basicValue + 2.209},0.000 ${basicValue + 4.0},1.791 ${
                      basicValue + 4.0
                  },4.000 C${basicValue + 4.0},6.209 ${
                      basicValue + 2.209
                  },8.000 ${basicValue},8.000 L4.000,8.000 C1.791,8.000 0.000,6.209 0.000,4.000 C0.000,1.791 1.791,0.000 4.000,0.000 Z`;
    } else {
        const value: number = (1 - values[1].value / 100) * width;

        d =
            pdfType === 'ys'
                ? `M${
                      5.0 + value
                  },0.000 L222.000,0.000 C224.761,0.000 227.000,2.239 227.000,5.000 C227.000,7.761 224.761,10.000 222.000,10.000 L${
                      5.0 + value
                  },10.000 C${2.239 + value},10.000 ${0.0 + value},7.761 ${0.0 + value},5.000 C${0.0 + value},2.239 ${
                      2.239 + value
                  },0.000 ${5.0 + value},0.000 Z`
                : `M${
                      5.0 + value
                  },0.000 L223.000,0.000 C225.209,0.000 227.000,1.791 227.000,4.000 C227.000,6.209 225.209,8.000 223.000,8.000 L${
                      5.0 + value
                  },8.000 C${2.791 + value},8.000 ${1.0 + value},6.209 ${1.0 + value},4.000 C${1.0 + value},1.791 ${
                      2.791 + value
                  },0.000 ${5.0 + value},0.000 Z`;
    }

    const realColors: [string, string] =
        pdfType === 'ys'
            ? [theme?.main, theme?.main]
            : type === 'left'
            ? [theme?.scoreCardMain, theme?.scoreCardSecondary]
            : [theme?.scoreCardSecondary, theme?.scoreCardMain];

    return (
        <View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', ...style }}>
            <SliderBarLabel
                value={values[0].value}
                type={values[0].type}
                color={realColors[0]}
                style={{ position: 'relative', left: -8 }}
            />
            <View style={{ width }}>
                {pdfType === 'ys' ? (
                    <Svg viewBox="0 0 227 10">
                        <Path
                            fillRule="evenodd"
                            fill="rgb(255, 255, 255)"
                            d="M5.000,0.000 L222.000,0.000 C224.761,0.000 227.000,2.239 227.000,5.000 C227.000,7.761 224.761,10.000 222.000,10.000 L5.000,10.000 C2.239,10.000 0.000,7.761 0.000,5.000 C0.000,2.239 2.239,0.000 5.000,0.000 Z"
                        />
                        <Path fillRule="evenodd" fill={theme?.main} d={d} />
                    </Svg>
                ) : (
                    <Svg viewBox="0 0 227 8">
                        <Path
                            d="M4,0 L223,0 C225.209,0 227,1.791 227,4 C227,6.209 225.209,8 223,8 L4,8 C1.791,8 0,6.209 0,4 C0,1.791 1.791,0 4,0 Z"
                            fill="#FFFFFF"
                        />
                        <Path fillRule="evenodd" fill={theme?.main} d={d} />
                    </Svg>
                )}
            </View>
            <SliderBarLabel
                value={values[1].value}
                type={values[1].type}
                color={realColors[1]}
                style={{ position: 'relative', left: 8 }}
            />
        </View>
    );
}

export default SliderBar;
