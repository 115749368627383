import { View, Text } from '@react-pdf/renderer';

import IntroductionOneRow from '@components/MbtiPdfReporterA4/components/IntroductionOneRow';
import Avatar from '@components/MbtiPdfReporterA4/components/Avatar';
import { usePdfData, usePdfTheme, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

function Introduction() {
    const pdfType = usePdfType();

    const { userInfo: data } = usePdfData();

    return (
        <>
            <View style={{ marginTop: 31, paddingLeft: 3 }}>
                <IntroductionOneRow label="姓　　名" value={data?.name} />
                <IntroductionOneRow
                    label="MBTI类型"
                    value={data?.mbtiType}
                    style={{
                        marginTop: 17,
                    }}
                    valueStyle={{
                        fontSize: 34.97,
                        lineHeight: 1,
                        marginTop: 0,
                        position: 'relative',
                        top: -6,
                    }}
                />
                <IntroductionOneRow
                    label="性格特点"
                    value={data?.features}
                    style={{
                        marginTop: 10,
                    }}
                    valueStyle={
                        {
                            // width: 100,
                        }
                    }
                />
                <IntroductionOneRow
                    label="类型代表"
                    value={data?.representation}
                    style={{
                        marginTop: 12.5,
                    }}
                />
            </View>

            <MbtiMarkAtAvatarDown />
            <AvatarName fixed={true} />
            <View
                style={
                    pdfType === 'ys'
                        ? { width: 158, height: 204, position: 'absolute', top: 85, right: 79 }
                        : { width: 158, height: 232, position: 'absolute', top: 75, right: 83 }
                }
            >
                <Avatar name={data?.mbtiType} />
            </View>
        </>
    );
}

/**
 * for Milesight: 显示在人格头像下面的 MBTI 文字
 */
function MbtiMarkAtAvatarDown() {
    const pdfType = usePdfType();
    const theme = usePdfTheme();

    if (pdfType === 'ys') {
        return null;
    }

    return (
        <View
            style={{
                width: 244,
                // height: 60,
                fontFamily: 'Alibaba PuHuiTi',
                position: 'absolute',
                top: 170,
                right: 64,
                // left: 342,
                fontSize: 83.17,
                lineHeight: 1,
                color: theme?.main ?? 'white',
                opacity: 0.51,
                backgroundColor: 'rgba(0, 255, 0, .5)',
            }}
        >
            <Text style={{ position: 'absolute', right: 115, top: 0 }}>M</Text>
            <Text style={{ position: 'absolute', right: 68, top: 0 }}>B</Text>
            <Text style={{ position: 'absolute', right: 18, top: 0 }}>T</Text>
            <Text style={{ position: 'absolute', right: -5, top: 0 }}>I</Text>
        </View>
    );
}

function AvatarName(props: { fixed?: boolean; avatarType?: 'normal' | 'white' }) {
    const pdfType = usePdfType();
    const data = usePdfData();
    const { fixed: pageFixed = false, avatarType = 'normal' } = props;

    if (!data?.userInfo?.mbtiType) {
        return null;
    }

    return (
        <View
            style={{
                width: 343,
                height: 77,
                position: 'absolute',
                top: 67,
                right: 36,
                ...(pdfType === 'ms' ? { width: 301, height: 67, right: 49, top: 78 } : {}),
            }}
            fixed={pageFixed}
        >
            <Avatar.Name name={data?.userInfo?.mbtiType} type={avatarType} />
        </View>
    );
}

Introduction.AvatarName = AvatarName;

export default Introduction;
