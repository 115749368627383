import { Button, Icon, Modal, Table, Tooltip, message } from 'ysd-pp';
import styles from './index.module.less';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import AddEditModal from './AddEditModal';
import classNames from 'classnames';
import HeaderSearchDropdown from '@/pages/components/HeaderSearchDropdown';
import { SearchIcon } from 'ysd-pp/es/icon';
import { useRequest } from '@/utils/request';
import { apiRoleListGet, apiUserListPost } from '../../services';

import type { TablePaginationConfig } from 'ysd-pp';
import { hasPermissions } from '@/utils/permissions';
import { returnArray } from '@/utils';
import { apiDeleteUser } from '@/services/users';
// import { useDebounceFn } from 'ahooks';

interface IUserListProps {
    noTabs?: boolean;
}
const UserList = (props: IUserListProps) => {
    const { noTabs } = props;
    const [visible, setVisible] = useState(false);
    /** 分页抽出来管理 */
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        total: 0,
        current: 1,
        pageSize: 10,
    });
    const [currentRecord, setCurrentRecord] = useState<any>({});

    const { data: roleList, runAsync: getRoleList } = useRequest(() =>
        apiRoleListGet({
            status: 1,
        }).then(res => {
            return returnArray(res?.data);
        }),
    );
    useEffect(() => {
        getRoleList();
    }, []);

    const roleOptions = useMemo(() => {
        if (roleList?.length) {
            return roleList?.map(item => {
                return {
                    label: item?.name,
                    value: item?.id,
                };
            });
        }
        return [];
    }, [roleList]);
    // 列表api
    const {
        loading,
        run: runListData,
        data: data,
    } = useRequest(
        params => {
            return apiUserListPost({
                page_no: pagination.current,
                page_size: pagination.pageSize,
                user_type: 0,
                ...params,
            }).then(res => {
                setPagination({
                    pageSize: params?.page_size || 10,
                    current: params?.page_no || 1,
                    total: res?.data?.total || 0,
                });
                // return Array.isArray(res?.result?.results) ? res?.result?.results : [];
                return Array.isArray(res?.data?.list) ? res?.data?.list : [];
            });
        },
        {
            manual: false,
        },
    );
    const { runAsync: deleteUser } = useRequest(id =>
        apiDeleteUser({
            user_id: id,
        }),
    );
    const confirm = Modal.confirm;
    const handleDelete = async (record?: any) => {
        const id = `${record?.user_id}`;

        // 二次弹窗确认
        confirm({
            title: '删除确认',
            className: 'opt-delete',
            content: (
                <>
                    <div className="desc">你确定要删除{record?.username}吗？</div>
                </>
            ),
            okText: '删除',
            cancelText: '取消',
            onOk: async () => {
                const res = await deleteUser(id);
                if (res?.code === 200) {
                    message.success('删除成功');
                    handleReFreshTable();
                }
            },
        });
    };
    const [searchParamsLocal, setSearchParamsLocal] = useState<Record<string, any>>({});

    // 表头搜索回调（点击确认按钮，或者回车）
    const handleHeaderCellSearch = () => {
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            current: pNumber,
            page_size: pSize,
            ...searchColumns
        } = searchParamsLocal;
        setSearchParamsLocal(searchColumns);
        handleTableChange?.({
            searchColumns,
            current: 1,
            page_size: pSize,
        });
    };

    // 表头搜索值改变回调
    const handleHeaderCellSearchValueChange = (dataIndex: string, value: any, immediately?: boolean) => {
        const params = searchParamsLocal;
        params[dataIndex] = value;
        setSearchParamsLocal(params);
        if (immediately) {
            handleTableChange?.({
                searchParamsLocal,
                searchColumns: params,
                current: 1,
            });
        }
    };

    const getColumnSearchProps = (dataIndex, _searchType = 'input') => ({
        filterDropdown: ({
            // setSelectedKeys,
            // selectedKeys,
            confirm,
            filterListSelected,
            filters,
        }) => {
            const _searchParamsLocal = searchParamsLocal || {};

            if (filterListSelected !== undefined) {
                _searchParamsLocal[dataIndex] = filterListSelected;
            }
            return (
                <>
                    <HeaderSearchDropdown
                        searchParams={{ ...searchParamsLocal }}
                        confirm={confirm}
                        columnItem={
                            {
                                dataIndex,
                                searchType: _searchType,
                                filterList: filters,
                            } as any
                        }
                        onSearch={handleHeaderCellSearch}
                        onSearchValueChange={handleHeaderCellSearchValueChange}
                        // rangePikerProps={rangePikerProps}
                    />
                </>
            );
        },
        filterIcon: () => {
            let filtered = false;
            if (_searchType === 'input' || _searchType === 'radio') {
                filtered = !!searchParamsLocal?.[`${dataIndex}`] || searchParamsLocal?.[`${dataIndex}`] === 0;
            } else {
                filtered = !!searchParamsLocal?.[`${dataIndex}`] && searchParamsLocal?.[`${dataIndex}`]?.length;
            }
            if (_searchType === 'input') {
                return (
                    <SearchIcon
                        className={classNames(styles['search-icon'], styles[filtered ? 'search-icon-active' : ''])}
                    />
                );
            }
            return (
                <Icon
                    type="filter"
                    className={classNames(styles['search-icon'], styles[filtered ? 'search-icon-active' : ''])}
                />
            );
        },
        // filteredValue: searchParamsLocal?.[`${dataIndex}`] || null, //此处null必要
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        // onFilterDropdownVisibleChange: (visible) => {
        //   if (visible) {
        //     setTimeout(() => searchInput.select(), 100);
        //   }
        // },
    });
    const handleTableChange = (params: Record<string, any>) => {
        const {
            searchColumns = searchParamsLocal,
            // search,
            current = 1,
            pageSize = 10,
        } = params || {};
        runListData({
            ...searchColumns,
            page_no: current,
            page_size: pageSize,
        });
    };

    const handleReFreshTable = () => {
        handleTableChange({
            searchColumns: { ...searchParamsLocal },
            current: 1,
        });
    };
    const columns = [
        {
            title: '用户角色',
            dataIndex: 'role_names',
            defaultOrder: 1,
            render: (val: string) => {
                return val ? <Tooltip type="ellipsisTooltip" title={val} /> : '-';
            },
            width: '30%',
            ...getColumnSearchProps('role_id', 'radio'),
            filters: roleOptions,
        },
        {
            title: '用户名',
            dataIndex: 'username',
            defaultOrder: 2,
            render: (val: string) => {
                return val ? <Tooltip type="ellipsisTooltip" title={val} /> : '-';
            },
            ...getColumnSearchProps('username', 'input'),
        },
        {
            width: 98,
            title: '操作',
            dataIndex: 'operations',
            fixed: 'right',
            render: (_, record) => {
                return (
                    <span>
                        {hasPermissions('userUpdate') && (
                            <Tooltip type="tooltip" title="编辑">
                                <Icon
                                    type="edit"
                                    onClick={() => {
                                        setVisible(true);
                                        setCurrentRecord(record);
                                    }}
                                    className={styles['edit-btn']}
                                />
                            </Tooltip>
                        )}
                        {hasPermissions('userDelete') && (
                            <Tooltip
                                type="tooltip"
                                title={
                                    record?.user_id === '13ac9112-bc4c-40f5-a4e4-3183b3d1424a'
                                        ? '系统内置用户不可删除'
                                        : '删除'
                                }
                            >
                                <Icon
                                    type="delete"
                                    onClick={() => {
                                        record?.user_id !== '13ac9112-bc4c-40f5-a4e4-3183b3d1424a'
                                            ? handleDelete(record)
                                            : {};
                                    }}
                                    className={styles['delete-btn']}
                                    disabled={record?.user_id === '13ac9112-bc4c-40f5-a4e4-3183b3d1424a'}
                                />
                            </Tooltip>
                        )}
                    </span>
                );
            },
        },
    ];

    // // 模糊搜索处理
    // const handleSearchChange = (e: any) => {
    //     const value = e.target.value?.trim();
    //     const {
    //         // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //         current: pNumber,
    //         page_size: pSize,
    //         ...searchColumns
    //     } = searchParamsLocal;
    //     setSearchParamsLocal({ ...searchColumns, keyword: value });
    //     handleTableChange?.({
    //         searchColumns: {
    //             ...searchColumns,
    //             keyword: value,
    //         },
    //         current: 1,
    //         page_size: pSize,
    //     });
    // };

    // const { run: debounceSearchChange } = useDebounceFn(handleSearchChange, { wait: 300 });

    return (
        <div>
            {hasPermissions('userCreate') && (
                <div className={styles['btn-wrap']}>
                    <Button
                        type="primary"
                        onClick={() => {
                            setVisible(true);
                        }}
                    >
                        创建用户
                    </Button>
                    {/* 模糊搜索
                    <div className={styles['table-search']}>
                        <Input
                            allowClear
                            placeholder="Search"
                            className={styles['table-search-input']}
                            onChange={debounceSearchChange}
                            suffix={<SearchIcon />}
                        />
                    </div> */}
                </div>
            )}

            <Table
                loading={loading}
                rowKey="id"
                columns={columns as any}
                dataSource={data || []}
                onChange={handleTableChange}
                className={styles[loading ? 'hidden-empty' : '']}
                pagination={{
                    ...pagination,
                    onReload: handleReFreshTable,
                    showQuickJumper: true,
                    showSizeChanger: true,
                }}
                // pagination={false}
                scroll={{ x: 120 * columns.length, y: 'var(--scroll-y, 200)' }}
                style={
                    {
                        ['--scroll-y']: noTabs ? 'calc(100vh - 252px)' : 'calc(100vh - 292px)',
                    } as CSSProperties
                }
            />
            <AddEditModal
                optionsList={roleOptions}
                visible={visible}
                record={currentRecord}
                setVisible={setVisible}
                refreshList={handleReFreshTable}
                setCurrentRecord={setCurrentRecord}
                // reloadRegionsList={resetRegionsList}
            />
        </div>
    );
};

export default UserList;
