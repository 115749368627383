import React, { useContext } from 'react';
import { View } from '@react-pdf/renderer';
import { Context } from '../utils/common';
import ModuleHeader from './ModuleHeader';
import WrapText from '@/components/MbtiPdfReporterMobile/components/WrapText';
import { useStyles } from '@/components/PdfReporter/utils/styles';
/**
 * @description 详情2-心理健康风险
 * @param props
 */
const MentalHealthRisk: React.FC = () => {
    const { data } = useContext(Context);
    const { mental_health_risk } = data;
    const { styles } = useStyles();

    return (
        <View>
            <ModuleHeader titleText="详情2-心理健康风险" />
            <View>
                <WrapText
                    width={510.3}
                    indent={2}
                    text={mental_health_risk?.['risk_description'] || ''}
                    style={{
                        fontSize: 12.85,
                        color: '#303C42',
                        ...styles.nameFont,
                    }}
                />
            </View>
        </View>
    );
};

export default MentalHealthRisk;
