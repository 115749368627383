import { PdfDataForMbTi } from '@components/PdfReporter/services/mbti';
import { TpDFType } from '@components/PdfReporter/utils/styles';
import React, { useContext } from 'react';
import { THEME_YS_GREEN, TThemeConfig } from '@components/MbtiPdfReporterMobile/utils/constants';

export const Context = React.createContext<{
    type: TpDFType;
    data: PdfDataForMbTi | null;
    themeConfig: TThemeConfig;
}>({
    type: 'ys',
    data: null,
    themeConfig: THEME_YS_GREEN,
});

export function usePdfType() {
    const { type } = useContext(Context);

    return type;
}

export function usePdfData() {
    const { data } = useContext(Context);

    return {
        ...data,
    } as PdfDataForMbTi;
}

export function usePdfTheme() {
    const { themeConfig } = useContext(Context);

    return themeConfig;
}
