import { View, Text } from '@react-pdf/renderer';
import { usePdfTheme, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

export type TSliderValue = {
    value: number;
    type: string;
};
function SliderBarLabel(props: TSliderValue & { color: string; style?: any }) {
    const { value, type, color, style } = props;

    return (
        <View style={{ textAlign: 'center', width: 50, ...style }}>
            <Text style={{ fontSize: 17.01 }}>{value}%</Text>
            <Text style={{ fontSize: 10.4, color }}>{type}</Text>
        </View>
    );
}

function SliderBar(props: { type: 'left' | 'right'; values: [TSliderValue, TSliderValue]; style?: any }) {
    const pdfType = usePdfType();
    const theme = usePdfTheme();
    const { type, values, style } = props;

    const { width = 154, height = 6, ...otherStyle } = style;

    const realColors: [string, string] =
        pdfType === 'ys'
            ? [theme?.main, theme?.main]
            : type === 'left'
            ? [theme?.scoreCardMain, theme?.scoreCardSecondary]
            : [theme?.scoreCardSecondary, theme?.scoreCardMain];

    const valueWidth = (values[type === 'left' ? 0 : 1].value / 100) * width;

    return (
        <View
            style={{
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                ...otherStyle,
            }}
        >
            <SliderBarLabel
                value={values[0].value}
                type={values[0].type}
                color={realColors[0]}
                style={{ position: 'relative', left: 0 }}
            />
            <View
                style={{
                    width,
                    height,
                    borderRadius: height,
                    position: 'relative',
                    backgroundColor: 'white',
                    overflow: 'hidden',
                }}
            >
                <View
                    style={{
                        width: valueWidth,
                        height,
                        borderRadius: height,
                        position: 'absolute',
                        top: 0,
                        backgroundColor: theme?.main,
                        ...(type === 'left' ? { left: 0 } : { right: 0 }),
                    }}
                />
            </View>
            <SliderBarLabel
                value={values[1].value}
                type={values[1].type}
                color={realColors[1]}
                style={{ position: 'relative', left: 0 }}
            />
        </View>
    );
}

export default SliderBar;
