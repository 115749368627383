import { Document, Font } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import fontAlibabaPuHuiTiBold from '@components/PdfReporter/assets/fonts/Alibaba-PuHuiTi-Bold.ttf';
import fontDingTalkJinBuTi from '@components/PdfReporter/assets/fonts/DingTalk JinBuTi.ttf';
import fontPingFangSC400 from '@components/PdfReporter/assets/fonts/PingFangSC-Regular.ttf';
import fontPingFangSC500 from '@components/PdfReporter/assets/fonts/PingFangSC-Semibold.ttf';
import { TpDFType } from '../PdfReporter/utils/styles';
import { Context } from './utils/common';
import CoverPage from './components/cover';
import PageBackground from './components/PageBackground';
import CareerRiskLevel from './components/CareerRiskLevel';
import ReportReference from './components/ReportReference';
import PersonalityFactors from './components/PersonalityFactors';
import { PdfDataForHigh, fetchPdfDataForHigh } from '../PdfReporter/services/high';
import MentalHealthRisk from './components/MentalHealthRisk';
import MentalHealthRiskFactors from './components/MentalHealthRiskFactors';
import { getToken } from '@/utils/getToken';

Font.register({
    family: 'DingTalk JinBuTi',
    src: fontDingTalkJinBuTi,
});
Font.register({
    family: 'Alibaba PuHuiTi',
    src: fontAlibabaPuHuiTiBold,
});
Font.register({
    family: 'PingFangSC400',
    src: fontPingFangSC400,
});
Font.register({
    family: 'PingFangSC500',
    src: fontPingFangSC500,
});

function HighPdfReporter(
    { type, data: outerData }: { type: TpDFType; data?: PdfDataForHigh } = {
        type: 'ys',
    },
) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<PdfDataForHigh>(outerData ?? {});

    useEffect(() => {
        if (typeof outerData === 'undefined') {
            const token = getToken();
            if (!token) {
                return;
            }

            fetchPdfDataForHigh({
                token,
                entrance_ids: ['C8Y6JA21T5'],
            })
                .then(res => {
                    if (res.status === 200 && res?.data?.code === 200) {
                        setData(res?.data?.data?.[0]);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
            // 模拟数据
            // setData({
            //     survey_type: '2',
            //     entrance_id: '89',
            //     user_info: {
            //         college: '厦门大学',
            //         birthday: '1999.09.09',
            //         name: '沈小镕',
            //         position: '前端工程师',
            //         sex: '女',
            //         education: '本科',
            //     },
            //     personality_factor: {
            //         innovation: '6',
            //         pressure_resistance: '9',
            //         extroversion: '3',
            //         risk: '的风险项是外向（内向寡言，好独处，不适合与人打交道的岗位)、团队合作（好竞争，与人相处较多猜疑，不易亲信他人）、抗压性（心理调节能力不足，易感不安、沮丧无助），需引起重视。',
            //         cooperation: '2',
            //         responsibility: '7',
            //         emotional_stability: '8',
            //         advantage:
            //             '在责任心（自律、目标感强、认真负责）、情绪稳定性（不易情绪化，能控制好情绪）、抗压性（坚韧，即使面对复杂的环境也能够积极面对）、团队合作（友善坦率、随和易相处）方面表现突出。',
            //     },
            //     create_at: 923474690239,
            //     risk_info: {
            //         risk_level: 'middle',
            //         risk_description:
            //             '谨慎任用，建议进一步评估 l 沈小镕整体风险等级为中，心理状态基本可以满足工作要求，但心理健康相关风险因子显示有不适症状，并且抗压性不足，在未来遇到压力或危机性事件时，较难自我调节和疏解，请结合心理风险因子进一步评估。',
            //     },
            //     mental_health_risk: {
            //         delusion_hallucination: '1',
            //         risk_description:
            //             '沈小镕的心理健康总体得分处于中等区间，表明其心理健康状况一般。若遇到一些比较严重的困难或挫折，易受干扰而处于消极的心境中难以释怀，容易导致出现偏差或过激行为，对工作容易产生消极影响。',
            //         obsessive_symptoms: '1.3',
            //         phobia: '2.2',
            //         depression: '3.3',
            //         interpersonal_relationship_sensitivity: '4.3',
            //         paranoia: '2.7',
            //         hostility: '1.6',
            //         somatization: '4',
            //         anxiety: '5',
            //     },
            //     overall_reporting_reference: {
            //         social_desirability: '4分',
            //         social_desirability_description: '正常，作答时间属于正常范围内',
            //         answer_duration: '31分',
            //         answer_duration_description: '正常，8分以下表明伪装作答的可能性较低',
            //         answer_regular: '无',
            //         reference_level: 'normal',
            //         answer_regular_description: '正常，选项分布无明显规律性作答',
            //     },
            // });
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [outerData]);

    return (
        <Document title={`职场适应性报告-${data?.user_info?.name}`}>
            {typeof outerData === 'undefined' && loading ? null : (
                <Context.Provider
                    value={{
                        type,
                        data,
                    }}
                >
                    <CoverPage />
                    <PageBackground>
                        <CareerRiskLevel />
                        <ReportReference />
                        <PersonalityFactors />
                    </PageBackground>
                    <PageBackground>
                        <MentalHealthRisk />
                        <MentalHealthRiskFactors />
                    </PageBackground>
                </Context.Provider>
            )}
        </Document>
    );
}

export default HighPdfReporter;
