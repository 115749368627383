import { View } from '@react-pdf/renderer';
import ScoreTitle from '@components/MbtiPdfReporterMobile/components/ScoreTitle';
import SliderBar, { TSliderValue } from '@components/MbtiPdfReporterMobile/components/SliderBar';
import { usePdfTheme } from '@components/MbtiPdfReporterMobile/utils/styles';
import ScoreCard, { TScoreCardData } from '@components/MbtiPdfReporterMobile/components/ScoreCard';

function ScoreSection(props: {
    title: string;
    sliderValues: [TSliderValue, TSliderValue];
    scoreCardData: [TScoreCardData, TScoreCardData];
}) {
    const theme = usePdfTheme();
    const { title, sliderValues, scoreCardData } = props;

    const type: 'left' | 'right' = sliderValues[0].value > sliderValues[1].value ? 'left' : 'right';

    return (
        <View style={{ marginTop: 23 }}>
            <ScoreTitle color={theme?.main} title={title} />
            <SliderBar
                values={sliderValues}
                style={{
                    marginTop: 15,
                    marginBottom: 16,
                }}
                type={type}
            />
            <ScoreCard type={type} data={scoreCardData} />
        </View>
    );
}

export default ScoreSection;
