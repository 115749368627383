import { Image, View } from "@react-pdf/renderer";
import star from "../assets/favorite_fill@2x.png";

function Star(props: {
    top: number | string;
    left: number | string;
    width?: number | string;
    height?: number | string;
    backgroundColor?: string;
    padding?: number | string;
}) {
    const { top, left, width, height, padding, backgroundColor } = props;
    return (
        <View
            style={{
                position: "absolute",
                top,
                left,
                width: width ?? 12,
                height: height ?? 12,
                padding: padding ?? 0,
                backgroundColor,
            }}
        >
            <Image src={star} />
        </View>
    );
}

export default Star;
