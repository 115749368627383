import { Text } from "@react-pdf/renderer";
import { addBlank } from "../utils";

/**
 * 为 children 为文本的添加空格，
 * 用于处理中文自动换行为你
 */
function TextWithBlank(props: any) {
    const { children, style, ...rest } = props;
    return (
        <Text style={{ width: "100%", ...style }} {...rest}>
            {addBlank(children)}
        </Text>
    );
}
export default TextWithBlank;
