import React, { useState } from "react";

import type { FormItemProps } from "ysd-pp";
import { Form, Icon, Input } from "ysd-pp";
import "./FieldUserName.less";
interface IFieldUserNameProps extends FormItemProps {
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
}
function FieldUserName(props: IFieldUserNameProps) {
  const { onBlur, disabled, required = true, ...restProps } = props;
  const [readOnly, setReadOnly] = useState<boolean>(true);

  return (
    <Form.Item
      {...restProps}
      name="username"
      validateFirst
      rules={
        required
          ? [
              {
                required,
                message: "请输入用户名",
                // validator: checkRequired,
              },
              {
                validator: (_rule, value) => {
                  if (value.length > 127) {
                    return Promise.reject("字符长度127");
                  }
                  return Promise.resolve();
                },
              },
            ]
          : []
      }
    >
      <Input
        prefix={<Icon type="UserOIcon" className="user-input-icon" />}
        placeholder="请输入用户名"
        onBlur={(e) => {
          setReadOnly(true);
          onBlur?.(e);
        }}
        disabled={disabled}
        readOnly={readOnly}
        onFocus={() => {
          setReadOnly(false);
        }}
      />
    </Form.Item>
  );
}

export default FieldUserName;
