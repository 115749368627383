import React, { useContext } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Context } from '../utils/common';
import ModuleHeader from './ModuleHeader';
import Tags from './Tags';
import Triangle from './Triangle';

/**
 * @description 整体报告可参考性
 * @param props
 */
const ReportReference: React.FC = () => {
    const { data } = useContext(Context);
    const { overall_reporting_reference } = data;

    // 参考性等级
    const levels = [
        { label: '低', value: 'low' },
        { label: '正常', value: 'normal' },
    ];

    // 整体报告可参考性
    const dataSource = [
        { label: '答题时长：', value: 'answer_duration', desc: 'answer_duration_description' },
        { label: '作答规律性：', value: 'answer_regular', desc: 'answer_regular_description' },
        { label: '社会称许性：', value: 'social_desirability', desc: 'social_desirability_description' },
    ];

    return (
        <View>
            <ModuleHeader
                titleText="整体报告可参考性："
                extraCom={<Tags options={levels} activeTagValue={overall_reporting_reference?.reference_level || ''} />}
            />
            <View
                style={{
                    fontSize: 12.85,
                    color: '#303C42',
                }}
            >
                {dataSource.map(item => (
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ marginBottom: 6 }}>
                            <Triangle />
                        </View>
                        <Text style={{ marginLeft: 10, marginRight: 2 }}>{item.label}</Text>
                        <Text>{overall_reporting_reference?.[item.value]}</Text>
                        <Text style={{ marginLeft: 6, color: '#A4A8AB' }}>
                            ({overall_reporting_reference?.[item.desc]})
                        </Text>
                    </View>
                ))}
            </View>
        </View>
    );
};

export default ReportReference;
