import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useStyles } from "../utils/styles";
import TextWithBlank from "./TextWithBlank";

/**
 * 导言
 */
function Introduction() {
    const { commonStyles } = useStyles();

    const styles = StyleSheet.create({
        heading: {
            ...commonStyles.heading,
        },
        paragraph: {
            ...commonStyles.indent,
            marginTop: 4,
        },
        text: {
            ...commonStyles.text,
        },
        strong: {
            ...commonStyles.strongText,
        },
    });

    return (
        <View style={{ height: "auto" }}>
            <TextWithBlank style={styles.heading}>导言：</TextWithBlank>

            <TextWithBlank
                style={{ ...styles.paragraph, ...styles.text, marginTop: 8 }}
            >
                {`　　本报告分析及结论均依据受测人完成的卡特尔16PF测验结果。`}
            </TextWithBlank>
            <Text style={{ ...styles.paragraph, ...styles.text }}>
                <TextWithBlank>
                    {`　　卡特尔 16PF 测验全称是 Cartel 16 Personality Factor Test，由美国伊利诺州立大学人格及能力研究所雷蒙德•卡特尔教授编制。卡氏采用系统观察法、科学实验法以及因素分析统计法，经过二、三十年的研究确定了 16 种人格特质，并据此编制了测验量表。卡特尔认为这`}
                </TextWithBlank>
                <TextWithBlank style={styles.strong}>
                    {` 16 个“根源特质”是人类的潜在、稳定的人格特征，是人格测验应把握的实质。`}
                </TextWithBlank>
                <TextWithBlank>
                    {` 16 种个性因素在一个人身上的不同组合，就构成了一个人独特的人格，完整地反映了一个人个性的全貌。`}
                </TextWithBlank>
            </Text>
            <TextWithBlank
                style={{ ...styles.paragraph, ...styles.text, marginTop: 16 }}
            >
                {`　　16PF 测验从乐群、聪慧、自律、独立、敏感、变革、怀疑等16个相对独立的人格特点对人进行描绘，并可以了解受测者在环境适应、专业成就和心理健康等方面的表现。在人事管理中，16 PF 能够预测受测者的工作稳定性、工作效率、学习能力、适应能力、合作性和压力承受能力等，可广泛应用于职业辅导、人员选拔、和员工关爱的各个环节。`}
            </TextWithBlank>
        </View>
    );
}

export default Introduction;
