import { StyleSheet, View } from "@react-pdf/renderer";
import { useStyles } from "../utils/styles";
import ChartLeadingMotive from "./ChartLeadingMotive";
import TableMotivationalInterpretation from "./TableMotivationalInterpretation";
import TextWithBlank from "./TextWithBlank";

/**
 * 2、主导动机
 */
function Reporter2() {
    const { commonStyles } = useStyles();

    const styles = StyleSheet.create({
        heading: { ...commonStyles.heading },
        text: { ...commonStyles.text },
        paragraph: { ...commonStyles.indent },
    });

    return (
        <>
            <View style={{ height: "auto", marginTop: 24 }}>
                <TextWithBlank
                    style={styles.heading}
                >{`2、主导动机`}</TextWithBlank>
                <TextWithBlank
                    style={{
                        ...styles.text,
                        ...styles.paragraph,
                        marginTop: 8,
                    }}
                >
                    {`　　动机是指激发和维持个体活动，使活动朝向一定目标的内部动力。社会动机可用于解释支配个体社会行为的过程，分析个体的行为方式，预测不同的行为表现。`}
                </TextWithBlank>
                <TextWithBlank
                    style={{
                        ...styles.text,
                        ...styles.paragraph,
                    }}
                >
                    {`　　社会动机可分为亲和动机、成就动机、权力动机三种，在面临冲突时，个体主导社会动机的不同可预测不同的行为表现，如亲和动机过强的领导需要辞退下属时会遇到很大的挫折；成就动机过低的个体易满足于现状、缺乏进步与改进的动力，较难实现自我突破；权力动机过强而亲和动机低的领导很难营造较好的团队氛围。`}
                </TextWithBlank>
                <View
                    style={{
                        marginTop: 8,
                        marginBottom: 12,
                        justifyContent: "center",
                        paddingLeft: 16,
                    }}
                >
                    <ChartLeadingMotive />
                </View>
            </View>
            <TableMotivationalInterpretation />
        </>
    );
}

export default Reporter2;
