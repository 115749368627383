/* eslint-disable no-irregular-whitespace */
import React, { useContext } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Context } from '../utils/common';
import TextWithBlank from '@/components/PdfReporter/components/TextWithBlank';
import ModuleHeader from './ModuleHeader';
import Tags from './Tags';
import { useStyles } from '@/components/PdfReporter/utils/styles';

/**
 * @description 职场适应风险等级
 * @param props
 */
const CareerRiskLevel: React.FC = () => {
    const { data } = useContext(Context);
    const { risk_info } = data;
    const { styles } = useStyles();

    // 职业适应性风险等级
    const levels = [
        { label: '低', value: 'low' },
        { label: '较低', value: 'lower' },
        { label: '中', value: 'middle' },
        { label: '较高', value: 'higher' },
        { label: '高', value: 'high' },
    ];
    const levelText = risk_info?.risk_description?.split('|');

    return (
        <View>
            <ModuleHeader
                titleText="职场适应风险等级："
                extraCom={<Tags options={levels} activeTagValue={risk_info?.risk_level || ''} />}
            />
            <View
                style={{
                    fontSize: 12.85,
                }}
            >
                <Text style={{ letterSpacing: -1.8, fontSize: 12.85, ...styles.nameFont }}>
                    <TextWithBlank style={{ color: '#006ee1' }}>{`　　${levelText?.[0] || ''}`}</TextWithBlank>
                    <TextWithBlank
                        style={{
                            color: '#303C42',
                        }}
                    >
                        {`  |  ${levelText?.[1] || ''}`}
                    </TextWithBlank>
                </Text>
            </View>
        </View>
    );
};

export default CareerRiskLevel;
