import MbtiPdfReporterMobile from '@/components/MbtiPdfReporterMobile';
import MbtiPdfReporterA4 from '@/components/MbtiPdfReporterA4';
import { TpDFType } from '@/components/PdfReporter/utils/styles';
import { PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { EPersonality } from '@/components/MbtiPdfReporterMobile/utils/constants';
import { Select } from 'ysd-pp';
import { useState } from 'react';

function PdfMbti({ type, isMobile }: { type?: TpDFType; isMobile: boolean }) {
    const params = useParams();

    const realType = ((type ?? params?.type) || 'ys') as TpDFType;

    const cnNames = {
        [EPersonality.ESFJ]: '东道主 蓝色',
        [EPersonality.ESFP]: '乐天派 黄色',
        [EPersonality.INTJ]: '军师 紫色',
        [EPersonality.ISFJ]: '守护者 蓝色',
        [EPersonality.ESTP]: '实践者 黄色',
        [EPersonality.ESTJ]: '总经理 蓝色',
        [EPersonality.ISTP]: '探险家 黄色',
        [EPersonality.ENFJ]: '教育家 绿色',
        [EPersonality.INFP]: '治愈者 绿色',
        [EPersonality.ISTJ]: '物流师 蓝色',
        [EPersonality.INTP]: '科学家 紫色',
        [EPersonality.INFJ]: '精神导师 绿色',
        [EPersonality.ENTJ]: '统帅 紫色',
        [EPersonality.ISFP]: '艺术家 黄色',
        [EPersonality.ENTP]: '辩论家 紫色',
        [EPersonality.ENFP]: '追梦人 绿色',
    };
    const options = Object.keys(EPersonality).map(item => ({
        label: `${item} ${cnNames[item]}`,
        value: item,
    }));

    const [mbtiType, setMbtiType] = useState<EPersonality>(EPersonality.ENFJ);

    return (
        <>
            <Select
                options={options}
                value={mbtiType}
                onChange={val => {
                    setMbtiType(val as EPersonality);
                }}
                style={{
                    width: 200,
                    marginBottom: 10,
                }}
                showSearch
            />
            {isMobile ? (
                <PDFViewer style={{ width: '100%', height: 'calc(100vh - 60px)' }} key={mbtiType}>
                    <MbtiPdfReporterMobile type={realType} mbtiType={mbtiType} />
                </PDFViewer>
            ) : (
                <PDFViewer style={{ width: '100%', height: 'calc(100vh - 60px)' }} key={mbtiType}>
                    <MbtiPdfReporterA4 type={realType} mbtiType={mbtiType} />
                </PDFViewer>
            )}
        </>
    );
}

export default PdfMbti;
