import { Polyline, StyleSheet, Svg, View } from "@react-pdf/renderer";
import { usePdfData, useStyles } from "../utils/styles";
import Star from "./Star";
import TextWithBlank from "./TextWithBlank";

function TablePersonalityFactorPoints() {
    const { commonStyles, colors } = useStyles();

    const tbStyles = StyleSheet.create({
        table: {
            textAlign: "center",
            ...commonStyles.text,
        },
        blueCellBg: {
            backgroundColor: colors.themeColor,
            color: "white",
        },
        row: {
            justifyContent: "space-between",
            alignItems: "stretch",
            flexDirection: "row",
            textAlign: "center",
            backgroundColor: "#F9F9F9",
            color: "black",
        },
        head: {
            //
        },
        cell: {
            height: 40,
            paddingLeft: 6,
            paddingRight: 6,
            lineHeight: 16 / 12,
        },
        titlePointCellText: { marginTop: 8, lineHeight: 1 },
    });

    const { personality_factor_score: scopes = [] } = usePdfData();

    const dataSource: {
        factor: string;
        lowScopeFeature: string;
        highScopeFeature: string;
        /**
         * 文字居中需要的 marginTop 大小
         * 分别对应着：人格因素、低分特征、高分特征
         */
        marginTops: [number, number, number];
        /**
         * 星星要定位的位置 y
         */
        y: number;
    }[] = [
        // 32, 48
        {
            factor: "乐群",
            lowScopeFeature: "缄默，孤独，冷漠",
            highScopeFeature: "外向，热情，乐群",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 0 + 40 / 2,
        },
        {
            factor: "聪慧",
            lowScopeFeature: "迟钝，学识浅，思维能力弱",
            highScopeFeature: "聪明，富有才识，善学习",
            marginTops: [14, 6, 14],
            y: (40 + 1) * 1 + 40 / 2,
        },
        {
            factor: "稳定",
            lowScopeFeature: "情绪激动，易受环境支配",
            highScopeFeature: "情绪稳定而成熟",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 2 + 40 / 2,
        },
        {
            factor: "影响",
            lowScopeFeature: "谦逊，顺从，通融，恭顺",
            highScopeFeature: "好强固执，独立积极",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 3 + 40 / 2,
        },
        {
            factor: "活跃",
            lowScopeFeature: "严肃审慎，冷静寡言",
            highScopeFeature: "轻松兴奋，随遇而安",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 4 + 40 / 2,
        },
        {
            factor: "规范",
            lowScopeFeature: "权宜敷衍，难以奉公守法",
            highScopeFeature: "有恒负责，做事尽职",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 5 + 40 / 2,
        },
        {
            factor: "敢为",
            lowScopeFeature: "畏怯退缩，缺乏自信心",
            highScopeFeature: "冒险敢为，少有顾忌",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 6 + 40 / 2,
        },
        {
            factor: "敏感",
            lowScopeFeature: "理性，着重现实",
            highScopeFeature: "敏感，感情用事",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 7 + 40 / 2,
        },
        {
            factor: "怀疑",
            lowScopeFeature: "依赖随和，易与人相处",
            highScopeFeature: "怀疑，刚愎，固执己见",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 8 + 40 / 2,
        },
        {
            factor: "幻想",
            lowScopeFeature: "现实，合乎成规",
            highScopeFeature: "幻想，狂放不羁",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 9 + 40 / 2,
        },
        {
            factor: "世故",
            lowScopeFeature: "坦白直率，天真",
            highScopeFeature: "精明能干，世故",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 10 + 40 / 2,
        },
        {
            factor: "忧虑",
            lowScopeFeature: "安详，沉着，有信心",
            highScopeFeature: "忧虑抑郁，烦恼自扰",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 11 + 40 / 2,
        },
        {
            factor: "变革",
            lowScopeFeature: "保守，尊重传统",
            highScopeFeature: "自由，批评激进",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 12 + 40 / 2,
        },
        {
            factor: "独立",
            lowScopeFeature: "依赖，随群附众",
            highScopeFeature: "自立自强，当机立断",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 13 + 40 / 2,
        },
        {
            factor: "自律",
            lowScopeFeature: "矛盾冲突，不识大体",
            highScopeFeature: "知己知彼，自律谨严",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 14 + 40 / 2,
        },

        {
            factor: "紧张",
            lowScopeFeature: "心平气和",
            highScopeFeature: "紧张困扰",
            marginTops: [14, 14, 14],
            y: (40 + 1) * 15 + 40 / 2,
        },
    ];

    const points = scopes.map((item, itemIndex) => {
        return [item * 20 - 20 / 2, dataSource[itemIndex].y];
    });

    return (
        <View style={{ ...tbStyles.table, ...commonStyles.text, width: 547 }}>
            <View
                style={{
                    ...tbStyles.row,
                    ...tbStyles.head,
                    ...tbStyles.blueCellBg,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    height: 56,
                    alignItems: "stretch",
                }}
            >
                <View
                    style={{
                        width: 56,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                        borderTopLeftRadius: 4,
                    }}
                >
                    <TextWithBlank
                        style={{ ...commonStyles.strongText, marginTop: 10 }}
                    >
                        人格
                    </TextWithBlank>
                    <TextWithBlank style={{ ...commonStyles.strongText }}>
                        因素
                    </TextWithBlank>
                </View>
                <View
                    style={{
                        width: 145,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                    }}
                >
                    <TextWithBlank
                        style={{ ...commonStyles.strongText, marginTop: 22 }}
                    >
                        低分特征
                    </TextWithBlank>
                </View>
                <View
                    style={{
                        width: 200,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                    }}
                >
                    <View style={{ ...commonStyles.borderBottom, height: 30 }}>
                        <TextWithBlank
                            style={{
                                ...tbStyles.titlePointCellText,
                                ...commonStyles.strongText,
                            }}
                        >
                            标准分
                        </TextWithBlank>
                    </View>
                    <View
                        style={{
                            ...tbStyles.row,
                            ...tbStyles.blueCellBg,
                            height: 26,
                            alignItems: "stretch",
                            ...commonStyles.fontSize12,
                        }}
                    >
                        <View
                            style={{
                                width: 20,
                                padding: 0,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                1
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 20,
                                padding: 0,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                2
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 20,
                                padding: 0,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                3
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 20,
                                padding: 0,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                4
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 20,
                                padding: 0,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                5
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 20,
                                padding: 0,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                6
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 20,
                                padding: 0,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                7
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 20,
                                padding: 0,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                8
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 20,
                                padding: 0,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                9
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 20,
                                padding: 0,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                10
                            </TextWithBlank>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        width: 146,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderRight,
                        ...commonStyles.borderBottom,
                        borderTopRightRadius: 4,
                    }}
                >
                    <TextWithBlank
                        style={{ ...commonStyles.strongText, marginTop: 24 }}
                    >
                        高分特征
                    </TextWithBlank>
                </View>
            </View>
            {dataSource.map((item, itemIndex) => {
                const isLastItem = itemIndex === dataSource.length - 1;

                return (
                    <View
                        key={item.factor}
                        style={{
                            backgroundColor: "#F9F9F9",
                            flexDirection: "row",
                            alignItems: "stretch",
                            ...commonStyles.text,
                            ...commonStyles.fontSize12,
                            ...commonStyles.borderBottom,
                            ...(isLastItem
                                ? {
                                      borderBottomLeftRadius: 4,
                                      borderBottomRightRadius: 4,
                                  }
                                : {}),
                        }}
                    >
                        <View
                            style={{
                                ...tbStyles.cell,
                                width: 56,
                                ...commonStyles.borderLeft,
                                ...(isLastItem
                                    ? { borderBottomLeftRadius: 4 }
                                    : {}),
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...commonStyles.strongText,
                                    marginTop: item.marginTops[0],
                                }}
                            >
                                {item.factor}
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                ...tbStyles.cell,
                                ...commonStyles.borderLeft,
                                width: 145,
                                textAlign: "left",
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    marginTop: item.marginTops[1],
                                }}
                            >
                                {item.lowScopeFeature}
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                ...commonStyles.borderLeft,
                                width: 20,
                            }}
                        ></View>
                        <View
                            style={{
                                width: 20,
                            }}
                        ></View>
                        <View
                            style={{
                                width: 20,
                            }}
                        ></View>
                        <View
                            style={{
                                ...commonStyles.borderLeft,
                                borderLeftStyle: "dashed",
                                borderLeftColor: "#CCCCCC",
                                width: 20,
                            }}
                        ></View>
                        <View
                            style={{
                                width: 20,
                            }}
                        ></View>
                        <View
                            style={{
                                width: 20,
                            }}
                        ></View>
                        <View
                            style={{
                                width: 20,
                            }}
                        ></View>
                        <View
                            style={{
                                ...commonStyles.borderLeft,
                                borderLeftStyle: "dashed",
                                borderLeftColor: "#CCCCCC",
                                width: 20,
                            }}
                        ></View>
                        <View
                            style={{
                                width: 20,
                            }}
                        ></View>
                        <View
                            style={{
                                width: 20,
                            }}
                        ></View>
                        <View
                            style={{
                                ...tbStyles.cell,
                                ...commonStyles.borderLeft,
                                ...commonStyles.borderRight,
                                width: 146,
                                textAlign: "left",
                                ...(isLastItem
                                    ? {
                                          borderBottomRightRadius: 4,
                                      }
                                    : {}),
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    marginTop: item.marginTops[2],
                                }}
                            >
                                {item.highScopeFeature}
                            </TextWithBlank>
                        </View>
                    </View>
                );
            })}
            <View
                style={{
                    position: "absolute",
                    top: 56,
                    left: 201,
                    width: 200,
                    height: 660,
                }}
            >
                <Svg
                    style={{
                        width: 200,
                        height: 660,
                        // backgroundColor: "yellow",
                    }}
                >
                    <Polyline
                        stroke={colors.themeColor}
                        strokeWidth={1}
                        points={points.map((item) => item.join(",")).join(" ")}
                    />
                </Svg>
                {dataSource.map((item, itemIndex) => {
                    return (
                        <Star
                            key={`${item.y}-${itemIndex}`}
                            top={item.y - 8}
                            left={scopes[itemIndex] * 20 - 20 / 2 - 8}
                            width={16}
                            height={16}
                            padding={2}
                            backgroundColor="#F9F9F9"
                        />
                    );
                })}
            </View>
        </View>
    );
}

export default TablePersonalityFactorPoints;
