import { StyleSheet, View } from "@react-pdf/renderer";
import { useStyles } from "../utils/styles";
import TextWithBlank from "./TextWithBlank";

function TableRiskIndicator(props: {
    mainTitle: [string, string];
    titles: string[];
    values: string[];
}) {
    const { mainTitle, titles, values } = props;

    const { commonStyles, tableStyles, colors } = useStyles();

    const tbStyles = StyleSheet.create({
        table: {
            ...tableStyles.table,
            width: "100%",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            ...commonStyles.text,
        },
        blueCellBg: {
            backgroundColor: colors.themeColor,
            color: "white",
        },
        firstCell: {
            width: "25%",
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            height: 56,
            paddingTop: 12,
            ...commonStyles.strongText,
        },
        rightPart: {
            width: "75%",
        },
        rightPartRow: {
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            textAlign: "center",
            backgroundColor: "#F9F9F9",
            color: "black",
        },
        rightPartRowTitle: {
            color: "white",
            borderTopRightRadius: 4,
        },
        rightPartRowLast: {
            borderBottomRightRadius: 4,
        },
        cell: {
            width: "33.33%",
            paddingTop: 5,
        },
        cellHeight: {
            height: 28,
        },
        cellHeightX: {
            height: 28,
            paddingTop: 8,
            lineHeight: 1,
        },
        highlight: {
            color: "#E6492D",
        },
    });
    return (
        <View style={tbStyles.table}>
            <View
                style={{
                    ...tbStyles.firstCell,
                    ...tbStyles.blueCellBg,
                    ...commonStyles.borderLeft,
                    ...commonStyles.borderRight,
                    ...commonStyles.borderBottom,
                    ...commonStyles.borderTop,
                }}
            >
                <TextWithBlank>{mainTitle[0]}</TextWithBlank>
                <TextWithBlank>{mainTitle[1]}</TextWithBlank>
            </View>
            <View style={tbStyles.rightPart}>
                <View
                    style={{
                        ...tbStyles.rightPartRow,
                        ...tbStyles.rightPartRowTitle,
                        ...tbStyles.blueCellBg,
                    }}
                >
                    <View
                        style={{
                            ...tbStyles.cell,
                            ...tbStyles.cellHeight,
                            ...commonStyles.borderBottom,
                            ...commonStyles.borderTop,
                            ...commonStyles.strongText,
                            paddingTop: 6,
                        }}
                    >
                        <TextWithBlank>{titles[0]}</TextWithBlank>
                    </View>
                    <View
                        style={{
                            ...tbStyles.cell,
                            ...tbStyles.cellHeight,
                            ...commonStyles.borderLeft,
                            ...commonStyles.borderBottom,
                            ...commonStyles.borderTop,
                            ...commonStyles.strongText,
                            paddingTop: 6,
                        }}
                    >
                        <TextWithBlank>{titles[1]}</TextWithBlank>
                    </View>
                    <View
                        style={{
                            ...tbStyles.cell,
                            ...tbStyles.cellHeight,
                            ...commonStyles.borderLeft,
                            ...commonStyles.borderRight,
                            ...commonStyles.borderBottom,
                            ...commonStyles.borderTop,
                            borderTopRightRadius: 4,
                            ...commonStyles.strongText,
                            paddingTop: 6,
                        }}
                    >
                        <TextWithBlank>{titles[2]}</TextWithBlank>
                    </View>
                </View>
                <View
                    style={{
                        ...tbStyles.rightPartRow,
                        ...tbStyles.rightPartRowLast,
                    }}
                >
                    <View
                        style={{
                            ...tbStyles.cell,
                            ...tbStyles.cellHeightX,
                            ...commonStyles.borderBottom,
                        }}
                    >
                        <TextWithBlank
                            style={values[0] ? tbStyles.highlight : void 0}
                        >
                            {values[0] || "—"}
                        </TextWithBlank>
                    </View>
                    <View
                        style={{
                            ...tbStyles.cell,
                            ...tbStyles.cellHeightX,
                            ...commonStyles.borderLeft,
                            ...commonStyles.borderBottom,
                        }}
                    >
                        <TextWithBlank
                            style={values[1] ? tbStyles.highlight : void 0}
                        >
                            {values[1] || "—"}
                        </TextWithBlank>
                    </View>
                    <View
                        style={{
                            ...tbStyles.cell,
                            ...tbStyles.cellHeightX,
                            ...commonStyles.borderLeft,
                            ...commonStyles.borderBottom,
                            ...commonStyles.borderRight,
                            borderBottomRightRadius: 4,
                        }}
                    >
                        <TextWithBlank
                            style={values[2] ? tbStyles.highlight : void 0}
                        >
                            {values[2] || "—"}
                        </TextWithBlank>
                    </View>
                </View>
            </View>
        </View>
    );
}

export default TableRiskIndicator;
