import { Image, Page, Text, View } from '@react-pdf/renderer';
import ms from '../assets/milesight-pdf-home.jpg';
import { usePdfData, useStyles } from '../utils/styles';

function PageMs() {
    const { styles, colors } = useStyles();
    const { user_info, create_at } = usePdfData();

    const d = new Date(create_at!);
    const Y = d.getUTCFullYear();
    let M: string | number = d.getUTCMonth() + 1;
    M = `${M < 10 ? '0' : ''}${M}`;

    return (
        <Page style={{ ...styles.body }}>
            <View style={styles.homeBodyBg}>
                <Image style={styles.homeBodyBgImage} src={ms} />
            </View>
            <View style={styles.homeBodyText}>
                <Text>姓名：{user_info?.name}</Text>
                <Text>性别：{user_info?.sex}</Text>
                <Text>毕业学校：{user_info?.college}</Text>
                <Text>应聘岗位：{user_info?.position}</Text>
            </View>
            <Text
                style={{
                    position: 'absolute',
                    fontSize: 10,
                    right: 0,
                    textAlign: 'center',
                    paddingBottom: 4,
                    letterSpacing: 0,
                    fontFamily: 'PingFangSC400',
                    left: '50%',
                    bottom: 16,
                    lineHeight: 1,
                    width: 20,
                    height: 20,
                    paddingTop: 5,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    backgroundColor: '#FFFFFF',
                    color: colors.themeColor,
                    borderRadius: 18,
                    marginLeft: -13,
                }}
                fixed
            >
                01
            </Text>
            <View
                style={{
                    position: 'absolute',
                    bottom: 22,
                    left: 24,
                    right: 24,
                    color: '#FFFFFF',
                    fontFamily: 'Alibaba PuHuiTi',
                    fontSize: 12,
                    lineHeight: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Text>人力资源部</Text>
                <Text>
                    {Y}年{M}月
                </Text>
            </View>
        </Page>
    );
}

export default PageMs;
