import { Image } from '@react-pdf/renderer';
import {
    AVATAR_MS,
    AVATAR_YS,
    CN_NAME_MS,
    CN_NAME_MS_2,
    CN_NAME_YS_A4,
    EPersonality,
} from '@components/MbtiPdfReporterMobile/utils/constants';
import { usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

type TImage = ConstructorParameters<typeof Image>[0];

function Avatar(
    props: Omit<TImage, 'src'> & {
        name?: EPersonality;
    },
) {
    const pdfType = usePdfType();
    const { name, ...rest } = props;
    const avatars = pdfType === 'ys' ? AVATAR_YS : AVATAR_MS;
    return name ? <Image src={avatars[name]} {...rest} /> : null;
}

function AvatarCnName(
    props: Omit<TImage, 'src'> & {
        name?: EPersonality;
        type?: 'normal' | 'white';
    },
) {
    const pdfType = usePdfType();
    const { name, type = 'normal', ...rest } = props;
    let cnNames = pdfType === 'ys' ? CN_NAME_YS_A4 : CN_NAME_MS;
    if (pdfType === 'ms' && type === 'white') {
        cnNames = CN_NAME_MS_2;
    }

    return name ? <Image src={cnNames[name]} {...rest} /> : null;
}

Avatar.Name = AvatarCnName;

export default Avatar;
