import { View } from "@react-pdf/renderer";
import { useStyles } from "../utils/styles";
import ChartWorkingCompetence from "./ChartWorkingCompetence";
import TextWithBlank from "./TextWithBlank";

/**
 * 4、工作能力
 */
function Reporter4() {
    const { commonStyles } = useStyles();

    return (
        <View style={{ height: "auto", marginTop: 24 }}>
            <TextWithBlank
                style={commonStyles.heading}
            >{`4、工作能力`}</TextWithBlank>
            <View style={{ marginTop: 8, marginLeft: 112 }}>
                <ChartWorkingCompetence />
            </View>
        </View>
    );
}

export default Reporter4;
