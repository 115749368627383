import request from '@/utils/request';

const MOCK_PREFIX = '/oa_web_survey';

/**
 * 查询测评信息列表
 * get请求参数类型
 * https://app.apifox.com/project/3297894/apis/api-110804000
 */
export type TSurveyListGetParams = {
    /**0-Yeastar，1-Milesight*/
    company?: number;
    /** 入口状态*/
    entrance_status?: number;
    /**用户名*/
    name?: string;
    /** 当前页*/
    page_no?: number;
    /**每页大小*/
    page_size?: number;
    /**手机号*/
    phone?: string;
    /**报告状态*/
    report_status?: number;
    /** 测评ID*/
    survey_id?: number;
    /** 测评类型*/
    survey_type?: number;
    /**url创建时间结束*/
    url_create_time_end?: number;
    /** url创建时间开始*/
    url_create_time_start?: number;
    /** url创建人姓名*/
    url_create_user_name?: string;
    /** url状态*/
    url_status?: number;
    /** 用户标识*/
    user_id?: string;
};
/**
 * 查询测评信息列表
 * response body类型
 * https://app.apifox.com/project/3297894/apis/api-110804000
 */
export type TSurveyListGetRes = {
    /**  */
    list?: {
        /**公司code*/
        company?: number | null;
        /** 有效时间*/
        effective_time?: number | null;
        /** 入口状态*/
        entrance_status?: number | null;
        /**url过期状态：1-已过期，0-未过期*/
        is_entrance_expired?: number | null;
        /**姓名 */
        name?: null | string;
        /**手机号*/
        phone?: null | string;
        /** 报告状态*/
        report_status?: number | null;
        /**测评类型ID*/
        survey_id?: number | null;
        /** 测评类型*/
        survey_type?: number | null;
        /** url*/
        url?: null | string;
        /** url创建时间*/
        url_create_time?: null | string;
        /**url创建人ID*/
        url_create_user_id?: null | string;
        /**url创建人姓名*/
        url_create_user_name?: null | string;
        /** 用户标识*/
        user_id?: null | string;
        /**测评ID*/
        entrance_id?: string;
    }[];
    /**  */
    total?: number;
    page_size?: number;
    current?: number;
};
/**
 * 获取测评记录列表
 * https://app.apifox.com/project/3297894/apis/api-110804000
 */
export const apiEntranceListGet = (data: TSurveyListGetParams) => {
    return request<TSurveyListGetRes>(`${MOCK_PREFIX}/entrance/list`, {
        method: 'POST',
        data,
    });
};

/**
 * 获取测评类型列表
 * response body类型
 * https://app.apifox.com/project/3297894/apis/api-110801319
 */
export type TSurveyTypeListGetRes = {
    code?: number;
    name?: string;
}[];
/**
 * 获取测评类型列表
 * https://app.apifox.com/project/3297894/apis/api-110801319
 */
export const apiSurveyTypeListGet = () => {
    return request<TSurveyTypeListGetRes>(`${MOCK_PREFIX}/survey/type/list`, {
        method: 'GET',
    });
};

export type TCompanyTypeGetRes = {
    code?: number;
    name?: string;
}[];
/**
 * 获取公司类型
 * https://app.apifox.com/project/3297894/apis/api-112978881
 */
export const apiCompanyTypeGet = () => {
    return request<TCompanyTypeGetRes>(`${MOCK_PREFIX}/company/type`, {
        method: 'GET',
    });
};
/**
 * 添加入口--生成测评链接
 * response body类型
 * https://app.apifox.com/project/3297894/apis/api-110801320
 */
export type TEntrancePostParams = {
    /**
     * 公司
     */
    company_code: number;
    /**
     * 有效时间，单位：秒
     */
    effective_time: number;
    /**
     * 测评类型
     */
    survey_code: number;
};
/**
 * 添加入口--生成测评链接
 * response body类型
 * https://app.apifox.com/project/3297894/apis/api-110801320
 */
export type TEntrancePostRes = {
    /**  */
    url?: string;
};
/**
 * 添加入口--生成测评链接
 * https://app.apifox.com/project/3297894/apis/api-110801320
 */
export const apiEntrancePost = (data: TEntrancePostParams) => {
    return request<TEntrancePostRes>(`${MOCK_PREFIX}/entrance`, {
        method: 'POST',
        data,
    });
};

/**
 * SurveyTimeVO
 */
export interface SurveyTimeVO {
    /**
     * 结束时间
     */
    end_time?: null | string;
    /**
     * 开始时间
     */
    start_time?: null | string;
}

// 获取测评时间
export const apiGetMbtiValidTime = () => {
    return request<SurveyTimeVO>(`${MOCK_PREFIX}/survey/survey_time`, {
        method: 'GET',
    });
};

// 更新测评时间
export const apiUpdateMbtiValidTime = (data: SurveyTimeVO) => {
    return request(`${MOCK_PREFIX}/survey/survey_time`, {
        method: 'PUT',
        data,
    });
};

/**
 * 添加入口--生成测评链接
 * https://app.apifox.com/link/project/3297894/apis/api-114016351
 */
export const apiReportExport = () => {
    return request<TEntrancePostRes>(`${MOCK_PREFIX}/report/export/excel`, {
        method: 'GET',
    });
};

/**
 * 查询用户信息列表
 * response body类型
 * https://app.apifox.com/project/3297894/apis/api-110801320
 */
export type TUserListPostParams = {
    /** 用户类型：0-系统用户、1-测评用户 */
    user_type?: 0;
    /** 用户状态：1-正常，0-失活 */
    status?: 1;
    name?: string;
};
/**
 * 查询用户信息列表
 * response body类型
 * https://app.apifox.com/project/3297894/apis/api-110801320
 */
export type TUserListPostRes = {
    list: {
        /** 用户类型：0-系统用户、1-测评用户 */
        user_type?: 0;
        /** 用户状态：1-正常，0-失活 */
        status?: 1;
        name?: string;
        role_name?: string;
    };
    /**  */
    total?: number;
    page_size?: number;
    current?: number;
};
/**
 * 查询用户信息列表
 * https://app.apifox.com/project/3297894/apis/api-110801320
 */
export const apiUserListPost = (data: TUserListPostParams) => {
    return request<TUserListPostRes>(`${MOCK_PREFIX}/user/list`, {
        method: 'POST',
        data,
    });
};

export type TRoleListGetParams = {
    /** 角色状态： 1-启用 0-停用 */
    status?: number;
};
export type TRoleListGetRes = {
    id?: number;
    name?: string;
}[];

/**
 * 获取公司类型
 * https://app.apifox.com/project/3297894/apis/api-112978881
 */
export const apiRoleListGet = (data: TRoleListGetParams) => {
    return request<TRoleListGetRes>(`${MOCK_PREFIX}/role/list`, {
        method: 'POST',
        data,
    });
};

export type TReportRegenerateParams = {
    /** 入口标识 */
    entrance_id?: string;
};
/**
 * 重新生成测评PDF报告
 *
 */
export const apiReportRegenerate = (data: TReportRegenerateParams) => {
    return request(`${MOCK_PREFIX}/report/regenerate/${data?.entrance_id}`, {
        method: 'PUT',
        data,
    });
};

/**
 * PaginationResponse«StationRule»
 */
export interface IStationRuleRes {
    /**
     * 当前页
     */
    current?: number | null;
    /**
     * 列表
     */
    list?: StationRule[] | null;
    /**
     * 每页大小
     */
    pageSize?: number | null;
    /**
     * 总数
     */
    total?: number | null;
}

/**
 * <p>
 * 岗位规则表
 * </p>
 *
 * StationRule
 */
export interface StationRule {
    create_at?: null | string;
    /**
     * FIXME：注释，后续引入用户和权限功能后，可以打开
     */
    create_by?: null | string;
    id?: number | null;
    /**
     * 岗位
     */
    station?: null | string;
    /**
     * 岗位id
     */
    station_id?: null | string;
    /**
     * 测评名称
     */
    survey_name?: null | string;
    /**
     * 测评类型:0-16PF,1-MBTI,2-职业适应性
     */
    survey_type?: number | null;
    update_at?: null | string;
    /**
     * // FIXME：注释，后续引入用户和权限功能后，可以打开
     */
    update_by?: null | string;
}

export interface IStationRuleParams {
    /**
     * 关键字
     */
    keyword?: string;
    /**
     * 当前页
     */
    page_no?: number;
    /**
     * 每页大小
     */
    page_size?: number;
    /**
     * 岗位
     */
    station?: string;
    /**
     * 测评类型
     */
    type?: number;
}

/**
 * 查询岗位规则列表
 */
export const apiGetJobRulesList = (params: IStationRuleParams) => {
    return request<IStationRuleRes>(`${MOCK_PREFIX}/station_rule/list`, {
        method: 'POST',
        data: params,
    });
};

export interface StationListRes {
    /**
     * 岗位名称
     */
    station?: null | string;
    /**
     * 岗位Id
     */
    station_id?: null | string;
}

/**
 * 获取岗位列表
 */
export const apiGetStationList = () => {
    return request<StationListRes>(`${MOCK_PREFIX}/station_rule/station_list`, {
        method: 'GET',
    });
};

/**
 * 获取已经新增的岗位
 */
export const apiGetAddedStationList = () => {
    return request<StationListRes>(`${MOCK_PREFIX}/station_rule/station_list_added`, {
        method: 'GET',
    });
};

export type AddJobRulesParams = {
    /**
     * 岗位规则id
     */
    id?: number | null;
    /**
     * 岗位
     */
    station_list?: StationListRes[] | null;
    /**
     * 测评类型
     */
    survey_type?: number | null;
};

/**
 * 新增岗位规则
 * @param params
 * @returns
 */
export function apiAddJobRules(data: AddJobRulesParams) {
    return request(`${MOCK_PREFIX}/station_rule/add`, {
        method: 'POST',
        data,
    });
}

export interface UpdateJobRulesParams {
    /**
     * 岗位规则id
     */
    id?: number | null;
    /**
     * 岗位
     */
    station?: null | string;
    /**
     * 岗位id
     */
    station_id?: null | string;
    /**
     * 测评类型
     */
    survey_type?: number | null;
}

/**
 * 更新岗位规则
 * @param params
 * @returns
 */
export function apiUpdateJobRules(data: UpdateJobRulesParams) {
    return request(`${MOCK_PREFIX}/station_rule/update`, {
        method: 'PUT',
        data,
    });
}

/**
 * 删除岗位规则
 * @param params
 * @returns
 */
export function apiDeleteJobRules(id: number) {
    return request(`${MOCK_PREFIX}/station_rule/delete/${id}`, {
        method: 'DELETE',
    });
}
