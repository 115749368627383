import React from 'react';
import { View } from '@react-pdf/renderer';
import { WaPersonalityFactorDTO } from '@/components/PdfReporter/services/high';
import { TpDFType, useStyles } from '@/components/PdfReporter/utils/styles';
import WrapText from '@/components/MbtiPdfReporterMobile/components/WrapText';
import ScoreSlide from './ScoreSlide';

interface FactorListItemProps {
    data: Record<string, any>; // 表格每一行的数据
    scores: WaPersonalityFactorDTO; // 表格的得分数据
    pdfType: TpDFType; // pdf的类型
}

const FactorListItem: React.FC<FactorListItemProps> = props => {
    const { data, scores, pdfType } = props;
    const { commonStyles, colors } = useStyles(pdfType);

    const getColor = (score: number) => {
        if (score < 4) {
            return '#FFC5C5';
        } else if (score >= 4 && score <= 7) {
            return '#FEE6B2';
        } else {
            return '#B0EACD';
        }
    };

    return (
        <View
            style={{
                ...commonStyles.flex,
                width: 303.91,
                height: 57.65,
                borderBottom: data.factor === '抗压性' ? '' : '1 solid #dce2e7',
            }}
        >
            <View style={{ ...commonStyles.flex, width: 132.67, padding: 8.51 }}>
                <WrapText
                    width={52}
                    style={{
                        fontSize: 12.85,
                        color: '#303C42',
                    }}
                    text={data.factor || ''}
                />
                <View style={{ width: 52 }}>
                    {data.details.map((item: string) => (
                        <View style={{ ...commonStyles.flex, justifyContent: 'flex-start' }}>
                            <View
                                style={{
                                    width: 1.89,
                                    height: 1.89,
                                    borderRadius: 0.95,
                                    backgroundColor: colors.themeColor,
                                    marginRight: 4,
                                    marginBottom: data.factor === '情绪稳定性' ? 18 : 4,
                                }}
                            ></View>
                            <WrapText
                                width={46}
                                style={{
                                    fontSize: 9.07,
                                    color: '#303C42',
                                }}
                                text={item || ''}
                            />
                        </View>
                    ))}
                </View>
            </View>
            <View style={{ width: 155.36 }}>
                <ScoreSlide
                    width={148.55}
                    height={15.12}
                    score={scores[data.score]}
                    color={getColor(scores[data.score])}
                    slideRadius={7.56}
                />
            </View>
        </View>
    );
};

export default FactorListItem;
