import TextWithBlank from '@components/PdfReporter/components/TextWithBlank';
import { View, Text, Svg, Path } from '@react-pdf/renderer';
import { usePdfTheme } from '@components/MbtiPdfReporterMobile/utils/styles';
import { useStyles } from '@/components/PdfReporter/utils/styles';

function IntroductionFieldTitle(props: { text: string; style?: any }) {
    const { text, style } = props;

    return (
        <View style={{ position: 'relative', ...style }}>
            <View style={{ width: 88, height: 28 }}>
                <IntroductionFieldTitleBg />
            </View>
            <View style={{ width: 88, position: 'absolute', left: 0, top: 6, textAlign: 'center' }}>
                <Text style={{ fontSize: 13.61, lineHeight: 21.61, color: 'white' }}>{text}</Text>
            </View>
        </View>
    );
}

function IntroductionFieldTitleBg() {
    const theme = usePdfTheme();

    return (
        <Svg viewBox="0 0 150 48">
            <Path
                fillRule="evenodd"
                fill={theme.main}
                d="M23.2769752,-9.23902656e-16 L126.723025,-9.23902656e-16 C139.57913,-9.23902656e-16 150,10.7454924 150,24 C150,37.2545076 139.57913,48 126.723025,48 L23.2769752,48 C10.42087,48 3.69596479e-15,37.2545076 3.69596479e-15,24 C3.69596479e-15,10.7454924 10.42087,-9.23902656e-16 23.2769752,-9.23902656e-16 Z"
            />
        </Svg>
    );
}

function IntroductionOneRow(props: { label: string; value?: string; style?: any; labelStyle?: any; valueStyle?: any }) {
    const { label, value = '-', style, labelStyle, valueStyle } = props;
    const { styles } = useStyles();
    return (
        <View style={{ justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'flex-start', ...style }}>
            <IntroductionFieldTitle text={label} style={labelStyle} />
            <View style={{ width: 170, marginLeft: 10 }}>
                <TextWithBlank
                    style={{
                        ...styles.nameFont,
                        fontSize: 15.12,
                        lineHeight: 23.57 / 15.12,
                        letterSpacing: -1.5,
                        marginTop: 5,
                        ...valueStyle,
                    }}
                >
                    {value}
                </TextWithBlank>
            </View>
        </View>
    );
}

export default IntroductionOneRow;
