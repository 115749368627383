import { Image, Page, Text, View } from '@react-pdf/renderer';
import ys from '../assets/yeastar-pdf-home.png';
import { usePdfData, useStyles } from '../utils/styles';
import TextWithBlank from './TextWithBlank';

function PageYs() {
    const { styles } = useStyles();
    const { user_info, create_at } = usePdfData();

    const d = new Date(create_at!);
    const Y = d.getUTCFullYear();
    let M: string | number = d.getUTCMonth() + 1;
    M = `${M < 10 ? '0' : ''}${M}`;

    return (
        <Page style={{ ...styles.body }}>
            <View style={styles.homeBodyBg}>
                <Image style={styles.homeBodyBgImage} src={ys} />
            </View>
            <View style={styles.homeBodyTextForYs}>
                <View
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        flexDirection: 'row',
                    }}
                >
                    <Text>姓{'  '}名</Text>
                    <TextWithBlank
                        style={{
                            ...styles.nameFont,
                            width: 120,
                            borderBottom: '1px solid #0D1E39',
                            textAlign: 'center',
                        }}
                    >
                        {user_info?.name}
                    </TextWithBlank>

                    <Text
                        style={{
                            width: 60,
                        }}
                    >
                        毕业学校
                    </Text>
                    <Text
                        style={{
                            width: 180,
                            borderBottom: '1px solid #0D1E39',
                            textAlign: 'center',
                        }}
                    >
                        {user_info?.college}
                    </Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        flexDirection: 'row',
                        margin: '20pt 0',
                    }}
                >
                    <Text>性{'  '}别</Text>
                    <Text
                        style={{
                            width: 120,
                            borderBottom: '1px solid #0D1E39',
                            textAlign: 'center',
                        }}
                    >
                        {user_info?.sex}
                    </Text>

                    <Text
                        style={{
                            width: 60,
                        }}
                    >
                        应聘岗位
                    </Text>
                    <Text
                        style={{
                            width: 180,
                            borderBottom: '1px solid #0D1E39',
                            textAlign: 'center',
                        }}
                    >
                        {user_info?.position}
                    </Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        borderBottom: '1px solid #0D1E39',
                        position: 'absolute',
                        bottom: 40,
                        left: 0,
                        right: 0,
                        fontSize: 12,
                        lineHeight: 1,
                        paddingBottom: 8,
                    }}
                >
                    <Text>人力资源部</Text>
                    <Text>01</Text>
                    <Text>
                        {Y}年{M}月
                    </Text>
                </View>
            </View>
        </Page>
    );
}

export default PageYs;
