import { Polyline, StyleSheet, Svg, View } from "@react-pdf/renderer";
import { usePdfData, useStyles } from "../utils/styles";
import Star from "./Star";
import TextWithBlank from "./TextWithBlank";

function TablePreOccupationalFactorPoints() {
    const { commonStyles, colors } = useStyles();

    const tbStyles = StyleSheet.create({
        table: {
            textAlign: "center",
            ...commonStyles.text,
        },
        blueCellBg: {
            backgroundColor: colors.themeColor,
            color: "white",
        },
        row: {
            justifyContent: "space-between",
            alignItems: "stretch",
            flexDirection: "row",
            textAlign: "center",
            backgroundColor: "#F9F9F9",
            color: "black",
        },
        head: {
            //
        },
        cell: {
            height: 64,
            paddingLeft: 6,
            paddingRight: 6,
            lineHeight: 1,
        },
        titlePointCellText: { marginTop: 7, lineHeight: 1 },
    });

    const { career_prediction: scopes = [] } = usePdfData();

    const dataSource: {
        factor: string;
        lowScopeFeature: string;
        highScopeFeature: string;
        /**
         * 星星要定位的位置 y
         */
        y: number;
    }[] = [
        // 32, 48
        {
            factor: "心理健康",
            lowScopeFeature: "心理状态差",
            highScopeFeature: "心理状态好",
            y: (64 + 1) * 0 + 64 / 2,
        },
        {
            factor: "专业成就",
            lowScopeFeature: "专业成就潜力低",
            highScopeFeature: "专业成就潜力高",
            y: (64 + 1) * 1 + 64 / 2,
        },
        {
            factor: "创造力",
            lowScopeFeature: "创造力低",
            highScopeFeature: "创造力高",
            y: (64 + 1) * 2 + 64 / 2,
        },
        {
            factor: "适应性",
            lowScopeFeature: "适应性低",
            highScopeFeature: "适应性好",
            y: (64 + 1) * 3 + 64 / 2,
        },
    ];

    const points = scopes.map((item, itemIndex) => {
        return [item * 67 - 67 / 2, dataSource[itemIndex].y];
    });

    return (
        <View style={{ ...tbStyles.table, ...commonStyles.text, width: 547 }}>
            <View
                style={{
                    ...tbStyles.row,
                    ...tbStyles.head,
                    ...tbStyles.blueCellBg,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    height: 56,
                    alignItems: "stretch",
                }}
            >
                <View
                    style={{
                        width: 76,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                        borderTopLeftRadius: 4,
                    }}
                >
                    <TextWithBlank
                        style={{ ...commonStyles.strongText, marginTop: 22 }}
                    >
                        因素
                    </TextWithBlank>
                </View>
                <View
                    style={{
                        width: 135,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                    }}
                >
                    <TextWithBlank
                        style={{ ...commonStyles.strongText, marginTop: 22 }}
                    >
                        低分特征
                    </TextWithBlank>
                </View>
                <View
                    style={{
                        width: 200,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                    }}
                >
                    <View style={{ ...commonStyles.borderBottom, height: 30 }}>
                        <TextWithBlank
                            style={{
                                ...tbStyles.titlePointCellText,
                                ...commonStyles.strongText,
                            }}
                        >
                            标准分
                        </TextWithBlank>
                    </View>
                    <View
                        style={{
                            ...tbStyles.row,
                            ...tbStyles.blueCellBg,
                            height: 26,
                            alignItems: "stretch",
                            ...commonStyles.fontSize12,
                        }}
                    >
                        <View
                            style={{
                                width: 67,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                低
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 67,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                中
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                width: 66,
                                ...commonStyles.borderLeft,
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...tbStyles.titlePointCellText,
                                    ...commonStyles.strongText,
                                }}
                            >
                                高
                            </TextWithBlank>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        width: 136,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderRight,
                        ...commonStyles.borderBottom,
                        borderTopRightRadius: 4,
                    }}
                >
                    <TextWithBlank
                        style={{ ...commonStyles.strongText, marginTop: 22 }}
                    >
                        高分特征
                    </TextWithBlank>
                </View>
            </View>
            {dataSource.map((item, itemIndex) => {
                const isLastItem = itemIndex === dataSource.length - 1;

                return (
                    <View
                        key={item.factor}
                        style={{
                            backgroundColor: "#F9F9F9",
                            flexDirection: "row",
                            alignItems: "stretch",
                            ...commonStyles.text,
                            ...commonStyles.fontSize12,
                            ...commonStyles.borderBottom,
                            ...(isLastItem
                                ? {
                                      borderBottomLeftRadius: 4,
                                      borderBottomRightRadius: 4,
                                  }
                                : {}),
                        }}
                    >
                        <View
                            style={{
                                ...tbStyles.cell,
                                width: 76,
                                ...commonStyles.borderLeft,
                                ...(isLastItem
                                    ? { borderBottomLeftRadius: 4 }
                                    : {}),
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    ...commonStyles.strongText,
                                    paddingTop: 26,
                                }}
                            >
                                {item.factor}
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                ...tbStyles.cell,
                                ...commonStyles.borderLeft,
                                width: 135,
                                textAlign: "left",
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    paddingTop: 26,
                                }}
                            >
                                {item.lowScopeFeature}
                            </TextWithBlank>
                        </View>
                        <View
                            style={{
                                ...commonStyles.borderLeft,
                                width: 67,
                            }}
                        ></View>
                        <View
                            style={{
                                ...commonStyles.borderLeft,
                                borderLeftStyle: "dashed",
                                borderLeftColor: "#CCCCCC",
                                width: 67,
                            }}
                        ></View>
                        <View
                            style={{
                                ...commonStyles.borderLeft,
                                borderLeftStyle: "dashed",
                                borderLeftColor: "#CCCCCC",
                                width: 66,
                            }}
                        ></View>
                        <View
                            style={{
                                ...tbStyles.cell,
                                ...commonStyles.borderLeft,
                                ...commonStyles.borderRight,
                                width: 136,
                                textAlign: "left",
                                ...(isLastItem
                                    ? {
                                          borderBottomRightRadius: 4,
                                      }
                                    : {}),
                            }}
                        >
                            <TextWithBlank
                                style={{
                                    paddingTop: 26,
                                }}
                            >
                                {item.highScopeFeature}
                            </TextWithBlank>
                        </View>
                    </View>
                );
            })}
            <View
                style={{
                    position: "absolute",
                    top: 56,
                    left: 211,
                    width: 200,
                    height: 660,
                }}
            >
                <Svg
                    style={{
                        width: 200,
                        height: 660,
                    }}
                >
                    <Polyline
                        stroke={colors.themeColor}
                        strokeWidth={1}
                        points={points.map((item) => item.join(",")).join(" ")}
                    />
                </Svg>
                {dataSource.map((item, itemIndex) => {
                    return (
                        <Star
                            key={`${item.y}-${itemIndex}`}
                            top={item.y - 8}
                            left={scopes[itemIndex] * 67 - 67 / 2 - 8}
                            width={16}
                            height={16}
                            padding={2}
                            backgroundColor="#F9F9F9"
                        />
                    );
                })}
            </View>
        </View>
    );
}

export default TablePreOccupationalFactorPoints;
