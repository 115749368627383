import {
    G,
    Line,
    Path,
    Rect,
    Svg,
    Text,
    Tspan,
    View,
} from "@react-pdf/renderer";
import { calcRectWidth, rectPathD } from "../utils";
import { usePdfData, useStyles } from "../utils/styles";

function ChartSocialDesirability() {
    const { chartSTyles: cStyles, colors } = useStyles();

    const { test_validity_verification } = usePdfData();

    const width = calcRectWidth(
        test_validity_verification?.social_desirability,
        10,
        275
    );

    return (
        <View
            style={{
                width: 280,
                height: 77,
            }}
        >
            <Svg style={{ width: 280, height: 77 }} viewBox="0 0 280 77">
                <G>
                    <Rect
                        fillOpacity="0.04"
                        fill="#000000"
                        x="2"
                        y="0"
                        width="272"
                        height="56"
                    ></Rect>
                    <Rect
                        fill="#E6F6FF"
                        x="2"
                        y="0"
                        width="217"
                        height="56"
                    ></Rect>
                    <Rect
                        fill="#FFE2D6"
                        x="219"
                        y="0"
                        width="55"
                        height="56"
                    ></Rect>
                    <Line
                        x1="219.5"
                        y1="0"
                        x2="219.5"
                        y2="56"
                        stroke="#666666"
                        strokeLineCap="square"
                        strokeDasharray="4"
                    ></Line>
                </G>
                <G transform="translate(0.000000, 56.000000)">
                    <G>
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.08203125" y="13">
                                0
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(27.000000, 0.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.829101562" y="13">
                                1
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(54.000000, 0.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.200683594" y="13">
                                2
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(81.000000, 0.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.0952148438" y="13">
                                3
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(108.000000, 0.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.0205078125" y="13">
                                4
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(136.000000, 0.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.134765625" y="13">
                                5
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(163.000000, 0.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.0512695312" y="13">
                                6
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(190.000000, 0.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.354492188" y="13">
                                7
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(217.000000, 0.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.0424804688" y="13">
                                8
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(244.000000, 0.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="0.0512695312" y="13">
                                9
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                    <G transform="translate(271.000000, 0.000000)">
                        <Text
                            style={cStyles.labelText}
                            fill="#000000"
                            fillOpacity="0.6"
                        >
                            <Tspan x="-2.08886719" y="13">
                                10
                            </Tspan>
                        </Text>
                        <Path stroke="#666666" d="M2.5 0 L2.5 4" />
                    </G>
                </G>
                <G>
                    <Text
                        style={cStyles.title}
                        fill="#000000"
                        fillOpacity="0.6"
                    >
                        <Tspan x="96" y="11">
                            正常
                        </Tspan>
                    </Text>
                    <Text
                        style={cStyles.title}
                        fill="#000000"
                        fillOpacity="0.6"
                    >
                        <Tspan x="237" y="11">
                            高
                        </Tspan>
                    </Text>
                </G>
                <G>
                    <Line
                        x1="2"
                        y1="4.16333634e-15"
                        x2="2.5"
                        y2="59.5"
                        stroke="#666666"
                    ></Line>
                    <Line
                        x1="2"
                        y1="56"
                        x2="274"
                        y2="56"
                        stroke="#666666"
                    ></Line>
                </G>
                <G>
                    <Path
                        d={rectPathD(2, 20, width, 16)}
                        fill={colors.themeColor}
                    ></Path>
                    <Text
                        style={cStyles.value}
                        fill={width < 30 ? colors.themeColor : "#fff"}
                        textAnchor={width < 30 ? "start" : "end"}
                    >
                        <Tspan x={width + (width < 30 ? 8 : -4)} y="32">
                            {test_validity_verification?.social_desirability}
                        </Tspan>
                    </Text>
                </G>
            </Svg>
        </View>
    );
}
export default ChartSocialDesirability;
