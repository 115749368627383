import { StyleSheet, View } from "@react-pdf/renderer";
import { usePdfData, useStyles } from "../utils/styles";
import TextWithBlank from "./TextWithBlank";

/**
 * 选项分布
 */
function TableOptionsDistribution() {
    const { commonStyles, tableStyles } = useStyles();

    const styles = StyleSheet.create({
        text: { ...commonStyles.text },
        ...tableStyles,
    });

    const { test_validity_verification } = usePdfData();

    return (
        <View style={{ ...styles.table, ...styles.text, width: 264 }}>
            <View
                style={{
                    ...styles.tableRow,
                    ...styles.tableHeadRow,
                    color: "#FFFFFF",
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                }}
            >
                <View
                    style={{
                        ...styles.tableCell,
                        width: 66,
                        height: 36,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                        borderTopLeftRadius: 4,
                        ...commonStyles.strongText,
                    }}
                >
                    <TextWithBlank
                        style={{
                            marginTop: 11,
                        }}
                    >{`选项`}</TextWithBlank>
                </View>
                <View
                    style={{
                        ...styles.tableCell,
                        width: 66,
                        height: 36,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                    }}
                >
                    <TextWithBlank style={{ marginTop: 11 }}>A</TextWithBlank>
                </View>
                <View
                    style={{
                        ...styles.tableCell,
                        width: 66,
                        height: 36,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                    }}
                >
                    <TextWithBlank style={{ marginTop: 11 }}>B</TextWithBlank>
                </View>
                <View
                    style={{
                        ...styles.tableCell,
                        width: 66,
                        height: 36,
                        ...commonStyles.borderTop,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                        ...commonStyles.borderRight,
                        borderTopRightRadius: 4,
                    }}
                >
                    <TextWithBlank style={{ marginTop: 11 }}>C</TextWithBlank>
                </View>
            </View>
            <View
                style={{
                    ...styles.tableRow,
                    ...styles.tableBodyRow,
                    ...commonStyles.fontSize12,
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                }}
            >
                <View
                    style={{
                        ...styles.tableCell,
                        width: 66,
                        height: 36,
                        ...commonStyles.borderLeft,
                        ...commonStyles.borderBottom,
                        borderBottomLeftRadius: 4,
                    }}
                >
                    <TextWithBlank
                        style={{
                            marginTop: 11,
                        }}
                    >{`选项比例`}</TextWithBlank>
                </View>

                {test_validity_verification?.option_distribution?.map(
                    (item, itemIndex) => {
                        return (
                            <View
                                key={`${item}-${itemIndex}`}
                                style={{
                                    ...styles.tableCell,
                                    width: 66,
                                    height: 36,
                                    ...commonStyles.borderLeft,
                                    ...commonStyles.borderBottom,
                                    ...(test_validity_verification?.option_distribution &&
                                    itemIndex ===
                                        test_validity_verification
                                            ?.option_distribution?.length -
                                            1
                                        ? {
                                              ...commonStyles.borderRight,
                                              borderBottomRightRadius: 4,
                                          }
                                        : {}),
                                }}
                            >
                                <TextWithBlank style={{ marginTop: 11 }}>
                                    {item}
                                </TextWithBlank>
                            </View>
                        );
                    }
                )}
            </View>
        </View>
    );
}

export default TableOptionsDistribution;
