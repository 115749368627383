/* eslint-disable no-irregular-whitespace */
import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import { useEffect, useState } from 'react';
import fontDingTalkJinBuTi from '@components/PdfReporter/assets/fonts/DingTalk JinBuTi.ttf';
import fontAlibabaPuHuiTiBold from '@components/PdfReporter/assets/fonts/Alibaba-PuHuiTi-Bold.ttf';
import Introduction from '@components/MbtiPdfReporterMobile/components/Introduction';

import { Context } from '@components/MbtiPdfReporterMobile/utils/styles';
import { TpDFType, useStyles } from '@components/PdfReporter/utils/styles';
import {
    EPersonality,
    ETheme,
    THEME_MS_BLUE,
    THEME_MS_GREEN,
    THEME_MS_PURPLE,
    THEME_MS_YELLOW,
    THEME_YS_BLUE,
    THEME_YS_GREEN,
    THEME_YS_PURPLE,
    THEME_YS_YELLOW,
    getThemeByPersonalityName,
} from '@components/MbtiPdfReporterMobile/utils/constants';
import PageBackground from '@components/MbtiPdfReporterMobile/components/PageBackground';
import TextWithBlank from '@components/PdfReporter/components/TextWithBlank';
import { fontSize } from '@components/PdfReporter/utils/fontSize';
import ListItem from '@components/MbtiPdfReporterMobile/components/ListItem';
import Card from '@components/MbtiPdfReporterMobile/components/Card';
import Section from '@components/MbtiPdfReporterMobile/components/Section';
import Header from '@components/MbtiPdfReporterMobile/components/Header';
import { PdfDataForMbTi } from '@components/PdfReporter/services/mbti';
import AllScoreSections from '@components/MbtiPdfReporterMobile/components/AllScoreSections';
import { allData } from '@components/MbtiPdfReporterMobile/test.data';
import WrapText from './components/WrapText';

Font.register({
    family: 'DingTalk JinBuTi',
    src: fontDingTalkJinBuTi,
});
Font.register({
    family: 'Alibaba PuHuiTi',
    src: fontAlibabaPuHuiTiBold,
});

function MbtiPdfReporterMobile({
    type = 'ys',
    data: outerData,
    mbtiType,
}: {
    type?: TpDFType;
    data?: PdfDataForMbTi;
    mbtiType?: EPersonality;
}) {
    const { styles } = useStyles(type);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<PdfDataForMbTi>(outerData ?? {});

    // const mbtiType = EPersonality.ESFJ; // 东道主 蓝色
    // const mbtiType = EPersonality.ESFP; // 乐天派 黄色
    // const mbtiType = EPersonality.INTJ; // 军师 紫色
    // const mbtiType = EPersonality.ISFJ; // 守护者 蓝色
    // const mbtiType = EPersonality.ESTP; // 实践者 黄色
    // const mbtiType = EPersonality.ESTJ; // 总经理 蓝色
    // const mbtiType = EPersonality.ISTP; // 探险家 黄色
    // const mbtiType = EPersonality.ENFJ; // 教育家 绿色
    // const mbtiType = EPersonality.INFP; // 治愈者 绿色
    // const mbtiType = EPersonality.ISTJ; // 物流师 蓝色
    // const mbtiType = EPersonality.INTP; // 科学家 紫色
    // const mbtiType = EPersonality.INFJ; // 精神导师 绿色
    // const mbtiType = EPersonality.ENTJ; // 统帅 紫色
    // const mbtiType = EPersonality.ISFP; // 艺术家 黄色
    // const mbtiType = EPersonality.ENTP; // 辩论家 紫色
    // const mbtiType = EPersonality.ENFP; // 追梦人 绿色

    useEffect(() => {
        if (typeof outerData === 'undefined' && mbtiType) {
            setData({
                /** 答题有效性 */
                answerValidity: '答题时长2分钟，用时过短，报告无效',
                /** 用户基本信息 */
                userInfo: allData[mbtiType].userInfo as any,
                /** 精力支配 */
                extrovertIntrovert: [32, 68],
                /** 信息获取 */
                sensingIntuition: [17, 83],
                /** 决策方式 */
                thinkingFelling: [77, 23],
                /** 生活方式 */
                judgingPerceiving: [43, 57],
                /** 个性特征 */
                personality: allData[mbtiType].personality.replace('XXX', allData[mbtiType].userInfo.name),
                /** 优势与盲点 */
                strengthsAndBlindSpots: allData[mbtiType].strengthsAndBlindSpots,
                /** 合适的职业 */
                rightCareers: allData[mbtiType].rightCareers,
                /** 合适的工作环境 */
                rightEnv: allData[mbtiType].rightEnv,
                /** 潜在阻碍 */
                potentialObstacle: allData[mbtiType].potentialObstacle,
                /** 发展建议 */
                developmentSuggestions: allData[mbtiType].developmentSuggestions,
            });

            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [outerData, mbtiType]);

    const theme = getThemeByPersonalityName(data?.userInfo?.mbtiType);

    const themeConfig = (
        type === 'ys'
            ? {
                  [ETheme.blue]: THEME_YS_BLUE,
                  [ETheme.purple]: THEME_YS_PURPLE,
                  [ETheme.yellow]: THEME_YS_YELLOW,
                  [ETheme.green]: THEME_YS_GREEN,
              }
            : {
                  [ETheme.blue]: THEME_MS_BLUE,
                  [ETheme.purple]: THEME_MS_PURPLE,
                  [ETheme.yellow]: THEME_MS_YELLOW,
                  [ETheme.green]: THEME_MS_GREEN,
              }
    )[theme || ETheme.green];

    const defaultTextColor: string = type === 'ys' ? '#101f3a' : '#251707';

    return (
        <>
            <Document title={`卡特尔 MBTI 测评个人报告-${data?.userInfo?.name}`}>
                {typeof outerData === 'undefined' && loading ? null : (
                    <Context.Provider
                        value={{
                            type,
                            data,
                            themeConfig: themeConfig as any,
                        }}
                    >
                        <Page
                            size={[375]}
                            style={{
                                backgroundColor: themeConfig?.bg,
                                fontFamily: 'DingTalk JinBuTi',
                                color: defaultTextColor,
                            }}
                            wrap={false}
                        >
                            <PageBackground />
                            <View
                                style={{
                                    paddingTop: 30,
                                    paddingRight: 24,
                                    paddingBottom: 0,
                                    paddingLeft: 24,
                                }}
                            >
                                <Header />
                                <Introduction />
                                <AllScoreSections />
                                <Section head={<Text>个性特征</Text>} style={{ marginTop: 24 }}>
                                    <WrapText
                                        width={375 - 24 - 24}
                                        indent={2}
                                        style={{
                                            fontSize: 12,
                                            ...styles.nameFont,
                                        }}
                                        text={`${data?.personality}`}
                                    />
                                </Section>
                                <Section head={<Text>优势与盲点</Text>} style={{ marginTop: 12 }}>
                                    <Card
                                        head={
                                            <Text style={{ position: 'relative', top: 9, left: 1 }}>该类型的优势</Text>
                                        }
                                        style={{ marginTop: 1 }}
                                    >
                                        <View style={{ ...fontSize(12) }}>
                                            {data?.strengthsAndBlindSpots?.strengths?.map((item, itemIndex) => (
                                                <ListItem
                                                    key={item}
                                                    text={item}
                                                    style={{
                                                        fontSize: 12,
                                                        wrapTextWidth: 325 - 21 - 11 - 10,
                                                        ...(itemIndex === 0 ? { marginTop: 0 } : {}),
                                                    }}
                                                />
                                            ))}
                                        </View>
                                    </Card>
                                    <Card
                                        head={
                                            <Text style={{ position: 'relative', top: 9, left: 1 }}>
                                                可能存在的盲点
                                            </Text>
                                        }
                                        style={{ marginTop: 14 }}
                                    >
                                        <View style={{ ...fontSize(12) }}>
                                            {data?.strengthsAndBlindSpots?.blindSpots?.map((item, itemIndex) => (
                                                <ListItem
                                                    key={item}
                                                    text={item}
                                                    style={{
                                                        fontSize: 12,
                                                        wrapTextWidth: 325 - 21 - 11 - 10,
                                                        ...(itemIndex === 0 ? { marginTop: 0 } : {}),
                                                    }}
                                                />
                                            ))}
                                        </View>
                                    </Card>
                                </Section>
                                <Section head={<Text>适合的职业</Text>} style={{ marginTop: 24 }}>
                                    <WrapText
                                        width={375 - 24 - 24}
                                        indent={2}
                                        style={{
                                            fontSize: 12,
                                            position: 'relative',
                                            top: -3,
                                        }}
                                        text={`${data?.rightCareers}`}
                                    />
                                </Section>
                                <Section head={<Text>适合的工作环境</Text>} style={{ marginTop: 12 }}>
                                    <View style={{ ...fontSize(12), paddingLeft: 10, position: 'relative', top: -4 }}>
                                        {data?.rightEnv?.map(item => (
                                            <ListItem
                                                key={item}
                                                text={item}
                                                style={{
                                                    fontSize: 12,
                                                    wrapTextWidth: 375 - 24 - 24 - 10 - 10,
                                                }}
                                            />
                                        ))}
                                    </View>
                                </Section>
                                <Section head={<Text>潜在阻碍</Text>} style={{ marginTop: 12 }}>
                                    <View style={{ ...fontSize(12), paddingLeft: 10, position: 'relative', top: -4 }}>
                                        {data?.potentialObstacle?.map(item => (
                                            <ListItem
                                                key={item}
                                                text={item}
                                                style={{
                                                    fontSize: 12,
                                                    wrapTextWidth: 375 - 24 - 24 - 10 - 10,
                                                }}
                                            />
                                        ))}
                                    </View>
                                </Section>
                                <Section head={<Text>发展建议</Text>} style={{ marginTop: 12 }}>
                                    <View style={{ ...fontSize(12), paddingLeft: 10, position: 'relative', top: -4 }}>
                                        {data?.developmentSuggestions?.map(item => (
                                            <ListItem
                                                key={item}
                                                text={item}
                                                style={{
                                                    fontSize: 12,
                                                    wrapTextWidth: 375 - 24 - 24 - 10 - 10,
                                                }}
                                            />
                                        ))}
                                    </View>
                                </Section>
                            </View>
                            <View style={{ paddingBottom: 36 }}>
                                <TextWithBlank
                                    style={{
                                        color: themeConfig?.footerTextColor,
                                        opacity: 0.5,
                                        fontSize: 80,
                                        lineHeight: 1,
                                        letterSpacing: -6.5,
                                    }}
                                >
                                    {data?.userInfo?.mbtiType}
                                </TextWithBlank>
                            </View>
                        </Page>
                    </Context.Provider>
                )}
            </Document>
        </>
    );
}

export default MbtiPdfReporterMobile;
