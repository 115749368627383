import { View, Image } from '@react-pdf/renderer';
import { usePdfTheme, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';
import StarBaby from '@components/MbtiPdfReporterMobile/components/StarBaby';

function PageBackground() {
    const pdfType = usePdfType();
    const theme = usePdfTheme();

    return theme ? (
        <>
            <View
                style={{
                    width: 375,
                    // height: 1877,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }}
            >
                <Image src={theme?.bg1} />
            </View>
            {pdfType === 'ms' ? (
                <View style={{ width: 128, height: 128, position: 'absolute', right: 24, bottom: 380 }}>
                    <StarBaby color={theme?.starBabyColor} />
                </View>
            ) : null}
            <View style={{ width: 375, height: 675, position: 'absolute', left: 0, bottom: 0 }}>
                <Image src={theme?.bg2} />
            </View>
        </>
    ) : null;
}

export default PageBackground;
