import { View } from '@react-pdf/renderer';
import ScoreTitle from '@components/MbtiPdfReporterA4/components/ScoreTitle';
import SliderBar, { TSliderValue } from '@components/MbtiPdfReporterA4/components/SliderBar';
import { usePdfTheme, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';
import ScoreCard, { TScoreCardData } from '@components/MbtiPdfReporterA4/components/ScoreCard';

function ScoreSection(props: {
    index: number;
    title: string;
    sliderValues: [TSliderValue, TSliderValue];
    scoreCardData: [TScoreCardData, TScoreCardData];
}) {
    const pdfType = usePdfType();
    const theme = usePdfTheme();
    const { index, title, sliderValues, scoreCardData } = props;

    const type: 'left' | 'right' = sliderValues[0].value > sliderValues[1].value ? 'left' : 'right';

    const positions = {
        0: {
            left: 0,
            top: 0,
        },
        1: {
            left: 244 + 26,
            top: 0,
        },
        2: {
            left: 0,
            top: 207,
        },
        3: {
            left: 244 + 26,
            top: 207,
        },
    }[index];

    const RealScoreCard = pdfType === 'ms' ? ScoreCard.ScoreCardMsA4 : ScoreCard;

    return (
        <View style={{ width: 244, position: 'absolute', ...positions }}>
            <ScoreTitle color={theme?.main} title={title} />
            <SliderBar
                values={sliderValues}
                style={{
                    marginTop: 17.5,
                    marginBottom: 22,
                    ...(pdfType === 'ys'
                        ? {
                              width: 157,
                              height: 9,
                          }
                        : {}),
                }}
                type={type}
            />
            <RealScoreCard type={type} data={scoreCardData} />
        </View>
    );
}

export default ScoreSection;
