import { View } from "@react-pdf/renderer";
import { useStyles } from "../utils/styles";
import TablePersonalityFactorPoints from "./TablePersonalityFactorPoints";
import TextWithBlank from "./TextWithBlank";

/**
 * 6、16 种人格因素得分表
 */
function Reporter6() {
    const { commonStyles } = useStyles();

    return (
        <View style={{ height: "auto" }} break>
            <TextWithBlank
                style={commonStyles.heading}
            >{`6、16 种人格因素得分表`}</TextWithBlank>
            <TextWithBlank
                style={{
                    ...commonStyles.text,
                    ...commonStyles.fontSize12,
                    color: "#A9AAAB",
                    marginTop: 8,
                }}
            >{`得分若大于8分或小于3分表明该维度明显突出，若4-7分之间表明其与大多数人一样。`}</TextWithBlank>
            <View style={{ marginTop: 8 }}>
                <TablePersonalityFactorPoints />
            </View>
        </View>
    );
}

export default Reporter6;
