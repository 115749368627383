import HeaderSearchDropdown from '@/pages/components/HeaderSearchDropdown';
import { returnArray } from '@/utils';
import moment from 'moment';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, Icon, Input, Menu, Table, Tag, Tooltip, message } from 'ysd-pp';
import { SearchIcon } from 'ysd-pp/es/icon';
import { apiCompanyTypeGet, apiEntranceListGet, apiSurveyTypeListGet, apiReportRegenerate } from '../../services';
import styles from './index.module.less';
// import { download } from "@/utils";
import {
    EntranceStatusOptions,
    REQUEST_BASE_URL,
    ReportStatusOptions,
    // SEARCH_LIMIT,
    StatusOptions,
    // SurveyTypeOptions,
} from '@/utils/constants';
import { getToken } from '@/utils/getToken';
import useCopy from '@/utils/hooks/useCopy';
import { hasPermissions } from '@/utils/permissions/index';
import { useRequest } from '@/utils/request';
import axios from 'axios';
import classNames from 'classnames';
import type { TablePaginationConfig } from 'ysd-pp';
import LinkModal from './LinkModal';
import { useDebounceFn } from 'ahooks';
import MbtiValidModal from './MbtiValidModal';
// import { useDebounce } from 'ahooks';

interface IAppraisalsListProps {
    noTabs?: boolean;
}
interface IDownloadParams {
    entrance_id?: string;
    type?: string;
    survey_type?: string | null;
}
const AppraisalsList = (props: IAppraisalsListProps) => {
    const { noTabs } = props;
    /** 分页抽出来管理 */
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        total: 0,
        current: 1,
        pageSize: 10,
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    // const [inputValue, setInputValue] = useState('');
    const copy = useCopy();
    const [disabled, setDisabled] = useState(true);
    // 获取公司类型
    const { data: companyList, runAsync: getCompanyList } = useRequest(() =>
        apiCompanyTypeGet().then(res => {
            return returnArray(res?.data);
        }),
    );
    useEffect(() => {
        getCompanyList();
    }, []);
    const companyOptions = useMemo(() => {
        return companyList?.map(item => {
            return {
                label: item?.name,
                value: item?.code,
            };
        });
    }, [companyList]);
    // 获取公司类型
    const { data: surveyTypeList, runAsync: getSurveyTypeList } = useRequest(() =>
        apiSurveyTypeListGet().then(res => {
            return returnArray(res?.data);
        }),
    );
    useEffect(() => {
        getSurveyTypeList();
    }, []);
    const surveyTypeOptions = useMemo(() => {
        return surveyTypeList?.map(item => {
            return {
                label: item?.name,
                value: item?.code,
            };
        });
    }, [surveyTypeList]);
    // 下载组织报告
    const surveyTypeMenu = useMemo(() => {
        return (
            <Menu onClick={params => downloadExcel?.({ survey_type: params.key })}>
                {surveyTypeList?.map(item => {
                    return (
                        <Menu.Item key={item?.code}>
                            <a>{item?.name}</a>
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
    }, [surveyTypeList]);
    // 列表api;
    const {
        loading,
        run: runListData,
        data: data,
    } = useRequest(
        params => {
            return apiEntranceListGet({
                page_no: pagination.current,
                page_size: pagination.pageSize,
                ...params,
            }).then(res => {
                setPagination({
                    pageSize: params?.page_size || 10,
                    current: params?.page_no || 1,
                    total: res?.data?.total || 0,
                });
                // return Array.isArray(res?.result?.results) ? res?.result?.results : [];
                return Array.isArray(res?.data?.list) ? res?.data?.list : [];
            });
        },
        {
            manual: false,
        },
    );

    const [searchParamsLocal, setSearchParamsLocal] = useState<Record<string, any>>({});

    const [pdfLoadings, setPDFLoadings] = useState<Record<string, boolean>>({});
    const [refreshLoadings, setRefreshLoadings] = useState<Record<string, boolean>>({});
    // 生成链接弹窗
    const [linkVisible, setLinkVisible] = useState(false);
    // 测评有效期弹窗
    const [MbtiValidVisible, setMbtiValidVisible] = useState(false);
    const showLinkVisible = () => {
        setLinkVisible(true);
    };
    const downloadPDF = record => {
        const key = record?.entrance_id ? `${record?.entrance_id}` : '';

        if (key) {
            setPDFLoadings(prevState => ({
                ...prevState,
                [key]: true,
            }));
        }
        axios
            .get(
                `${import.meta.env.PROD ? '' : 'http://localhost:4322'}/d/pdf/${
                    record?.company === 0 ? 'ys' : 'ms'
                }/${record?.entrance_id}`,
                {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                    params: {
                        survey_type: record.survey_type,
                    },
                },
            )
            .then(res => {
                const blob = new Blob([res.data], {
                    type: 'application/pdf',
                });
                const dom = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                dom.href = url;
                const filename =
                    res.headers?.['content-disposition']?.replace(/^.*filename=(.*)$/, '$1') || '16PF测评结果.pdf';
                dom.download = decodeURIComponent(filename);
                dom.style.display = 'none';
                document.body.appendChild(dom);
                dom.click();
                dom.parentNode?.removeChild(dom);
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                if (err?.response?.status === 404) {
                    message.error('报告不存在');
                } else {
                    message.error('下载失败');
                }
            })
            .finally(() => {
                setPDFLoadings(prevState => ({
                    ...prevState,
                    [key]: false,
                }));
            });
    };
    const { runAsync: regeneratePDF } = useRequest(data => {
        return apiReportRegenerate(data);
    });
    const refreshPDF = async record => {
        const key = record?.entrance_id ? `${record?.entrance_id}` : '';

        if (key) {
            setRefreshLoadings(prevState => ({
                ...prevState,
                [key]: true,
            }));
        }
        const res = await regeneratePDF({ entrance_id: record?.entrance_id });
        if (res?.code === 200) {
            message.success('重新生成成功');
            handleReFreshTable();
        } else {
            message.error(res?.message);
        }
        setRefreshLoadings(prevState => ({
            ...prevState,
            [key]: false,
        }));
    };
    // 表头搜索回调（点击确认按钮，或者回车）
    const handleHeaderCellSearch = () => {
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            current: pNumber,
            page_size: pSize,
            ...searchColumns
        } = searchParamsLocal;
        setSearchParamsLocal(searchColumns);
        handleTableChange?.({
            searchColumns,
            current: 1,
            page_size: pSize,
        });
    };

    // 表头搜索值改变回调
    const handleHeaderCellSearchValueChange = (dataIndex: string, value: any, immediately?: boolean) => {
        const params = searchParamsLocal;
        params[dataIndex] = value;
        setSearchParamsLocal(params);
        if (immediately) {
            handleTableChange?.({
                searchParamsLocal,
                searchColumns: params,
                current: 1,
            });
        }
    };
    const getColumnSearchProps = (dataIndex, _searchType = 'input') => ({
        filterDropdown: ({
            // setSelectedKeys,
            // selectedKeys,
            confirm,
            filterListSelected,
            filters,
        }) => {
            const _searchParamsLocal = searchParamsLocal || {};

            if (filterListSelected !== undefined) {
                _searchParamsLocal[dataIndex] = filterListSelected;
            }
            return (
                <>
                    <HeaderSearchDropdown
                        searchParams={{ ...searchParamsLocal }}
                        confirm={confirm}
                        columnItem={
                            {
                                dataIndex,
                                searchType: _searchType,
                                filterList: filters,
                            } as any
                        }
                        onSearch={handleHeaderCellSearch}
                        onSearchValueChange={handleHeaderCellSearchValueChange}
                        // rangePikerProps={rangePikerProps}
                    />
                </>
            );
        },
        filterIcon: () => {
            let filtered = false;
            if (_searchType === 'input' || _searchType === 'radio') {
                filtered = !!searchParamsLocal?.[`${dataIndex}`] || searchParamsLocal?.[`${dataIndex}`] === 0;
            } else {
                filtered = !!searchParamsLocal?.[`${dataIndex}`] && searchParamsLocal?.[`${dataIndex}`]?.length;
            }
            if (_searchType === 'input') {
                return (
                    <SearchIcon
                        className={classNames(styles['search-icon'], styles[filtered ? 'search-icon-active' : ''])}
                    />
                );
            }
            return (
                <Icon
                    type="filter"
                    className={classNames(styles['search-icon'], styles[filtered ? 'search-icon-active' : ''])}
                />
            );
        },
        // filteredValue: searchParamsLocal?.[`${dataIndex}`] || null, //此处null必要
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        // onFilterDropdownVisibleChange: (visible) => {
        //   if (visible) {
        //     setTimeout(() => searchInput.select(), 100);
        //   }
        // },
    });
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            render: (val: string) => {
                return val ? <Tooltip type="ellipsisTooltip" title={val} /> : '-';
            },
            width: 90,
            searchType: 'input',
            ...getColumnSearchProps('name'),
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            render: (val: string) => {
                return val ? <Tooltip type="ellipsisTooltip" title={val} /> : '-';
            },
            width: 120,
            ...getColumnSearchProps('phone'),
        },
        {
            title: '品牌',
            dataIndex: 'company',
            render: (val: number) => {
                return <Tooltip type="ellipsisTooltip" title={val === 0 ? 'Yeastar' : 'Milesight'} />;
            },
            width: 80,
            filters: companyList?.map(item => {
                return {
                    label: item?.name,
                    value: item?.code,
                };
            }),
            ...getColumnSearchProps('company', 'radio'),
        },
        {
            title: '测评工具',
            dataIndex: 'survey_type',
            render: (val: number) => <div>{surveyTypeOptions?.find(item => item?.value === val)?.label}</div>,
            width: 80,
            // width: 100,
            filters: surveyTypeOptions,
            ...getColumnSearchProps('survey_type', 'radio'),
        },
        {
            title: 'URL',
            dataIndex: 'url',
            render: (val: string, record) => {
                let url = val;
                if (!import.meta.env.PROD) {
                    url = val?.replace('client/', `${record?.company === 0 ? 'ys' : 'ms'}.html`);
                }
                return val ? (
                    <div className={styles['url-wrap']}>
                        <Tooltip
                            type="ellipsisTooltip"
                            title={url}
                            className={styles['test-url']}
                            overlayClassName={styles['test-url-tooltip']}
                        />
                        <Tooltip type="tooltip" title="复制">
                            <Icon type="copy" className={styles['copy-icon']} onClick={() => copy('复制成功', url)} />
                        </Tooltip>
                    </div>
                ) : (
                    '-'
                );
            },
            width: 150,
        },
        {
            title: 'URL时效性',
            dataIndex: 'effective_time',
            render: (val: string) => {
                return val ? <Tooltip type="ellipsisTooltip" title={`${val}h`} /> : '-';
            },
            width: 90,
        },
        {
            title: '答题状态',
            dataIndex: 'entrance_status',
            render: (val: number) => {
                // 0 未答题 1 答题中 2 已答题 3 已超时
                return (
                    <Tag
                        color={val === 1 ? 'blue' : val === 2 ? 'green' : val === 3 ? 'red' : 'gray'}
                        type="simple"
                        className={styles['success-tag']}
                    >
                        {EntranceStatusOptions?.find(item => item?.value === val)?.label}
                    </Tag>
                );
            },
            width: 96,
            filters: EntranceStatusOptions,
            ...getColumnSearchProps('entrance_status', 'radio'),
        },
        {
            title: '报告状态',
            dataIndex: 'report_status',
            render: (val: number) => {
                return (
                    <Tag color={val === 1 ? 'green' : 'gray'} type="simple" className={styles['success-tag']}>
                        {ReportStatusOptions?.find(item => item?.value === val)?.label}
                    </Tag>
                );
            },
            width: 96,
            filters: ReportStatusOptions,
            ...getColumnSearchProps('report_status', 'radio'),
        },
        {
            title: 'URL生成时间',
            dataIndex: 'url_create_time',
            render: (val: string) => {
                return val ? <Tooltip type="ellipsisTooltip" title={moment(val).format('YYYY/MM/DD HH:mm')} /> : '-';
            },
            width: 130,
            ...getColumnSearchProps('url_create_time', 'rangePicker'),
        },
        {
            title: 'URL状态',
            dataIndex: 'is_entrance_expired',
            render: (val: number) => {
                return (
                    <Tag color={val === 1 ? 'gray' : 'green'} type="simple" className={styles['success-tag']}>
                        {StatusOptions?.find(item => item?.value === val)?.label}
                    </Tag>
                );
            },
            width: 100,
            filters: StatusOptions,
            ...getColumnSearchProps('url_status', 'radio'),
        },
        {
            title: '操作者',
            dataIndex: 'url_create_user_name',
            render: (val: string) => {
                return val ? <Tooltip type="ellipsisTooltip" title={val} /> : '-';
            },
            width: 96,
            ...getColumnSearchProps('url_create_user_name'),
        },
        {
            // width: 80,
            width: 56,
            title: '操作',
            dataIndex: 'operations',
            fixed: 'right',
            align: 'center',
            render: (_, record: any) => {
                return (
                    <div className={styles['btn-group']}>
                        {hasPermissions('entranceDownloadReport') ? (
                            record?.entrance_status === 2 && record?.report_status !== 1 ? (
                                <Tooltip type="tooltip" title="重新生成报告" className={styles['download-btn-wrap']}>
                                    <Button
                                        type="link"
                                        disabled={record?.entrance_status !== 2}
                                        className={styles['download-btn']}
                                        onClick={() => refreshPDF(record)}
                                    >
                                        {refreshLoadings?.[record.entrance_id] ? (
                                            <Icon type="loading" spin className={styles['download-icon']} />
                                        ) : (
                                            <Icon type="refresh" className={styles['download-icon']} />
                                        )}
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Tooltip type="tooltip" title="下载报告" className={styles['download-btn-wrap']}>
                                    {pdfLoadings?.[record.entrance_id] ? (
                                        <Button
                                            type="link"
                                            disabled={record?.report_status !== 1 || record?.entrance_status !== 2}
                                            className={styles['loading-btn']}
                                        >
                                            <Icon type="loading" spin className={styles['download-icon']} />
                                        </Button>
                                    ) : (
                                        <Button
                                            type="link"
                                            disabled={record?.report_status !== 1 || record?.entrance_status !== 2}
                                            className={styles['download-btn']}
                                            onClick={() => downloadPDF(record)}
                                        >
                                            <Icon type="download" className={styles['download-icon']} />
                                        </Button>
                                    )}
                                </Tooltip>
                            )
                        ) : null}
                    </div>
                );
            },
        },
    ];
    // 下载excel
    const downloadExcel = params => {
        message.loading({
            content: '组织报告下载中...',
            key: 'downloadExcel',
            duration: 0,
        });
        axios
            .get(`/oa_web_survey/report/export/excel`, {
                responseType: 'blob',
                //请求头携带token
                headers: { Authorization: `Bearer ${getToken()} ` },
                baseURL: REQUEST_BASE_URL,
                params: params,
            })
            .then(res => {
                const blob = new Blob([res.data], {
                    type: 'application/xlsx',
                });
                const dom = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                dom.href = url;
                dom.download = decodeURIComponent(
                    `${
                        params?.survey_type === '1' ? 'MBTI' : params?.survey_type === '0' ? '16PF' : '职场适应性'
                    }性格测评组织报告${moment(new Date()).format('YYYYMMDD')}.xlsx`,
                );
                dom.style.display = 'none';
                document.body.appendChild(dom);
                dom.click();
                dom.parentNode?.removeChild(dom);
                window.URL.revokeObjectURL(url);
                message.success({
                    content: '组织报告下载成功',
                    key: 'downloadExcel',
                    duration: 3,
                });
            })
            .catch(err => {
                console.log(err);
                message.error({
                    content: '组织报告下载失败',
                    key: 'downloadExcel',
                    duration: 3,
                });
            });
    };
    const downloadPDFs = params => {
        message.loading({
            content: '批量下载中...',
            key: 'downloadPDFs',
            duration: 0,
        });
        axios
            .post(
                `${import.meta.env.PROD ? '' : 'http://localhost:4322'}/d/pdfs`,
                {
                    entrances: params,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                    responseType: 'blob',
                },
            )
            .then(res => {
                const blob = new Blob([res.data], {
                    type: 'application/zips',
                });
                const dom = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                dom.href = url;
                const filename = '批量报告下载.zip';
                dom.download = decodeURIComponent(filename);
                dom.style.display = 'none';
                document.body.appendChild(dom);
                dom.click();
                dom.parentNode?.removeChild(dom);
                window.URL.revokeObjectURL(url);
                setSelectedRowKeys([]);
                loadingRef.current = false;
                message.destroy();
                message.success({
                    content: '批量下载成功',
                    key: 'downloadPDFs',
                    duration: 3,
                });
            })
            .catch(err => {
                console.log(err);
                message.destroy();
                loadingRef.current = false;
                message.error({
                    content: '批量下载失败',
                    key: 'downloadPDFs',
                    duration: 3,
                });
            });
        // .finally(() => {
        //     setSelectedRowKeys([]);
        // });
    };
    // 批量下载
    const loadingRef = useRef(false);
    const bulkDownload = () => {
        if (loadingRef.current) {
            return;
        }
        loadingRef.current = true;
        if (selectedRowKeys.length) {
            const list = [] as IDownloadParams[];
            data?.forEach(item => {
                if (item?.entrance_id && selectedRowKeys.includes(item?.entrance_id)) {
                    list.push({
                        entrance_id: item?.entrance_id,
                        type: item?.company === 0 ? 'ys' : 'ms',
                        survey_type: item?.survey_type?.toString(),
                    });
                }
            });
            downloadPDFs(list);
        }
    };

    const handleTableChange = (params: Record<string, any>) => {
        const {
            searchColumns = searchParamsLocal,
            // search,
            current = 1,
            pageSize = 10,
        } = params || {};
        // updateTime 时间搜索字段处理
        const { url_create_time, ...restSearchColumns } = searchColumns;
        const newSearchValue = url_create_time
            ? {
                  ...restSearchColumns,
                  url_create_time_start: url_create_time?.[0],
                  url_create_time_end: url_create_time?.[1],
              }
            : restSearchColumns;
        // 特殊处理字段
        delete newSearchValue.current;
        runListData({
            ...newSearchValue,
            page_no: current,
            page_size: pageSize,
        });
    };

    const handleReFreshTable = () => {
        handleTableChange({
            searchColumns: { ...searchParamsLocal },
            current: 1,
        });
    };
    const onSelectChange = (record, selected) => {
        const _selectedRowKeys = [...selectedRowKeys];
        if (selected) {
            _selectedRowKeys.push(record?.entrance_id);
        } else {
            const delIndex = _selectedRowKeys.findIndex(item => {
                return item === record.entrance_id;
            });
            _selectedRowKeys.splice(delIndex, 1);
        }
        setSelectedRowKeys(_selectedRowKeys);
    };
    const onSelectAll = (selected, _selectionRows, changeRows) => {
        let _selectedRowKeys = [...selectedRowKeys];
        if (selected) {
            _selectedRowKeys = [..._selectedRowKeys, ...changeRows.map(item => item.entrance_id)];
        } else {
            _selectedRowKeys = _selectedRowKeys.filter(item => {
                return !changeRows.some(val => {
                    return val.entrance_id === item;
                });
            });
        }
        setSelectedRowKeys(_selectedRowKeys);
    };
    useEffect(() => {
        if (selectedRowKeys.length === 0) {
            setDisabled(true);
            return;
        }
        let count = 0;
        selectedRowKeys.forEach(item => {
            data?.forEach(subItem => {
                if (subItem?.entrance_id === item && subItem?.report_status === 1) {
                    count++;
                    return;
                }
            });
        });
        if (count > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [selectedRowKeys]);
    const rowSelection = {
        fixed: true,
        selectedRowKeys,
        onSelect: onSelectChange,
        onSelectAll: onSelectAll,
        getCheckboxProps: (record: any) => ({
            disabled: record?.entrance_status !== 2 || record?.report_status !== 1,
        }),
    };

    // const isSearch = useRef(false); // 是否触发table搜索，只有当输入框onChange时触发

    // const debounceValue = useDebounce(inputValue, { wait: 300 });

    // useEffect(() => {
    //     if (debounceValue !== undefined && isSearch.current) {
    //         const obj = searchParamsLocal;
    //         obj.keyword = debounceValue;
    //         setSearchParamsLocal(prevState => ({
    //             ...prevState,
    //             ...obj,
    //         }));
    //         handleTableChange(obj);
    //         isSearch.current = false;
    //     }
    // }, [debounceValue]);
    // const handleInputChange = e => {
    //     // 通过复制方式输入时直接过滤前后空格
    //     let value: string;
    //     value = e?.nativeEvent?.inputType === 'insertFromPaste' ? e.target?.value?.trim() : e.target?.value;
    //     if (e.target.value.length > SEARCH_LIMIT) {
    //         value = value.substring(0, SEARCH_LIMIT);
    //     }
    //     setInputValue?.(value);
    //     isSearch.current = true;
    // };

    const handleSearchChange = (e: any) => {
        const value = e.target.value?.trim();
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            current: pNumber,
            page_size: pSize,
            ...searchColumns
        } = searchParamsLocal;
        setSearchParamsLocal({ ...searchColumns, keyword: value });
        handleTableChange?.({
            searchColumns: {
                ...searchColumns,
                keyword: value,
            },
            current: 1,
            page_size: pSize,
        });
    };

    const { run: debounceSearchChange } = useDebounceFn(handleSearchChange, { wait: 300 });

    return (
        <div>
            <div className={styles['table-toolbar']}>
                <div className={styles['btn-wrap']}>
                    {hasPermissions('entranceGenerateUrl') && (
                        <Button type="primary" className={styles['render-link-btn']} onClick={showLinkVisible}>
                            生成测评链接
                        </Button>
                    )}
                    <Button
                        type="default"
                        onClick={bulkDownload}
                        disabled={disabled}
                        className={styles['render-link-btn']}
                    >
                        批量报告下载
                    </Button>
                    {hasPermissions('entranceDownloadAllReport') && (
                        <Dropdown overlay={surveyTypeMenu} className={styles['download-excel']}>
                            <Button type="default">
                                <span>组织报告下载</span>
                                <Icon type="chevron-down" className={styles['render-link-btn-icon']} />
                            </Button>
                        </Dropdown>
                    )}
                    {hasPermissions('surveyTimeUpdate') && (
                        <Button
                            type="default"
                            onClick={() => setMbtiValidVisible(true)}
                            className={styles['render-link-btn']}
                        >
                            测评有效期设置
                        </Button>
                    )}
                    <div className={styles['table-search']}>
                        <Input
                            allowClear
                            placeholder="Search"
                            className={styles['table-search-input']}
                            onChange={debounceSearchChange}
                            suffix={<SearchIcon />}
                        />
                    </div>
                </div>
                {/* <Input
                    type="text"
                    className={styles['table-header-search-input']}
                    suffix={!inputValue && <Icon type="icon-search" colorType="light" />}
                    onChange={handleInputChange}
                    value={inputValue}
                    placeholder="搜索"
                    allowClear={!!inputValue}
                /> */}
            </div>

            <Table
                loading={loading}
                rowKey="entrance_id"
                columns={columns as any}
                dataSource={data || []}
                onChange={handleTableChange}
                rowSelection={rowSelection as any}
                pagination={{
                    ...pagination,
                    onReload: handleReFreshTable,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50'],
                }}
                className={styles[loading ? 'hidden-empty' : '']}
                // pagination={false}
                scroll={{ x: 120 * columns.length, y: 'var(--scroll-y, 200)' }}
                // 处理表格的可滚宽高
                style={
                    {
                        ['--scroll-y']: noTabs ? 'calc(100vh - 252px)' : 'calc(100vh - 292px)',
                    } as CSSProperties
                }
            />
            <LinkModal
                visible={linkVisible}
                setVisible={setLinkVisible}
                companyOptions={companyOptions}
                surveyTypeOptions={surveyTypeOptions}
                refreshList={handleReFreshTable}
            />
            <MbtiValidModal visible={MbtiValidVisible} onCancel={() => setMbtiValidVisible(false)} />
        </div>
    );
};

export default AppraisalsList;
