import { View, Text } from '@react-pdf/renderer';
import IconInfo from '@components/MbtiPdfReporterMobile/components/IconInfo';
import Logo from '@components/MbtiPdfReporterMobile/components/Logo';
import { usePdfData } from '../utils/styles';

function Header() {
    const { answerValidity } = usePdfData();

    return (
        <View style={{ height: 29, width: '100%', position: 'relative' }}>
            <Logo />
            {answerValidity ? (
                <View
                    style={{
                        height: 29,
                        position: 'absolute',
                        right: 0,
                        top: 9,
                        paddingLeft: 13,
                    }}
                >
                    <View style={{ width: 9, height: 9, position: 'absolute', top: 1 }}>
                        <IconInfo />
                    </View>
                    <Text style={{ fontSize: 9 }}>{answerValidity}</Text>
                </View>
            ) : null}
        </View>
    );
}

export default Header;
