import { StyleSheet, View } from "@react-pdf/renderer";
import { usePdfData, useStyles } from "../utils/styles";
import ChartSocialDesirability from "./ChartSocialDesirability";
import Circle from "./Circle";
import TableOptionsDistribution from "./TableOptionsDistribution";
import TextWithBlank from "./TextWithBlank";

/**
 * 测试有效性检验
 */
function TestValidityCheck() {
  const { commonStyles, tableStyles, colors } = useStyles();

  const styles = StyleSheet.create({
    heading: {
      ...commonStyles.heading,
      color: "#000000",
    },
    paragraph: {
      // textIndent: 32,
      marginTop: 4,
    },
    text: {
      ...commonStyles.text,
      color: "#000000",
    },
    strong: {
      ...commonStyles.strongText,
    },
    container: {
      marginTop: 16,
    },
    ...tableStyles,
  });

  const { test_validity_verification } = usePdfData();

  return (
    <View style={{ height: "auto", marginTop: 40 }}>
      <TextWithBlank style={styles.heading}>{`测验有效性检验：`}</TextWithBlank>
      <TextWithBlank
        style={{
          ...styles.paragraph,
          ...styles.text,
          color: colors.themeColor,
          marginTop: 8,
        }}
      >
        {`　　${test_validity_verification?.description}`}
      </TextWithBlank>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: 16,
          marginBottom: 12,
          paddingLeft: 13,
        }}
      >
        <Circle style={{ position: "absolute", top: 5, left: 0 }} />
        <TextWithBlank
          style={{ ...styles.text, ...styles.strong, width: "auto" }}
        >{`作答时长：`}</TextWithBlank>
        <TextWithBlank style={{ ...styles.text, width: "auto" }}>
          {test_validity_verification?.answer_duration}
        </TextWithBlank>
      </View>

      <View
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View style={{ width: 264 }}>
          <View
            style={{
              marginBottom: 8,
              paddingLeft: 13,
            }}
          >
            <Circle style={{ position: "absolute", top: 4, left: 0 }} />
            <TextWithBlank
              style={{
                ...styles.text,
                ...styles.strong,
              }}
            >{`选项分布：`}</TextWithBlank>
          </View>
          <TableOptionsDistribution />
          <TextWithBlank
            style={{
              ...commonStyles.fontSize12,
              marginTop: 8,
            }}
          >
            {test_validity_verification?.option_distribution_description}
          </TextWithBlank>
        </View>
        <View style={{ width: 300, position: "relative", left: 18 }}>
          <View
            style={{
              marginBottom: 8,
              paddingLeft: 13,
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Circle style={{ position: "absolute", top: 4, left: 0 }} />

            <TextWithBlank
              style={{
                ...commonStyles.text,
                ...styles.strong,
                width: "auto",
              }}
            >{`社会称许性 `}</TextWithBlank>
            <TextWithBlank
              style={{
                width: "auto",
                position: "relative",
                top: -6,
              }}
            >
              {`*`}
            </TextWithBlank>
            <TextWithBlank
              style={{
                width: "auto",
              }}
            >
              {`：`}
            </TextWithBlank>
          </View>
          <ChartSocialDesirability />
          <TextWithBlank
            style={{
              ...commonStyles.fontSize12,
              marginTop: 2,
            }}
          >
            {test_validity_verification?.social_desirability_description}
          </TextWithBlank>
        </View>
      </View>
      <TextWithBlank
        style={{
          ...commonStyles.fontSize12,
          marginTop: 8,
          color: "#A9AAAB",
        }}
      >
        {`* 注：社会称许性是指答题者按照对题目的社会价值判断而不是自己的实际情况做出回答的倾向。`}
      </TextWithBlank>
    </View>
  );
}

export default TestValidityCheck;
