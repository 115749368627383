import { View, Text } from '@react-pdf/renderer';
import IconInfo from '@components/MbtiPdfReporterA4/components/IconInfo';
import Logo from '@components/MbtiPdfReporterA4/components/Logo';
import { usePdfData } from '@/components/MbtiPdfReporterMobile/utils/styles';

function Header() {
    const { answerValidity } = usePdfData();

    return (
        <View style={{ height: 83, paddingTop: 40, width: '100%', position: 'relative' }} fixed>
            <Logo />
            {answerValidity ? (
                <View
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 13 + 40,
                        paddingLeft: 15,
                    }}
                >
                    <View style={{ width: 8.51, height: 8.51, position: 'absolute', top: 1 }}>
                        <IconInfo />
                    </View>
                    <Text style={{ fontSize: 8.51 }}>{answerValidity}</Text>
                </View>
            ) : null}
        </View>
    );
}

export default Header;
