// 岗位规则对应的测评工具
export const surveyTypeList = [
    {
        value: 0,
        label: '16PF',
    },
    {
        value: 2,
        label: '职场适应性',
    },
];
