import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { useStyles } from '@/components/PdfReporter/utils/styles';

interface ScoreSlideProps {
    width: number; // 滑动条的宽度
    height: number; // 滑动条的高度
    score: number; // 得分
    color: string; // 得分对应的颜色
    slideRadius: number; // 滑动条的圆角
    totalScore?: number; // 滑动条的总分,不传默认10
}

const ScoreSlide: React.FC<ScoreSlideProps> = props => {
    const { width, height, slideRadius, score, totalScore, color } = props;
    const { commonStyles } = useStyles();
    const getSlideWidth = () => {
        return (score / (totalScore ? totalScore : 10)) * width;
    };

    return (
        <View style={{ ...commonStyles.flex }}>
            <View style={{ width, height, borderRadius: slideRadius, backgroundColor: '#F2F4F6' }}>
                <View style={{ width: getSlideWidth(), height, backgroundColor: color, borderRadius: slideRadius }} />
            </View>
            <Text
                style={{
                    marginLeft: 6,
                    marginTop: 2,
                    fontSize: 6.8,
                    color: '#303C42',
                }}
            >
                {score}
            </Text>
        </View>
    );
};

export default ScoreSlide;
