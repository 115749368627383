import { View } from "@react-pdf/renderer";
import { useStyles } from "../utils/styles";
import ChartDevelopmentPotential from "./ChartDevelopmentPotential";
import TextWithBlank from "./TextWithBlank";

/**
 * 5、发展潜力
 */
function Reporter5() {
    const { commonStyles } = useStyles();

    return (
        <View style={{ height: "auto", marginTop: 24 }}>
            <TextWithBlank
                style={commonStyles.heading}
            >{`5、发展潜力`}</TextWithBlank>
            <View style={{ width: "100%", marginTop: 8 }}>
                <ChartDevelopmentPotential />
            </View>
        </View>
    );
}

export default Reporter5;
