import { redirectToLoginPage } from '@/utils';
import { getToken } from '@/utils/getToken';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './BasicLayout.less';

function BasicLayout() {
    const location = useLocation();
    // 解决跳转新页面滚动条不在顶部的问题
    useEffect(() => {
        window.scroll({ top: 0, behavior: 'smooth' });
    }, [location]);
    const token = getToken();
    useEffect(() => {
        if (['/'].includes(location.pathname) && !token) {
            redirectToLoginPage();
        }
    }, [location, token]);

    return (
        <section className="layout-root">
            <Outlet />
        </section>
    );
}

export default BasicLayout;
