import { useLocation } from "react-router";

import BasicLayout from "./BasicLayout";
import BlankLayout from "./BlankLayout";

function Layout() {
    const location = useLocation();
    if (["/user/login", "/403", "/404", "/500"].includes(location.pathname)) {
        return <BlankLayout />;
    }

    return <BasicLayout />;
}

export default Layout;
