import { Defs, LinearGradient, Stop, Svg, View, Rect } from '@react-pdf/renderer';
import { usePdfTheme, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

function Card(props: { head: any; children: any; style?: any }) {
    const { head, children, style = {} } = props;
    const pdfType = usePdfType();
    const theme = usePdfTheme();

    const [startColor, stopColor] =
        pdfType === 'ys' ? [theme?.main, theme?.main] : theme?.cardHeaderGradientColors ?? [theme?.main, theme?.main];

    const { width = 244, minHeight = 164, borderRadius = 18.89, headHeight = 42, ...otherStyle } = style;

    const boxWidth = width;
    const boxMinHeight = minHeight;
    const boxHeadHeight = headHeight;
    const boxRadius = borderRadius;

    const radiusTopStyles =
        pdfType === 'ys'
            ? {
                  borderTopLeftRadius: boxRadius,
              }
            : {
                  borderTopLeftRadius: boxRadius,
                  borderTopRightRadius: boxRadius,
              };
    const radiusBottomStyles =
        pdfType === 'ys'
            ? {
                  borderBottomRightRadius: boxRadius,
              }
            : {
                  borderBottomRightRadius: boxRadius,
                  borderBottomLeftRadius: boxRadius,
              };

    return (
        <View
            style={{
                width: boxWidth,
                height: 'auto',
                minHeight: boxMinHeight,
                backgroundColor: 'white',
                ...radiusTopStyles,
                ...radiusBottomStyles,
                ...otherStyle,
            }}
        >
            <View
                style={{
                    width: boxWidth,
                    height: boxHeadHeight,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    overflow: 'hidden',
                    ...radiusTopStyles,
                    backgroundColor: startColor,
                }}
            >
                <Svg viewBox={`0 0 ${boxWidth} ${boxHeadHeight}`}>
                    <Defs>
                        <LinearGradient id="jb" x1="0%" y1="0%" x2="0%" y2="0%">
                            <Stop stopColor={startColor} offset="0%" />
                            <Stop stopColor={stopColor} offset="100%" />
                        </LinearGradient>
                    </Defs>
                    <Rect x={0} y={0} width={boxWidth} height={boxHeadHeight} fill="url(#jb)" />
                </Svg>
            </View>
            <View style={{ height: boxHeadHeight, paddingLeft: 18, paddingRight: 18, color: 'white', fontSize: 15.12 }}>
                {head}
            </View>
            <View
                style={{
                    paddingTop: 24,
                    paddingBottom: 18,
                    paddingLeft: 20,
                    paddingRight: 20,
                    backgroundColor: 'white',
                    ...radiusBottomStyles,
                }}
            >
                {children}
            </View>
        </View>
    );
}

export default Card;
