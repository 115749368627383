import { View, Text } from '@react-pdf/renderer';
import { useMemo } from 'react';
// import { Style } from '@react-pdf/types';

function WrapText(props: {
    // 1. 可视宽度
    width: number;
    // 3. 缩进字数
    indent?: number;
    // 4. 文本
    text: string;
    // 5. 处理的特殊字符清单
    punctuations?: string[];
    /**
     * 样式
     */
    style: {
        // 2. 字体大小
        fontSize: number;
        [key: string]: any;
    };
    // /**
    //  * 部分文本样式特殊处理，比如名字需要特殊的字体样式
    //  */
    // specialText?: { text: string; style: Style }[];
}) {
    const enPunctuations = '()';
    const cnPunctuations = '，、。；：——（）“”';

    const {
        width,
        indent = 0,
        text,
        punctuations = `${enPunctuations}${cnPunctuations}`.split(''),
        style: { fontSize, ...restStyle },
    } = props;

    const lines = useMemo(() => {
        return wrapLines(width, fontSize, indent, text, punctuations);
    }, [width, fontSize, indent, text, punctuations]);

    return (
        <View style={{ fontSize, width, ...restStyle }}>
            {lines.map((chars, index) => {
                const key = `${index}`;
                const renderTexts = _chars => (
                    <View
                        style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {_chars.map((char, charIndex) => {
                            // 先注释,有可能其他文字不需要特殊处理的但正好又包含在部分文本样式特殊处理也会被处理
                            // const specialTextItem = specialText?.find(s => s.text.includes(char));
                            // if (specialTextItem) {
                            //     return (
                            //         <Text key={`${charIndex}`} style={specialTextItem.style}>
                            //             {char}
                            //         </Text>
                            //     );
                            // }
                            return <Text key={`${charIndex}`}>{char}</Text>;
                        })}
                    </View>
                );

                if (indent > 0 && index === 0) {
                    return (
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                            }}
                            key={key}
                        >
                            <View style={{ width: indent * fontSize, height: 1 }} />
                            {renderTexts(chars)}
                        </View>
                    );
                }

                return <>{renderTexts(chars)}</>;
            })}
        </View>
    );
}

/**
 * 断行分组：
 * 1. 首行扣除掉缩进字数
 * 2. 每一行的第一个字如果是标点，就拿前一行末尾的非标点字开始截取放到改行的首部
 * 3. 其余正常显示
 *
 * 注意：一个英文字符算 0.5 个字
 *
 * @param width 可视宽度
 * @param fontSize 字体大小
 * @param indent 缩进字数
 * @param text 文本
 * @param punctuations 标点符号
 */
function wrapLines(width: number, fontSize: number, indent: number, text: string, punctuations: string[]) {
    const lines: string[][] = [];
    let lineIndex = 0; // 行数：0 表示第一行

    const perLineChars = Math.floor(width / fontSize); // 每行最多显示多少个字
    const firstLineChars = indent > 0 ? perLineChars - indent : perLineChars; // 首行最多显示多少个字：要扣除掉缩进字数
    const punString = punctuations.map(p => (['(', ')', '.', '?', '/'].includes(p) ? `\\${p}` : p)).join('');
    const rePun = new RegExp(`^(.*)([^${punString}][${punString}]*)$`);

    const allChars = text.split('');

    while (allChars.length > 0) {
        const char = allChars.shift() as string;

        if (!lines[lineIndex]) {
            lines[lineIndex] = [];
        }

        const len = countChars(lines[lineIndex]);
        if (lineIndex === 0) {
            // 首行处理
            lines[lineIndex].push(char);
            // 大于等于的时候换行，有可能存在半个字符
            if (len >= firstLineChars - 1) {
                lineIndex += 1;
            }
        } else {
            // 非首行的第一个字如果是标点符号就必须从前一行挪文字下来
            if (len === 0) {
                if (punctuations.includes(char)) {
                    const result = lines[lineIndex - 1].join('').match(rePun);

                    if (result) {
                        lines[lineIndex - 1] = result[1].split('');
                        lines[lineIndex].push(...result[2].split(''));
                    }
                }
            }

            lines[lineIndex].push(char);
            if (len === perLineChars - 1) {
                lineIndex += 1;
            }
        }
    }

    return lines;
}

/**
 * 计算一行的字数，一个英文字母算半个字
 */
function countChars(line: string[]) {
    const letters = /[a-zA-Z0-9(),'":;]/;

    return line.reduce((len, char) => {
        return len + (letters.test(char) ? 0.5 : 1);
    }, 0);
}

export default WrapText;
