import React, { useContext } from 'react';
import { Image, Page, View } from '@react-pdf/renderer';
import { useStyles } from '@/components/PdfReporter/utils/styles';
import msBg from '@/components/HighPdfReporter/assets/ms/bg.png';
import ysBg from '@/components/HighPdfReporter/assets/ys/bg.png';
import { Context } from '../utils/common';

interface PageBackgroundProps {
    children?: React.ReactNode;
}

const PageBackground: React.FC<PageBackgroundProps> = ({ children }) => {
    const { type } = useContext(Context);
    const { styles } = useStyles();

    return (
        <Page style={{ ...styles.body }}>
            <View style={styles.homeBodyBg}>
                <Image style={styles.homeBodyBgImage} src={type === 'ys' ? ysBg : msBg} />
            </View>
            <View style={{ ...styles.homeBodyContent }}>{children}</View>
        </Page>
    );
};

export default PageBackground;
