import { useCallback, useMemo, useState } from "react";

import { fetchCaptcha } from "@/services/users";
import { useRequest } from "@/utils/request";
import type { FormInstance } from "ysd-pp";

export type TErrorTime = Record<string, number>;
/**
 * 从 localStorage 里面取得 登录错误次数
 * 一个用户名对应一个状态记录
 * @returns
 */
export function getLoginErrorTimes(): TErrorTime {
  try {
    const times = JSON.parse(localStorage.getItem("errorTimes") as string);

    return times || {};
  } catch (e) {
    return {};
  }
}

/**
 * 设置 一个 用户的登录次数，默认是累加机制
 * @param username
 * @param val
 * 注意：当 val 传入 -1 时，将会触发对应的那条数据进行删除
 */
export function setLoginErrorTimes(username: string, val?: number) {
  const times = getLoginErrorTimes();
  const oneUserLoginErrorTimes = times[username] || 0;

  const newErrorTimes = {
    ...times,
    [username]: val ? val : oneUserLoginErrorTimes + 1,
  };

  // 触发删除
  if (newErrorTimes[username] === -1) {
    delete newErrorTimes[username];
  }

  localStorage.setItem("errorTimes", JSON.stringify(newErrorTimes));

  return newErrorTimes;
}

/**
 * 携带处理验证码功能
 */
export function useWithCaptcha(params: {
  /** 表单实例，从外面提供 */
  form: FormInstance;
}): {
  /**
   * 验证码是否正在加载中
   */
  captchaLoading: boolean;
  /**
   * 验证码图片的地址
   */
  captchaImg: string;
  /**
   * 刷新验证码的方法
   */
  refreshCaptchaImg: (uuid?: string) => Promise<void>;
} {
  const { form } = params;
  // 验证码图片，base64位数据
  const [captchaImg, setCaptchaImg] = useState<string>("");

  const { loading: captchaLoading, runAsync: fetchCaptchaCode } =
    useRequest(fetchCaptcha);

  /**
   * 刷新验证码
   * @returns
   */
  const refreshCaptchaImg = useCallback(() => {
    // 刷新验证码的同时，要清空输入框的验证码
    form.setFieldsValue({
      captcha: "",
    });

    return fetchCaptchaCode().then((res: any) => {
      if (res && res?.code === 200) {
        setCaptchaImg(res?.data?.captcha);
      } else {
        setCaptchaImg("");
      }
    });
  }, [form, setCaptchaImg]);

  return useMemo(
    () => ({
      captchaLoading,
      captchaImg,
      refreshCaptchaImg,
    }),
    [captchaLoading, captchaImg, refreshCaptchaImg]
  );
}
