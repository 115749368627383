import { Text, View } from '@react-pdf/renderer';
import { useStyles } from '../utils/styles';
import { fontSize } from '../utils/fontSize';
import TableNormal from './TableNormal';
import TextWithBlank from './TextWithBlank';

function TableMotivationalInterpretation() {
    const { commonStyles } = useStyles();

    return (
        <TableNormal<{
            id: string;
            title: string;
            a: string;
            b: string;
            c: string;
            break?: boolean;
        }>
            keyColumnName="id"
            dataSource={[
                {
                    id: '1',
                    title: '亲和动机',
                    a: '建立友好亲密的人际关系的需要。',
                    b: '主动与人交往的意愿不强，更偏好独自解决问题|（ 注意：亲和动机过低仅代表人际交往的意愿和主动性不强，但可能具备较强的人际交往经验和能力）。',
                    c: '待人热情，易于相处，偏好团队合作，乐于参加集体活动|（注意：亲和动机过高仅代表与人交往的意愿较强烈，实际交往的能力和效果需参考人际交往能力）。',
                },
                {
                    id: '2',
                    title: '成就动机',
                    a: '争取成功并希望做的最好的需要。',
                    b: '目标性不强，极少主动设置具有挑战性的目标，偏好回避、保守的策略解决问题。',
                    c: '主动性强，能主动为自己设置具有挑战性的目标并努力达成。但成就动机过高的个体苛求完美且通常过于自信，可能影响合作性。',
                    // break: true,
                },
                {
                    id: '3',
                    title: '权力动机',
                    a: '影响或控制他人且不受他人控制的需要。',
                    b: '主动影响他人的意愿不强，易从众、迎合，谦卑、顺从。',
                    c: '积极主动，自信好强，希望影响他人，获得绝对的权威和掌控权，关注在组织中所处的地位。',
                },
            ]}
            columns={[
                {
                    dataIndex: 'title',
                    title() {
                        return (
                            <TextWithBlank
                                style={{
                                    ...commonStyles.text,
                                    ...commonStyles.strongText,
                                    color: '#FFFFFF',
                                    marginTop: 11,
                                }}
                            >{`社会动机`}</TextWithBlank>
                        );
                    },
                    render(val, record) {
                        const marginTop = {
                            亲和动机: 36,
                            成就动机: 26,
                            权力动机: 16,
                        }[record.title];

                        return (
                            <View
                                style={{
                                    position: 'relative',
                                    top: marginTop,
                                }}
                            >
                                <TextWithBlank
                                    style={{
                                        width: '100%',
                                        ...commonStyles.text,
                                        ...commonStyles.strongText,
                                    }}
                                >
                                    {val}
                                </TextWithBlank>
                            </View>
                        );
                    },
                    width: `${(90 / 548) * 100}%`,
                },
                {
                    dataIndex: 'a',
                    title() {
                        return (
                            <TextWithBlank
                                style={{
                                    ...commonStyles.text,
                                    ...commonStyles.strongText,
                                    color: '#FFFFFF',
                                    marginTop: 11,
                                }}
                            >{`定义`}</TextWithBlank>
                        );
                    },
                    render(val, record) {
                        const marginTop = {
                            亲和动机: 30,
                            成就动机: 20,
                            权力动机: 10,
                        }[record.title];

                        return (
                            <TextWithBlank
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    position: 'relative',
                                    top: marginTop,
                                    ...fontSize(10, 16),
                                }}
                            >
                                {val}
                            </TextWithBlank>
                        );
                    },
                    width: `${(120 / 548) * 100}%`,
                },
                {
                    dataIndex: 'b',
                    title() {
                        return (
                            <TextWithBlank
                                style={{
                                    ...commonStyles.text,
                                    ...commonStyles.strongText,
                                    color: '#FFFFFF',
                                    marginTop: 11,
                                }}
                            >{`低分解释`}</TextWithBlank>
                        );
                    },
                    render(val, record) {
                        const marginTop = {
                            亲和动机: 4,
                            成就动机: 12,
                            权力动机: 10,
                        }[record.title];

                        if (record.title === '亲和动机') {
                            const [text1, text2] = val.split('|');
                            return (
                                <Text
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        position: 'relative',
                                        top: marginTop,
                                        ...fontSize(10, 16),
                                    }}
                                >
                                    <TextWithBlank>{text1}</TextWithBlank>
                                    <TextWithBlank style={{ ...commonStyles.strongText }}>{text2}</TextWithBlank>
                                </Text>
                            );
                        }

                        return (
                            <TextWithBlank
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    position: 'relative',
                                    top: marginTop,
                                    ...fontSize(10, 16),
                                }}
                            >
                                {val}
                            </TextWithBlank>
                        );
                    },
                    width: `${(169 / 548) * 100}%`,
                },
                {
                    dataIndex: 'c',
                    title() {
                        return (
                            <TextWithBlank
                                style={{
                                    ...commonStyles.text,
                                    ...commonStyles.strongText,
                                    color: '#FFFFFF',
                                    marginTop: 11,
                                }}
                            >{`高分解释`}</TextWithBlank>
                        );
                    },
                    render(val, record) {
                        const marginTop = {
                            亲和动机: 4,
                            成就动机: 4,
                            权力动机: 4,
                        }[record.title];

                        if (record.title === '亲和动机') {
                            const [text1, text2] = val.split('|');
                            return (
                                <Text
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        position: 'relative',
                                        top: marginTop,
                                        ...fontSize(10, 16),
                                        paddingLeft: 6,
                                        paddingRight: 6,
                                    }}
                                >
                                    <TextWithBlank>{text1}</TextWithBlank>
                                    <TextWithBlank style={{ ...commonStyles.strongText }}>{text2}</TextWithBlank>
                                </Text>
                            );
                        }

                        return (
                            <TextWithBlank
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    position: 'relative',
                                    top: marginTop,
                                    ...fontSize(10, 16),
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                }}
                            >
                                {val}
                            </TextWithBlank>
                        );
                    },
                    width: `${(169 / 548) * 100}%`,
                },
            ]}
        />
    );
}

export default TableMotivationalInterpretation;
