import PdfReporter from "@/components/PdfReporter";
import { TpDFType } from "@/components/PdfReporter/utils/styles";
import { PDFViewer } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";

function Pdf({ type }: { type?: TpDFType }) {
    const params = useParams();

    const realType = ((type ?? params?.type) || "ys") as TpDFType;

    return (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <PdfReporter type={realType} />
        </PDFViewer>
    );
}

export default Pdf;
