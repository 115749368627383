import { useCallback, useEffect, useRef } from 'react';

import { Modal } from 'ysd-pp';

/**
 * 需求点：做某一个动作前需要二次弹窗确认
 *
 * 当弹窗可见的状态下，点击确认按钮后触发http请求，
 * 在请求响应完成之前为取消按钮添加 disabled 状态
 *
 * @param loading
 */
const useConfirm = (loading?: boolean) => {
    const modalRef = useRef<Record<string, any>>();

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current?.update({
                cancelButtonProps: { disabled: loading },
            });
        }
    }, [loading]);

    const confirm = useCallback(params => {
        modalRef.current = Modal.confirm(params);
    }, []);

    return confirm;
};

export default useConfirm;
