import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { WaMentalHealthRiskDTO } from '@/components/PdfReporter/services/high';
import { TpDFType, useStyles } from '@/components/PdfReporter/utils/styles';
import WrapText from '@/components/MbtiPdfReporterMobile/components/WrapText';
import ScoreSlide from './ScoreSlide';

interface HealthListItemProps {
    data: Record<string, any>; // 表格每一行的数据
    scores: WaMentalHealthRiskDTO; // 表格的得分数据
    pdfType: TpDFType; // pdf的类型
    levels: Record<string, any>; // 心理健康风险等级
}

const HealthListItem: React.FC<HealthListItemProps> = props => {
    const { data, scores, pdfType, levels } = props;
    const { commonStyles, colors } = useStyles(pdfType);

    const styles = StyleSheet.create({
        tableColumn: {
            ...commonStyles.flex,
            justifyContent: 'center',
            paddingTop: '4',
        },
    });

    // 获取心理健康风险等级状态对应的背景色
    const getColor = (status: string) => {
        let color = '';
        levels.forEach((item: Record<string, any>) => {
            if (status === item.label) {
                color = item.bgcolor;
            }
        });
        return color;
    };

    // 获取心理健康风险等级状态对应的行为描述
    const getBehaviors = (status: string, ranges: Record<string, any>[]) => {
        return ranges.find(i => i.status.includes(status))?.behavior;
    };

    return (
        <View
            style={{
                ...commonStyles.flex,
                width: 477.69,
                height: 53.3,
                color: data.status === '极重' ? '#E6492D' : '#303C42',
                borderBottom: data.dimension === '妄想幻觉' ? '' : '1 solid #dce2e7',
            }}
        >
            <View style={{ ...styles.tableColumn, width: 61.3 }}>
                <WrapText
                    width={61.3}
                    style={{
                        fontSize: 12.85,
                        textAlign: 'center',
                    }}
                    text={data.dimension || ''}
                />
            </View>
            <View style={{ ...styles.tableColumn, width: 123.42, padding: '0 6' }}>
                <WrapText
                    width={111.42}
                    style={{
                        fontSize: 9.07,
                    }}
                    text={data.define || ''}
                />
            </View>
            <View style={{ ...styles.tableColumn, width: 108.67 }}>
                <ScoreSlide
                    width={74.47}
                    height={11.34}
                    score={scores[data.score]}
                    color={getColor(data.status)}
                    slideRadius={5.67}
                    totalScore={5}
                />
            </View>
            <View style={{ ...styles.tableColumn, width: 53.87 }}>
                <WrapText
                    width={53.87}
                    style={{
                        fontSize: 12.85,
                    }}
                    text={data.status || ''}
                />
            </View>
            <View
                style={{
                    ...commonStyles.flexColumn,
                    padding: '4 6',
                    width: 129.21,
                }}
            >
                {getBehaviors(data.status, data.ranges).map((item: string) => (
                    <View
                        style={{
                            ...commonStyles.flex,
                            width: 129.21,
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}
                    >
                        <View
                            style={{
                                width: 1.89,
                                height: 1.89,
                                borderRadius: 0.95,
                                backgroundColor: colors.themeColor,
                                marginRight: 4,
                                marginTop: 4,
                            }}
                        ></View>
                        <WrapText
                            width={117.21}
                            style={{
                                fontSize: 9.07,
                            }}
                            text={item || ''}
                        />
                    </View>
                ))}
            </View>
        </View>
    );
};

export default HealthListItem;
