/* eslint-disable no-irregular-whitespace */
import React, { useContext } from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import { useStyles } from '@/components/PdfReporter/utils/styles';
import { Context } from '../utils/common';
import ModuleHeader from './ModuleHeader';
import HighTable from './HighTable';
import FactorListItem from './FactorListItem';
import goodImg from '../assets/good.png';
import riskImg from '../assets/risk.png';
import WrapText from '@/components/MbtiPdfReporterMobile/components/WrapText';

/**
 * @description 详情1-职业相关人格因素
 * @param props
 */
const PersonalityFactors: React.FC = () => {
    const { data, type } = useContext(Context);
    const { commonStyles, styles } = useStyles(type);
    const { user_info, personality_factor } = data;
    const numbers = Array.from(Array(10).keys()); // 创建一个包含0到9的数组

    const dataSource = [
        {
            factor: '责任心',
            details: ['自律', '尽职可靠', '目标导向'],
            score: 'responsibility',
        },
        {
            factor: '开拓创新',
            details: ['灵活适应', '富有想象', '有创造力'],
            score: 'innovation',
        },
        {
            factor: '外向性',
            details: ['热情', '好社交', '活跃健谈'],
            score: 'extroversion',
        },
        {
            factor: '团队合作',
            details: ['友善', '坦诚', '随和易相处'],
            score: 'cooperation',
        },
        {
            factor: '情绪稳定性',
            details: ['情绪稳定而成熟'],
            score: 'emotional_stability',
        },
        {
            factor: '抗压性',
            details: ['坚韧', '控制', '乐观'],
            score: 'pressure_resistance',
        },
    ];

    const riskAdvantageData = [
        {
            title: '优势',
            icon: goodImg,
            desc: 'advantage',
        },
        {
            title: '风险',
            icon: riskImg,
            desc: 'risk',
        },
    ];

    // 因素头部
    const headerChildren = () => {
        return (
            <>
                <View
                    style={{
                        width: 149.69,
                        height: 24.57,
                        fontSize: 12.85,
                        textAlign: 'center',
                        color: '#fff',
                        borderRight: '0.19 solid #fff',
                    }}
                >
                    <Text style={{ paddingTop: 4 }}>因素</Text>
                </View>
                <View
                    style={{
                        paddingTop: 6.43,
                        paddingBottom: 6.62,
                        ...commonStyles.flexColumn,
                        paddingRight: 22,
                    }}
                >
                    <View
                        style={{
                            ...commonStyles.flex,
                            fontSize: 8.32,
                            color: '#fff',
                            justifyContent: 'space-around',
                        }}
                    >
                        <Text>低</Text>
                        <Text>中</Text>
                        <Text>高</Text>
                    </View>
                    <View
                        style={{
                            width: 148.55,
                            height: 11.34,
                            borderRadius: 5.67,
                            backgroundColor: '#B0EACD',
                            position: 'relative',
                        }}
                    >
                        <View
                            style={{
                                width: 103,
                                height: 11.34,
                                position: 'absolute',
                                borderRadius: 5.67,
                                top: 0,
                                left: 0,
                                backgroundColor: '#FEE6B2',
                                zIndex: 3,
                            }}
                        ></View>
                        <View
                            style={{
                                width: 44.41,
                                height: 11.34,
                                position: 'absolute',
                                borderRadius: 5.67,
                                top: 0,
                                left: 0,
                                backgroundColor: '#FFC5C5',
                                zIndex: 2,
                            }}
                        ></View>
                        <View
                            style={{
                                ...commonStyles.flex,
                                justifyContent: 'space-around',
                                width: 148.55,
                                fontSize: 6.8,
                                color: '#303C42',
                                position: 'absolute',
                                top: 2,
                                left: 0,
                                zIndex: 1,
                            }}
                        >
                            {numbers.map(number => (
                                <Text>{number + 1}</Text>
                            ))}
                        </View>
                    </View>
                </View>
            </>
        );
    };

    // 因素表格
    const factorTable = () => {
        return (
            <HighTable
                width={334.15}
                height={384.24}
                pdfType={type}
                headerStyle={commonStyles.flex}
                header={headerChildren()}
            >
                <View style={{ ...commonStyles.flexColumn, paddingTop: 37.8, alignItems: 'center' }}>
                    {dataSource.map(item => {
                        return <FactorListItem data={item} scores={personality_factor || {}} pdfType={type} />;
                    })}
                </View>
            </HighTable>
        );
    };

    return (
        <View>
            <ModuleHeader titleText="详情1-职业相关人格因素" />
            <View
                style={{
                    ...commonStyles.flex,
                    justifyContent: 'space-between',
                    fontSize: 12.85,
                    color: '#303C42',
                }}
            >
                {factorTable()}
                <View
                    style={{
                        ...commonStyles.flexColumn,
                        justifyContent: 'space-between',
                        width: 157.85,
                        height: 384.24,
                    }}
                >
                    {riskAdvantageData.map(item => {
                        return (
                            <HighTable
                                width={157.85}
                                height={183.18}
                                pdfType={type}
                                key={item.title}
                                header={
                                    <View
                                        style={{
                                            ...commonStyles.flex,
                                            width: 157.85,
                                            height: 183.18,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Image src={item.icon} style={{ width: 11.53, height: 11.34 }} />
                                        <Text
                                            style={{
                                                marginTop: 4,
                                                marginLeft: 4.72,
                                                color: '#fff',
                                            }}
                                        >
                                            {item.title}
                                        </Text>
                                    </View>
                                }
                            >
                                <View
                                    style={{
                                        ...commonStyles.flex,
                                        width: 157.85,
                                        height: 183.18,
                                        paddingTop: 46,
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        fontSize: 11.34,
                                        position: 'relative',
                                    }}
                                >
                                    <Text style={{ position: 'absolute', top: 45, left: 32, ...styles.nameFont }}>
                                        {user_info?.name}
                                    </Text>
                                    <WrapText
                                        width={140}
                                        indent={2 + (user_info?.name?.length || 0)}
                                        style={{
                                            fontSize: 11.34,
                                        }}
                                        text={personality_factor?.[item.desc] || ''}
                                    />
                                </View>
                            </HighTable>
                        );
                    })}
                </View>
            </View>
        </View>
    );
};

export default PersonalityFactors;
