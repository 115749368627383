import classNames from "classnames";
import type { FormItemProps, InputProps } from "ysd-pp";
import { Button, Form, Icon, Input } from "ysd-pp";

import { useWithCaptcha } from "./utils";

import "./FieldCaptcha.less";

interface FieldCaptchaProps extends FormItemProps {
  /**
   * 验证码是否正在加载
   */
  loading: boolean;
  /**
   * 当前验证码的图片地址
   */
  captchaImg: string;
  /**
   * 提供刷新验证码的方法
   */
  refreshCaptchaImg: () => void;
  /**
   * InputProps 的 prefix 字段
   */
  prefix?: InputProps["prefix"];
}
function FieldCaptcha(props: FieldCaptchaProps) {
  const {
    prefix,
    loading,
    captchaImg,
    refreshCaptchaImg,
    className,
    ...restProps
  } = props;

  return (
    <div className={classNames("form-item-captcha", className)}>
      <Form.Item
        {...restProps}
        name="captcha"
        rules={[
          {
            required: true,
            message: "请输入验证码",
          },
        ]}
      >
        <Input
          prefix={
            typeof prefix !== "undefined" ? (
              prefix
            ) : (
              <Icon type="CodeOIcon" className="code-icon" />
            )
          }
          placeholder="请输入验证码"
        />
      </Form.Item>
      <Button
        className="btn-captcha-img"
        id="hash__captcha-img"
        loading={loading}
        onClick={() => {
          refreshCaptchaImg();
        }}
      >
        <img src={captchaImg} alt="验证码" />
      </Button>
    </div>
  );
}

/**
 * 携带处理验证码功能
 */
FieldCaptcha.useWithCaptcha = useWithCaptcha;

export default FieldCaptcha;
