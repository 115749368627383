import { StyleSheet, View } from "@react-pdf/renderer";
import { useStyles } from "../utils/styles";
import TextWithBlank from "./TextWithBlank";

/**
 * 报告包含以下部分
 */
function ReporterContents() {
    const { commonStyles } = useStyles();

    const styles = StyleSheet.create({
        heading: {
            ...commonStyles.heading,
            color: "#000000",
        },
        text: {
            ...commonStyles.text,
            color: "#000000",
            marginTop: 4,
        },
        container: {
            flexDirection: "row",
            marginTop: 8,
        },
        col: {
            width: 260,
        },
    });

    return (
        <View style={{ height: "auto", marginTop: 40 }}>
            <TextWithBlank style={styles.heading}>
                报告包含以下部分：
            </TextWithBlank>
            <View style={styles.container}>
                <View
                    style={{
                        ...styles.col,
                        marginRight: 27,
                    }}
                >
                    <TextWithBlank style={{ ...styles.text, marginTop: 0 }}>
                        1、整体结果
                    </TextWithBlank>
                    <TextWithBlank style={styles.text}>
                        2、主导动机
                    </TextWithBlank>
                    <TextWithBlank style={styles.text}>
                        3、风险指标预警
                    </TextWithBlank>
                    <TextWithBlank style={styles.text}>
                        4、工作能力
                    </TextWithBlank>
                </View>
                <View style={{ ...styles.col }}>
                    <TextWithBlank style={{ ...styles.text, marginTop: 0 }}>
                        5、发展潜力
                    </TextWithBlank>
                    <TextWithBlank style={styles.text}>
                        6、16种人格因素得分表
                    </TextWithBlank>
                    <TextWithBlank style={styles.text}>
                        7、职业相关人格因素
                    </TextWithBlank>
                    <TextWithBlank style={styles.text}>
                        8、职业预测人格因素
                    </TextWithBlank>
                </View>
            </View>
        </View>
    );
}

export default ReporterContents;
