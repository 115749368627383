import React, { useContext } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Context } from '../utils/common';
import { useStyles } from '@/components/PdfReporter/utils/styles';
import { Style } from '@react-pdf/types';

interface IOptionsProps {
    label: string; // tag的文字
    value?: string; // tag的值
    bgcolor?: string; // tag的颜色
}

interface TagsProps {
    options: IOptionsProps[]; // 每个tag的配置
    activeTagValue?: string; // 当前选中的tag的值
    style?: Style; // 样式
}

const Tags: React.FC<TagsProps> = props => {
    const { options, style, activeTagValue } = props;
    const { type } = useContext(Context);
    const { colors } = useStyles(type);

    const styles = StyleSheet.create({
        flex: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: '#303C42',
        },
    });

    const getBackgroundColor = (item: IOptionsProps) => {
        const { value, bgcolor } = item;
        // 如果没有传递activeTagValue，则采用传入的bgcolor
        if (!activeTagValue) {
            return bgcolor;
        } else {
            // 如果传递了activeTagValue，则根据activeTagValue和value的值来判断颜色
            return activeTagValue === value ? colors.themeColor : '#c9d3db';
        }
    };

    return (
        <View style={{ ...styles.flex, ...style }}>
            {options.map((item, index) => (
                <View
                    style={{
                        width: 34.02,
                        height: 16.44,
                        borderRadius: 5.67,
                        backgroundColor: getBackgroundColor(item),
                        color: item.value === activeTagValue ? '#fff' : '#303C42',
                        fontSize: 9.07,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        paddingTop: 2.6,
                        marginRight: index === options.length - 1 ? 0 : 4.72,
                    }}
                >
                    <Text>{item.label}</Text>
                </View>
            ))}
        </View>
    );
};

export default Tags;
