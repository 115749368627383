import { StyleSheet } from '@react-pdf/renderer';

/**
 * 传入字号 fontSize，生成带有 lineHeight 的样式
 */
export const fontSize = (size: number, lineHeight?: number) => {
    const styles = StyleSheet.create({
        size: {
            fontSize: size,
            lineHeight: (lineHeight ?? size + 8) / size,
        },
    });

    return styles.size;
};
