import { Result } from "ysd-pp";

function Page403() {
    return (
        <Result
            title={403}
            subTitle={"Sorry, you don't have access to this page."}
        />
    );
}

export default Page403;
