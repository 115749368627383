import { Image, Svg, View, Path, G } from '@react-pdf/renderer';
import { usePdfTheme, usePdfType } from '@components/MbtiPdfReporterMobile/utils/styles';

function IconPoint(props: { style?: any; stroke?: string }) {
    const pdfType = usePdfType();
    const theme = usePdfTheme();
    const { style, stroke } = props;

    return (
        <View style={{ width: 15, height: 15, ...(pdfType === 'ms' ? { width: 14, height: 14 } : {}), ...style }}>
            {pdfType === 'ys' ? (
                <Image src={theme?.iconPoint} />
            ) : (
                <Svg viewBox="0 0 80 80">
                    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <Path
                            d="M40,0 C62.0914286,0 80,17.9085714 80,40 C80,62.0914286 62.0914286,80 40,80 C17.9085714,80 0,62.0914286 0,40 C0,17.9085714 17.9085714,0 40,0 Z"
                            fill={stroke ?? theme?.main}
                        />
                        <Path
                            d="M40,17.1428571 C52.6228571,17.1428571 62.8571429,27.3771429 62.8571429,40 C62.8571429,52.6228571 52.6228571,62.8571429 40,62.8571429 C27.3771429,62.8571429 17.1428571,52.6228571 17.1428571,40 C17.1428571,27.3771429 27.3771429,17.1428571 40,17.1428571 Z"
                            fill="#FFFFFF"
                        ></Path>
                    </G>
                </Svg>
            )}
        </View>
    );
}

export default IconPoint;
