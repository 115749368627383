import { getToken } from '@/utils/getToken';
import LoginForm from '../components/LoginForm';
import './index.less';
import loginBgImg from '@/assets/images/login-bg.jpg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
function Login() {
    const token = getToken();
    const navigate = useNavigate();
    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token]);
    return (
        <div className="login-page" style={{ background: `url(${loginBgImg}) center` }}>
            <LoginForm />
        </div>
    );
}
export default Login;
